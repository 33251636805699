import { AxiosResponse } from "axios";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ApiResponse, BasicApiResponse, ResponseMessage } from "src/types";

import { unpackError } from "@dashboard/devices/api/helpers";
import { License, Subscription } from "@dashboard/devices/types";

import { Provisioning } from "../types";

export const fetchProvisionings = async (workspaceId: number) => {
    try {
        const endpoint = await RouteService.getProvisioningsRoute();
        const { data } = await httpService.get<ApiResponse<Provisioning[]>>(endpoint + `workspace/${workspaceId}`);

        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export const updateProvisioning = async (payload: Provisioning) => {
    try {
        const endpoint = await RouteService.getProvisioningsRoute();
        const { data } = await httpService.put<Provisioning, AxiosResponse<ApiResponse<Provisioning>>>(
            endpoint,
            payload,
        );
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export const createProvisioning = async (payload: Omit<Provisioning, "id">) => {
    try {
        const endpoint = await RouteService.getProvisioningsRoute();
        const { data } = await httpService.post<Omit<Provisioning, "id">, AxiosResponse<ApiResponse<Provisioning>>>(
            endpoint,
            payload,
        );

        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export const deleteProvisioning = async (provisioningId: number) => {
    try {
        const endpoint = await RouteService.getProvisioningsRoute();
        await httpService.delete<Provisioning, BasicApiResponse>(endpoint + String(provisioningId));
        return provisioningId;
    } catch (error) {
        throw unpackError(error);
    }
};
interface UpdateUniversalLicenseGroupPayload {
    id: number;
    groupId?: number | null;
}
export const updateUniversalLicenseGroup = async (payload: UpdateUniversalLicenseGroupPayload) => {
    try {
        const endpoint = await RouteService.getLicensesRoute();
        const formData = new FormData();
        Object.entries(payload).forEach(([key, value]) => {
            formData.append(key, value as string);
        });

        const { data } = await httpService.patch<FormData, AxiosResponse<ApiResponse<License>>>(
            endpoint + `${payload.id}/universalgroup`,
            formData,
            { headers: { "content-type": "application/json" } },
        );

        return data;
    } catch (error) {
        throw unpackError(error);
    }
};
export const deleteUniversalLicenseGroup = async (id: number): Promise<ApiResponse<License>> => {
    try {
        const formData = new FormData();
        formData.append("groupId", "null");

        const endpoint = await RouteService.getLicensesRoute();
        const { data } = await httpService.patch<FormData, AxiosResponse<ApiResponse<License>>>(
            endpoint + `${id}/universalgroup`,
            formData,
        );

        return data;
    } catch (error) {
        throw unpackError(error);
    }
};
interface FetchFdroidReposResponse {
    message: string;
    details: ResponseMessage[];
    data: { model: API.FdroidRepo[] };
}
export const fetchFdroidRepos = async (subscriptionId: Subscription["id"]) => {
    try {
        const endpoint = await RouteService.getSubscriptionsRoute();
        const { data } = await httpService.get<object, FetchFdroidReposResponse>(endpoint + `${subscriptionId}/repos`, {
            headers: {},
        });
        return data?.model;
    } catch (error) {
        throw unpackError(error);
    }
};
export interface CreateFdroidRepoPayload extends Omit<API.FdroidRepo, "id"> {
    password: string;
}

export async function createFdroidRepo(payload: CreateFdroidRepoPayload) {
    try {
        const endpoint = await RouteService.getSubscriptionsRoute();
        const { data } = await httpService.post<CreateFdroidRepoPayload, AxiosResponse<ApiResponse<API.FdroidRepo>>>(
            endpoint + `${payload.subscriptionId}/repos`,
            payload,
            { headers: { "content-type": "application/json" } },
        );

        return data?.model;
    } catch (error) {
        throw unpackError(error);
    }
}
interface DeleteFdroidRepoPayload {
    subscriptionId: number;
    repoId: number;
}
export async function deleteFdroidRepo(payload: DeleteFdroidRepoPayload) {
    try {
        const endpoint = await RouteService.getSubscriptionsRoute();
        await httpService.delete<object, AxiosResponse<BasicApiResponse>>(
            endpoint + `${payload.subscriptionId}/repos/${payload.repoId}`,
            {
                headers: { "content-type": "application/json" },
                data: payload,
            },
        );
    } catch (error) {
        throw unpackError(error);
    }
}
