import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RootState } from "src/store";

import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import {
    ContentContainer,
    ContentContainerSubTitle,
    ContentContainerTitle,
    PageContainer,
    SectionDescription,
} from "@dashboard/shared/styles";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { selectDevices } from "@devices/store/selectors/devices";
import { Alert, Box, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ApmLink from "@shared/ApmLink";
import { LoadingBox } from "@shared/Loading";
import { showErrorToast } from "@shared/toasts/Toasts";

import { selectMergedFiltersByProperty } from "../devices/store";
import { fetchReleases } from "./api";
import DownloadsTable from "./components/DownloadsTable/DownloadsTable";

const useStyles = makeStyles(() => ({
    row: {
        display: "flex",
        padding: "10px 5px",
    },
    logoBox: {
        width: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    li: { marginBottom: 4 },
    listBox: {
        marginLeft: 36,
    },
    tutorialList: { listStyleType: "disc", marginLeft: 24, marginTop: 8 },
    tutorialAlert: { marginBottom: 18 },
}));

type State = {
    data: API.ReleaseFile[];
    count: number;
    status: "pending" | "fulfilled" | "rejected" | "uninitialized";
};
const defaultState: State = {
    data: [],
    count: 0,
    status: "uninitialized",
};

const DownloadsPage = () => {
    const [releaseFiles, setReleaseFiles] = React.useState<State>(defaultState);

    const classes = useStyles();
    const workspace = useSelector(selectWorkspace);
    const devices = useSelector(selectDevices);

    const codenameOptions = React.useMemo(() => releaseFiles.data.map((item) => item.codename), [releaseFiles]);
    const productOptions = React.useMemo(() => releaseFiles.data.map((item) => item.productName), [releaseFiles.data]);
    const channelOptions = ["Live", "Beta", "Nightly"];

    const selectCodenameFilters = selectMergedFiltersByProperty<"releasesCodename">();
    const selectProductFilters = selectMergedFiltersByProperty<"releasesProduct">();
    const selectChannelFilters = selectMergedFiltersByProperty<"channel">();

    const codenameFilters = useSelector(
        (state: RootState) => selectCodenameFilters(state)("releasesCodename", codenameOptions),
        shallowEqual,
    );
    const productFilters = useSelector(
        (state: RootState) => selectProductFilters(state)("releasesProduct", productOptions),
        shallowEqual,
    );
    const channelFilters = useSelector(
        (state: RootState) => selectChannelFilters(state)("channel", channelOptions),
        shallowEqual,
    );

    const filteredData = React.useMemo(() => {
        const normalize = (str: string) => str.toLowerCase();

        const noCodenameFilters = !Object.values(codenameFilters).some(Boolean);
        const noProductFilters = !Object.values(productFilters).some(Boolean);
        const noChannelFilters = !Object.values(channelFilters).some(Boolean);

        if (noCodenameFilters && noProductFilters && noChannelFilters) {
            return releaseFiles.data;
        }

        const finalFilteredData = releaseFiles.data.filter((item) => {
            const isCodenameMatch = noCodenameFilters || codenameFilters[item.codename] || false;
            const isProductNameMatch = noProductFilters || productFilters[item.productName] || false;
            const normalizedChannel = normalize(item.channel);
            const isChannelMatch =
                noChannelFilters ||
                Object.keys(channelFilters).some(
                    (filterKey) => channelFilters[filterKey] && normalize(filterKey) === normalizedChannel,
                );
            return isCodenameMatch && isProductNameMatch && isChannelMatch;
        });

        return finalFilteredData;
    }, [releaseFiles.data, codenameFilters, productFilters, channelFilters]);

    React.useLayoutEffect(() => {
        if (workspace?.id) {
            setReleaseFiles((prev) => ({ ...prev, status: "pending" }));
            void fetchReleases({ workspaceId: workspace.id })
                .then((data) => setReleaseFiles({ data: data.list, count: data.count, status: "fulfilled" }))
                .catch(() => {
                    showErrorToast("An error occurred while fetching release files");
                    setReleaseFiles((prev) => ({ ...prev, status: "rejected" }));
                });
        }
    }, [workspace?.id]);

    return (
        <PageContainer>
            <PageTitleWithIcon title="Downloads" iconName="fa-regular fa-floppy-disk" />
            <SectionDescription>
                Discover latest Android releases through our official installer or GPT downloads of public community
                images and customized Android OS builds for your own products.
            </SectionDescription>
            <ContentContainer>
                <ContentContainerTitle>Emteria OS installers</ContentContainerTitle>
                <ContentContainerSubTitle>
                    Explore Emteria OS installers tailored for both Linux and Windows platforms. Download the installer
                    for your operating system to get started with Emteria&apos;s Android releases today.
                </ContentContainerSubTitle>

                {!devices.length ? (
                    <Alert severity="info" className={classes.tutorialAlert}>
                        <Typography>
                            Need help installing Android to your first device? Here is a list of tutorials to guide you
                            through the installation process:
                        </Typography>
                        <ul className={classes.tutorialList}>
                            <li>
                                <Typography>
                                    <a href="https://emteria.com/kb/use-installer-emteria-os">
                                        How to use emteria installer to flash Android to your device
                                    </a>
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <a href="https://emteria.com/kb/initial-configuration-setup-wizard">
                                        How to finish device configuration during very first boot
                                    </a>
                                </Typography>
                            </li>
                            <li>
                                <Typography>
                                    <a href="https://emteria.com/kb/enable-mdm">
                                        How to enable remote device management and monitoring
                                    </a>
                                </Typography>
                            </li>
                        </ul>
                    </Alert>
                ) : null}

                <Box className={classes.row}>
                    <Box className={classes.logoBox}>
                        <i className="fab fa-3x fa-linux" />
                        <Typography variant="subtitle1">Linux x64</Typography>
                    </Box>
                    <Box className={classes.listBox}>
                        <ul>
                            <li className={classes.li}>Version: 1.1-43</li>
                            <li className={classes.li}>Size: 34.06 MB</li>
                            <li className={classes.li}>
                                Download:{" "}
                                <ApmLink
                                    parent="Downloads page"
                                    title="Download installer"
                                    href="https://s3.emteria.com/public/installer/Emteria.OS_Installer-v1.1-43.AppImage"
                                >
                                    Emteria.OS_Installer-v1.1-43.AppImage
                                </ApmLink>
                            </li>
                        </ul>
                    </Box>
                </Box>
                <Divider />
                <Box className={classes.row}>
                    <Box className={classes.logoBox}>
                        <i className="fab fa-3x fa-windows" />
                        <Typography variant="subtitle1">Windows x64</Typography>
                    </Box>
                    <Box className={classes.listBox}>
                        <ul>
                            <li className={classes.li}>Version: 1.1-43</li>
                            <li className={classes.li}>Size: 49.47 MB</li>
                            <li className={classes.li}>
                                Download:{" "}
                                <ApmLink
                                    parent="Downloads page"
                                    title="Download installer"
                                    href="https://s3.emteria.com/public/installer/Emteria.OS_Installer-v1.1-43.exe"
                                >
                                    Emteria.OS_Installer-v1.1-43.exe
                                </ApmLink>
                            </li>
                        </ul>
                    </Box>
                </Box>
            </ContentContainer>
            <ContentContainer>
                <ContentContainerTitle>Android OS downloads</ContentContainerTitle>
                <ContentContainerSubTitle>
                    Download community releases and customized Android OS images for your products.
                </ContentContainerSubTitle>
                {releaseFiles.status === "pending" ? (
                    <LoadingBox style={{ minHeight: 300 }} />
                ) : (
                    <DownloadsTable allFiles={releaseFiles.data} filteredFiles={filteredData} />
                )}
            </ContentContainer>
        </PageContainer>
    );
};
export default DownloadsPage;
