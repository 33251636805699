import clsx from "clsx";
import { Dispatch, SetStateAction, useState } from "react";

import { TagFilterDialog } from "@dashboard/files/components/TagFilterDialog/TagFilterDialog";
import { Sort } from "@dashboard/files/components/UploadTable/UploadTable.utils";
import { TableCell, TableCellProps, TableSortLabel } from "@mui/material";

import { useStyles } from "./TableHeader.style";

type Item = {
    id: string;
    label: string;
};

interface TableHeaderProps {
    item: Item;
    sort?: Sort<string>;
    activeFiltersCount?: number;
    onSort?: (orderBy: string) => void;
    filters?: Record<string, boolean>;
    setFilters?: Dispatch<SetStateAction<Record<string, boolean>>>;
    cellProps?: TableCellProps;
    sortableColumnIds?: string[];
    filterableColumnIds?: string[];
}

export const TableHeaderCell = (props: TableHeaderProps) => {
    const classes = useStyles();
    const isSortable = props.sortableColumnIds?.includes(props.item.id);
    const isFilterable = props.filterableColumnIds?.includes(props.item.id);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    const onSort = () => {
        if (props.onSort) {
            props.onSort(props.item.id);
        }
    };

    if (isSortable) {
        return (
            <TableCell
                align="left"
                className={clsx(props.item.id === "contentType" ? classes.typeCell : "")}
                sortDirection={props.sort?.orderDirection}
                {...props.cellProps}
            >
                <TableSortLabel
                    active={props.sort?.orderBy === props.item.id}
                    onClick={onSort}
                    className={classes.sortLabel}
                    direction={props.sort?.orderDirection}
                >
                    {props.item.label}
                </TableSortLabel>
            </TableCell>
        );
    }
    if (isFilterable && props.filters && props.setFilters) {
        return (
            <TableCell align="left" {...props.cellProps}>
                {props.item.label}
                <TagFilterDialog
                    filters={props.filters}
                    setFilters={props.setFilters}
                    setOpenFilterDialog={setOpenFilterDialog}
                    open={openFilterDialog}
                />
            </TableCell>
        );
    }

    return (
        <TableCell align="left" {...props.cellProps}>
            {props.item.label}
        </TableCell>
    );
};
