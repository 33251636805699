import { useForm } from "react-final-form";
import { shallowEqual } from "react-redux";
import { useSelector } from "src/store";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "src/ui/shared/CustomButton";
import { SelectField } from "src/ui/shared/form/SelectField";

import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Row } from "@dashboard/shared/styles";
import { selectIsWorkspaceProvisioningOverlimit } from "@dashboard/workspaces/store/selectors";
import { Box, SelectChangeEvent } from "@mui/material";

import { selectProvisioningListStatus } from "../store/selectors";
import { Provisioning } from "../types";

type Props = {
    submitting: boolean;
    setDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setCreateDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SelectProvisionings = (props: Props) => {
    const status = useSelector(selectProvisioningListStatus);
    const parentForm = useForm();
    const provisionings = useSelector(selectProvisionings, shallowEqual);
    const isOverLimit = useSelector(selectIsWorkspaceProvisioningOverlimit);
    const isExpired = useSelector(selectSubscriptionExpired);

    const onChange = (e: SelectChangeEvent<unknown>) => {
        const provisioning = provisionings.filter((item) => item.id === e.target.value)[0];
        if (provisioning) {
            parentForm.reset(provisioning);
        }
    };

    const getCreateButtonTooltip = () => {
        if (isOverLimit) {
            return "You have reached the maximum limit of provisioning settings allowed for your subscription.";
        }
        if (isExpired) {
            return "Subscription expired";
        }
        return "";
    };

    return (
        <Box style={{ paddingBottom: 15 }}>
            <SelectField
                name="id"
                label={!provisionings.length ? "You have no provisioning settings" : "Choose settings"}
                disabled={props.submitting || status === "pending" || !provisionings.length}
                options={provisionings.map((item: Provisioning) => ({
                    label: item.name,
                    value: item.id,
                }))}
                withoutHelperText
                fullWidth
                onChange={onChange}
            />

            <Row style={{ display: "flex", justifyContent: "flex-end", marginTop: 16 }}>
                <DangerOutlinedButton
                    disabled={props.submitting || !provisionings.length}
                    onClick={() => props.setDeleteDialogOpen(true)}
                    data-testid="provisioningPage.btn.delete"
                >
                    Delete
                </DangerOutlinedButton>
                <PrimaryOutlinedButton
                    disabled={props.submitting || isOverLimit || isExpired}
                    onClick={() => props.setCreateDialogOpen(true)}
                    data-testid="provisioningPage.btn.create"
                    tooltipProps={{ title: getCreateButtonTooltip() }}
                >
                    Create
                </PrimaryOutlinedButton>
            </Row>
        </Box>
    );
};
