import { AxiosResponse } from "axios";
import queryString from "query-string";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ApiResponse, BasicApiResponse, ListResponse } from "src/types";

import { unpackError } from "@dashboard/devices/api/helpers";
import { apiGetFileLink } from "@dashboard/files/api";
import { BuildFile } from "@dashboard/files/types";
import { Build, Product } from "@dashboard/products/types";

const serializeProduct = (products: API.Product[]): Product[] => {
    return products.map(({ created: _created, numberOfBuilds: _numberOfBuilds, ...rest }) => rest);
};
const addDownloadLink = async (item: API.BuildFile): Promise<BuildFile> => {
    try {
        const link = await apiGetFileLink(item.id);
        return { ...item, link };
    } catch (error) {
        return { ...item, link: null };
    }
};
export const fetchFileDownloadLinks = async (build: API.Build): Promise<Build> => {
    if (build.files?.length) {
        const files = await Promise.all(build.files.map(addDownloadLink));

        if (files.some((f) => f.link === "")) {
            throw new Error("No files to download");
        }

        return { ...build, files };
    }
    return { ...build, files: [] };
};

const serializeBuild = (build: API.Build): Build => ({
    ...build,
    files: (build.files ?? []).filter((file) => file.isUploaded).map((file) => ({ ...file, link: "" })),
});

export const fetchProductBuilds = async (productId: number): Promise<Build[]> => {
    const endpoint = await RouteService.getProductRoute();
    try {
        const { data } = await httpService.get<string, AxiosResponse<ApiResponse<API.Build[]>>>(
            `${endpoint}products/${productId}/builds`,
        );
        return data.model.map(serializeBuild);
    } catch (error) {
        throw unpackError(error);
    }
};

const requestFetchBuilds = async (payload: Product[]): Promise<Build[]> => {
    const result: Build[] = [];
    for (const product of payload) {
        const builds = await fetchProductBuilds(product.id);
        result.push(...builds);
    }
    return result;
};

type FetchProductsPayload = {
    workspaceId: number;
};
const requestFetchProducts = async (payload: FetchProductsPayload) => {
    const endpoint = await RouteService.getProductRoute();
    const { data } = await httpService.get<string, AxiosResponse<ApiResponse<API.Product[]>>>(endpoint + "products", {
        params: { workspaceId: payload.workspaceId },
    });
    return serializeProduct(data.model);
};
export const fetchProducts = async (payload: FetchProductsPayload) => {
    const products = await requestFetchProducts(payload);
    const builds = await requestFetchBuilds(products);
    return { products, builds };
};

interface FetchProductChangeLogsPayload {
    id: number;
}

export const fetchProductChangeLogs = async (payload: FetchProductChangeLogsPayload) => {
    const endpoint = await RouteService.getProductRoute();
    const { data } = await httpService.get<string, AxiosResponse<ApiResponse<API.Product["changes"]>>>(
        `${endpoint}products/${payload.id}/changes`,
    );

    return data.model;
};

interface BuildChangesPayload {
    productId: number;
    version: string;
    changes: string[];
}
export const setBuildChanges = async (payload: BuildChangesPayload): Promise<API.Product["changes"]> => {
    try {
        const formData = new FormData();
        formData.append("version", payload.version);
        payload.changes.forEach((change) => formData.append("changes", change));

        const endpoint = await RouteService.getProductRoute();
        const { data } = await httpService.patch<FormData, AxiosResponse<ApiResponse<API.Product["changes"]>>>(
            `${endpoint}products/${payload.productId}/changes`,
            formData,
            { headers: { "content-type": "multipart/form-data" } },
        );
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

interface DeleteProductChangesPayload {
    productId: number;
    version: string;
}

export const deleteProductChangelog = async (payload: DeleteProductChangesPayload): Promise<API.Product["changes"]> => {
    try {
        const formData = new FormData();
        formData.append("version", payload.version);

        const endpoint = await RouteService.getProductRoute();
        const { data } = await httpService.delete<FormData, AxiosResponse<ApiResponse<API.Product["changes"]>>>(
            `${endpoint}products/${payload.productId}/changes`,
            {
                headers: { "content-type": "multipart/form-data" },
                data: formData,
            },
        );

        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export const fetchAllProducts = async () => {
    const endpoint = await RouteService.getProductRoute();
    const { data } = await httpService.get<string, AxiosResponse<ApiResponse<API.Product[]>>>(
        endpoint + "products/all",
    );

    return data.model;
};

export const createProduct = async (payload: Product) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        const { data } = await httpService.post<string, AxiosResponse<ApiResponse<API.Product>>>(
            endpoint + "products",
            JSON.stringify(payload),
            { headers: { "content-type": "application/json" } },
        );
        return serializeProduct([data.model])[0];
    } catch (error) {
        throw unpackError(error);
    }
};
export const updateProduct = async (payload: Partial<Product>) => {
    const endpoint = await RouteService.getProductRoute();
    const { data } = await httpService.patch<string, AxiosResponse<ApiResponse<API.Product>>>(
        endpoint + "products",
        JSON.stringify(payload),
        { headers: { "content-type": "application/json" } },
    );
    return serializeProduct([data.model])[0];
};
export const updateProductCodename = async (productId: number, newCodename: string) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        const { data } = await httpService.patch<string, AxiosResponse<ApiResponse<API.Product>>>(
            `${endpoint}products/${productId}/codename`,
            queryString.stringify({ newCodename }),
        );
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
export const updateProductAndroidVersion = async (productId: number, newVersion: string) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        const { data } = await httpService.patch<string, AxiosResponse<ApiResponse<API.Product>>>(
            `${endpoint}products/${productId}/version`,
            queryString.stringify({ newVersion }),
        );
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export const deleteProduct = async (payload: number) => {
    const endpoint = await RouteService.getProductRoute();
    const { data } = await httpService.delete<string, AxiosResponse<BasicApiResponse>>(
        `${endpoint}products/${payload}`,
    );
    return data.message;
};

export type BuildConfig = {
    version: string;
    changes: string[];
    configSnapshot: {
        [key: string]: unknown;
    };
    rolloutPercentage: number;
    configOverride: {
        manifest?: {
            fromVersion?: string;
        };
        ota?: {
            incrementalFromVersions?: string[];
        };
        buildVariant?: "user" | "userdebug" | "eng";
    };
};

type CreateBuildPayload = {
    productId: number;
    config?: Partial<BuildConfig>;
};

export const createBuild = async ({ productId, config = {} }: CreateBuildPayload) => {
    const endpoint = await RouteService.getProductRoute();
    try {
        const { data } = await httpService.post<string, AxiosResponse<ApiResponse<API.Build>>>(
            `${endpoint}products/${productId}/build`,
            JSON.stringify(config),
            { headers: { "content-type": "application/json" } },
        );
        return serializeBuild(data.model);
    } catch (error) {
        throw unpackError(error);
    }
};
export const cancelBuild = async (payload: Build["buildId"]) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        await httpService.delete<string, AxiosResponse<BasicApiResponse>>(`${endpoint}products/build`, {
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: queryString.stringify({ buildId: payload }),
        });
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};
export const deleteBuild = async (payload: { productId: Build["productId"]; buildId: Build["buildId"] }) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        await httpService.delete<string, AxiosResponse<BasicApiResponse>>(
            `${endpoint}products/${payload.productId}/builds/${payload.buildId}`,
        );
        return payload.buildId;
    } catch (error) {
        throw unpackError(error);
    }
};

export const requestDownloadBuildLog = async (payload: Build["buildId"]) => {
    const endpoint = await RouteService.getProductRoute();
    return httpService.get<string, AxiosResponse<Blob>>(endpoint + "logging/full", {
        responseType: "blob",
        params: { buildId: payload },
    });
};

export const requestStreamBuildLog = async (buildId: Build["buildId"], token: string) => {
    const endpoint = await RouteService.getProductRoute();
    return fetch(`${endpoint}logging/stream?buildId=${buildId}`, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Accept": "text/plain",
            "Connection": "keep-alive",
            "Keep-Alive": "timeout=5, max=1000",
        },
    });
};
interface UpdateBuildMetadataPayload {
    productId: number;
    buildId: number;
    metadata: { tags: API.Tag[] };
}
export const updateBuildMetadata = async (payload: UpdateBuildMetadataPayload) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        await httpService.patch<UpdateBuildMetadataPayload["metadata"], AxiosResponse<ApiResponse<API.Build>>>(
            `${endpoint}products/${payload.productId}/builds/${payload.buildId}/metadata`,
            payload.metadata,
        );
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};
interface UpdateBuildChannelPayload {
    productId: number;
    buildId: number;
    channel: Build["channel"];
}
export const updateBuildChannel = async (payload: UpdateBuildChannelPayload) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        await httpService.patch<UpdateBuildChannelPayload["channel"], AxiosResponse<ApiResponse<API.Build>>>(
            `${endpoint}products/${payload.productId}/builds/${payload.buildId}/channel`,
            payload.channel,
            { headers: { "content-type": "application/json" } },
        );
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};

interface UpdateBuildCompatibilityPayload {
    productId: number;
    buildId: number;
    compatibility: Build["compatibility"];
}
export const updateBuildCompatibility = async (payload: UpdateBuildCompatibilityPayload) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        await httpService.patch<
            UpdateBuildCompatibilityPayload["compatibility"],
            AxiosResponse<ApiResponse<API.Build>>
        >(`${endpoint}products/${payload.productId}/builds/${payload.buildId}/compatibility`, payload.compatibility, {
            headers: { "content-type": "application/json" },
        });
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};
interface UpdateBuildRolloutPayload {
    productId: number;
    buildId: number;
    rolloutPercentage: Build["rolloutPercentage"];
}
export const updateBuildRollout = async (payload: UpdateBuildRolloutPayload) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        await httpService.patch<UpdateBuildRolloutPayload["rolloutPercentage"], AxiosResponse<ApiResponse<API.Build>>>(
            `${endpoint}products/${payload.productId}/builds/${payload.buildId}/rollout`,
            payload.rolloutPercentage,
            { headers: { "content-type": "application/json" } },
        );
        return payload;
    } catch (error) {
        throw unpackError(error);
    }
};

export const fetchProductVariables = async (productId: number) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        const { data } = await httpService.get<ListResponse<API.ProductVariable>>(
            `${endpoint}products/${productId}/variables`,
        );
        return data.list;
    } catch (error) {
        throw unpackError(error);
    }
};

interface AddVariablePayload {
    productId: number;
    variableData: {
        key: string;
        value: string;
        description?: string;
    };
}

export const addProductVariable = async (payload: AddVariablePayload) => {
    try {
        const formData = new FormData();
        formData.append("key", payload.variableData.key);
        formData.append("value", payload.variableData.value);
        if (payload.variableData.description) {
            formData.append("description", payload.variableData.description);
        }
        const endpoint = await RouteService.getProductRoute();
        const response = await httpService.post<FormData, AxiosResponse<ApiResponse<API.ProductVariable>>>(
            `${endpoint}products/${payload.productId}/variables`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            },
        );
        return response.data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

export const deleteProductVariable = async (productId: number, variableKey: string) => {
    try {
        const endpoint = await RouteService.getProductRoute();
        const response = await httpService.delete<BasicApiResponse>(
            `${endpoint}products/${productId}/variables/${variableKey}`,
        );
        return response.data.message;
    } catch (error) {
        throw unpackError(error);
    }
};
