import React from "react";
import { useNavigate } from "react-router";
import { theme } from "src/providers/ThemeProvider";
import { RootState, useDispatch, useSelector } from "src/store";
import { CustomTileContainer, CustomTileLink, CustomTileText, CustomTileTitle } from "src/ui/shared/CustomTile";
import { LoadingIcon } from "src/ui/shared/Loading";
import { ProgressBar } from "src/ui/shared/ProgressBar";

import { IconSize } from "@dashboard/devices/components/shared/index";
import { updateFilterProperties } from "@dashboard/devices/store/index";
import { setColumnVisibility } from "@dashboard/devices/utils/columnVisibility";
import { selectLicenses } from "@dashboard/licenses/store/selectors";
import { selectWorkspace, selectWorkspaceLicenseLimit } from "@dashboard/workspaces/store/selectors";
import { selectGroupsByIds } from "@groups/store/selectors";

import { useStyles } from "../shared";

const getBarValue = (usedCount: number, total: number) => {
    if (total === 0) {
        return 0;
    }
    return (usedCount / total) * 100;
};
const getBarColor = (barValue: number) => {
    if (barValue >= 98) {
        return theme.palette.red[100];
    }
    if (barValue > 80) {
        return theme.palette.orange[100];
    }
    return theme.palette.green[50];
};

const EnrollmentProgressCard = (props: { groupIds: number[] }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const groups = useSelector((state) => selectGroupsByIds(state, props.groupIds));
    const groupsNames = groups.map((group) => group.name);
    const isLoading = useSelector((state: RootState) => state.devices.list.licenses.status);
    const licenseLimit = useSelector(selectWorkspaceLicenseLimit);
    const workspace = useSelector(selectWorkspace);
    const workspaceLicenses = useSelector(selectLicenses);
    const deviceCount = workspaceLicenses.filter((license) => license.device).length;
    const barValue = getBarValue(deviceCount, licenseLimit);

    const handleOnClick = () => {
        if (deviceCount === licenseLimit) {
            window.location.href = "https://emteria.com/p/subscription";
        } else {
            dispatch(
                updateFilterProperties({
                    license_activationStatus: ["Used"],
                    group_name: groupsNames,
                }),
            );
            navigate("/dashboard/devices");
            setColumnVisibility("licenseStatus");
        }
    };

    return (
        <CustomTileContainer>
            <CustomTileTitle>Fleet size</CustomTileTitle>
            {isLoading === "pending" ? (
                <div className={classes.loadingBox}>
                    <LoadingIcon size={IconSize.large} />
                </div>
            ) : (
                <React.Fragment>
                    {!deviceCount ? <CustomTileText>No devices enrolled</CustomTileText> : null}
                    {deviceCount === licenseLimit && workspace ? (
                        <CustomTileLink onClick={handleOnClick}>Limit reached</CustomTileLink>
                    ) : null}
                    {deviceCount && deviceCount !== licenseLimit ? (
                        <CustomTileLink
                            onClick={handleOnClick}
                        >{`${deviceCount} of ${licenseLimit} devices enrolled`}</CustomTileLink>
                    ) : null}

                    {deviceCount ? <ProgressBar progress={barValue} color={getBarColor(barValue)} /> : null}
                </React.Fragment>
            )}
        </CustomTileContainer>
    );
};

export default EnrollmentProgressCard;
