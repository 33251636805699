import React from "react";

import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Collapse, IconButton, TableCell, TableCellProps, TableRow } from "@mui/material";

type CollapsibleContextType = { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> };
const CollapsibleContext = React.createContext<CollapsibleContextType>({
    open: false,
    setOpen: () => null,
});

export const CollapsibleRow = ({ children }: React.PropsWithChildren<object>) => {
    const [open, setOpen] = React.useState(false);

    return <CollapsibleContext.Provider value={{ open, setOpen }}>{children}</CollapsibleContext.Provider>;
};

export const CollapsibleSummary = ({ children, ...props }: React.PropsWithChildren<TableCellProps>) => {
    const context = React.useContext(CollapsibleContext);

    if (!context) {
        throw new Error("CollapsibleSummary have to be use inside CollapsibleContext");
    }

    return (
        <TableRow>
            <TableCell align="center" style={{ width: 60, padding: 0, borderBottom: "none" }} {...props}>
                <IconButton onClick={() => context.setOpen((prev) => !prev)} size="large">
                    {context.open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </IconButton>
            </TableCell>
            {children}
        </TableRow>
    );
};
export const CollapsibleDetails = (props: React.PropsWithChildren<object>) => {
    const context = React.useContext(CollapsibleContext);

    if (!context) {
        throw new Error("CollapsibleDetails have to be use inside CollapsibleContext");
    }

    return (
        <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={12}>
                <Collapse in={context.open} collapsedSize={0} style={{ paddingLeft: 60 }}>
                    <Box style={{ padding: 16, paddingTop: 0 }}>{props.children}</Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};
