export type FileInfo = API.File;

export type BuildFile = API.BuildFile & { link: string | null };
export enum FileStatus {
    Pending = 0,
    Uploaded = 1,
}

export interface FileTableItem extends FileInfo {
    formattedCreatedDate?: {
        tooltipTitle: string;
        displayValue: string;
    };
    rolloutTag?: API.Tag | null;
    hasTags?: number | API.Tag | null;
    groupName?: string;
    uploader?: string;
    isUploading?: boolean;
    status: FileStatus;
    version?: string;
    sha256?: string;
    codename?: string;
    visibility?: string;
    versionCodeTag?: API.Tag | null;
    removableTags?: API.Tag[];
}
