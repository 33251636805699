import CryptoJS from "crypto-js";

export enum NotificationId {
    StarterSubscription = 1,
}

export const createRule = (email: string, id: NotificationId): string => {
    const emailHash = CryptoJS.MD5(email).toString();
    return `${emailHash}-${id}`;
};
