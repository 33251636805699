import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    card: { marginBottom: 20 },
    cardContent: { display: "flex", gap: 10, "&.MuiCardContent-root": { paddingTop: 0 } },
    idCell: { maxWidth: 200 },
    cardTitleBox: { display: "flex", gap: 10, alignItems: "flex-end" },
    cell: { width: 200, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" },
    sizeCell: { width: 100, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" },
    actionCell: { display: "flex", gap: 5, justifyContent: "flex-end" },
    flex: { display: "flex", gap: 6 },
    boldLabel: {
        "&.MuiTypography-body1": { fontWeight: 600 },
    },
    noResultsRow: {
        textAlign: "center",
        padding: "20px",
    },
});
