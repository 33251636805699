import { ValidationErrors } from "final-form";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { permissionService } from "src/services/permissionService/permissionService";
import { UploadFileField } from "src/ui/shared/form/UploadFileField";
import FreshAccountMessage from "src/ui/shared/FreshAccountMessage";
import { Tooltip } from "src/ui/shared/Tooltip";

import { UploadProgressBar } from "@dashboard/files/components/UploadProgressBar";
import UploadTable from "@dashboard/files/components/UploadTable/UploadTable";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { selectIsWorkspaceFilesOverlimit, selectWorkspace } from "@dashboard/workspaces/store/selectors";

import PageTitleWithIcon from "../shared/components/PageTitleWithIcon";
import { UploadProvider, useUploadContext } from "./context/UploadContext";
import { useStyles } from "./UploadPage.style";

// 256 MB in bytes
export const maxFileSize = 256 * 1024 * 1024;

interface FormValues {
    files: File[];
    target: number | undefined;
}

const UploadPageContent = () => {
    const classes = useStyles();
    const workspace = useSelector(selectWorkspace);
    const { workspaceAbility } = permissionService();
    const canManageFile = workspaceAbility(workspace).can("manage", "File");
    const { uploadStarted } = useUploadContext();
    const isOverlimit = useSelector(selectIsWorkspaceFilesOverlimit);

    const validate = (values: FormValues): ValidationErrors => {
        const errors: ValidationErrors = {};
        if (values.files) {
            for (const file of values.files) {
                if (file.size > maxFileSize) {
                    errors.files = "One or more files exceed the maximum size limit of 256 MB.";
                    break;
                }
            }
        }
        return errors;
    };

    return (
        <PageContainer>
            <PageTitleWithIcon title="Uploaded files" iconName="fa-regular fa-file" />
            <SectionDescription>
                Upload, manage, and organize your files and applications with custom tags.
            </SectionDescription>
            {workspace ? (
                <Form<FormValues>
                    onSubmit={() => undefined}
                    validate={validate}
                    render={({ handleSubmit, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={classes.dropzoneContainer}>
                                {!canManageFile ? (
                                    <Tooltip
                                        placement="top"
                                        title="You don't have any group or workspace to upload file"
                                        className={classes.uploadTooltipContainer}
                                    >
                                        <UploadFileField name="files" disabled />
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        placement="top"
                                        className={classes.uploadTooltipContainer}
                                        title={
                                            isOverlimit
                                                ? "You have reached the file upload limit. Please check your workspace for more details."
                                                : ""
                                        }
                                    >
                                        <UploadFileField name="files" disabled={uploadStarted || isOverlimit} />
                                    </Tooltip>
                                )}
                                {values.files ? (
                                    <div className={classes.uploadContainer}>
                                        <div className={classes.contentBox}>
                                            <UploadProgressBar filesToUpload={values.files} />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <UploadTable />
                        </form>
                    )}
                />
            ) : (
                <FreshAccountMessage />
            )}
        </PageContainer>
    );
};

const UploadPage = () => {
    return (
        <UploadProvider>
            <UploadPageContent />
        </UploadProvider>
    );
};

export default UploadPage;
