import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "src/store";
import { disableTwoFactor } from "src/store/account";

import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const TwoFactorDisablePage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        void (async () => {
            try {
                const { message } = await dispatch(disableTwoFactor()).unwrap();
                showSuccessToast(message);
                navigate("/account/edit");
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message);
            }
        })();
    }, [dispatch, navigate]);

    return null;
};

export default TwoFactorDisablePage;
