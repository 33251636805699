import _ from "lodash";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { permissionService } from "src/services/permissionService/permissionService";
import { RootState, useDispatch, useSelector } from "src/store";

import { ConfirmDialog } from "@dashboard/devices/components/shared/index";
import BuildsTable from "@dashboard/products/components/Product/Builds/BuildsTable";
import _Product from "@dashboard/products/components/Product/Product";
import { CreateDialog } from "@dashboard/products/components/ProductDetails";
import { useStyles } from "@dashboard/products/ProductPage.style";
import { deleteProduct, fetchProductBuilds } from "@dashboard/products/store/index";
import { selectProducts } from "@dashboard/products/store/selectors/products";
import { Product } from "@dashboard/products/types/index";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { selectIsWorkspaceProductOverlimit, selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { DangerOutlinedButton, PrimaryOutlinedButton } from "@shared/CustomButton";
import { UncontrolledSelectField } from "@shared/form/SelectField";
import { LoadingBox } from "@shared/Loading";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { useCurrentProduct } from "./ProductPage.utils";

const ProductManagerPage = (props: { products: Product[] }) => {
    const [currentProductId, setCurrentProductId] = useCurrentProduct(props.products);
    const [isDeleteProductDialogOpen, setIsDeleteProductDialogOpen] = React.useState(false);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useReducer((state) => !state, false);
    const currentWorkspace = useSelector(selectWorkspace);
    const isOverlimit = useSelector(selectIsWorkspaceProductOverlimit);
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { codename, androidVersion } = useParams();
    const { productAbility } = permissionService();
    const cannotManageProducts = productAbility(currentWorkspace).cannot("manage", "Product");
    const currentProduct = props.products.find((item) => item.id === currentProductId);
    const productOptions = props.products.map((product) => ({
        label: `${product.customBuildConfig?.productName || ""} - Android ${product.androidVersion}`,
        value: product.id,
    }));

    const updateProductUrl = (product: Product | undefined) => {
        if (product) {
            navigate(`/dashboard/products/${product.codename}/${product.androidVersion}`);
        }
    };

    const handleProductChange: SelectInputProps["onChange"] = async (event) => {
        if (typeof event.target.value === "number") {
            const selectedProduct = props.products.find((product) => product.id === event.target.value);

            try {
                await dispatch(fetchProductBuilds(event.target.value));
                setCurrentProductId(selectedProduct?.id);
                updateProductUrl(selectedProduct);
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "Error while fetching builds";
                showErrorToast(errorMessage);
            }
        }
    };

    const getCreateButtonTooltip = () => {
        if (isOverlimit) {
            return "You have reached the maximum limit of products allowed for your workspace.";
        }
        if (cannotManageProducts) {
            return "Your role does not allow you to create products.";
        }
        return "";
    };

    const confirmDeleteProductDialog = async () => {
        if (currentProductId) {
            try {
                await dispatch(deleteProduct(currentProductId)).unwrap();
                showSuccessToast("Product has been deleted");
                const filterProducts = props.products.filter((item) => item.id !== currentProductId);
                setCurrentProductId(filterProducts[0]?.id);
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : "Error deleting product";
                showErrorToast(errorMessage);
            } finally {
                setIsDeleteProductDialogOpen(false);
            }
        }
    };

    useEffect(() => {
        if (codename && androidVersion) {
            const matchedProduct = props.products.find(
                (product) => product.codename === codename && product.androidVersion === androidVersion,
            );
            if (matchedProduct) {
                setCurrentProductId(matchedProduct.id);
            }
        }
    }, [androidVersion, codename, props.products, setCurrentProductId]);

    return (
        <React.Fragment>
            <PageContainer>
                <PageTitleWithIcon title="Product manager" iconName="fa-solid fa-microchip" />
                <SectionDescription>
                    Product Manager allows creating and distributing builds of the operating system for your hardware,
                    with custom provisioning settings and flexible OS configurations parameters.
                </SectionDescription>

                <div className={classes.productContainer}>
                    <UncontrolledSelectField
                        value={currentProductId}
                        options={productOptions}
                        onChange={handleProductChange}
                        disabled={!props.products.length}
                        placeholder="You don't have any products"
                        fullWidth
                    />
                    <div className={classes.buttonsContainer}>
                        <DangerOutlinedButton
                            onClick={() => setIsDeleteProductDialogOpen(true)}
                            className={classes.deleteButton}
                            disabled={!props.products.length || cannotManageProducts}
                            tooltipProps={{
                                title: "Your role does not allow you to delete products",
                                hide: !cannotManageProducts,
                            }}
                        >
                            Delete
                        </DangerOutlinedButton>
                        <PrimaryOutlinedButton
                            onClick={setIsCreateDialogOpen}
                            disabled={isOverlimit || cannotManageProducts}
                            tooltipProps={{
                                title: getCreateButtonTooltip(),
                            }}
                        >
                            Create
                        </PrimaryOutlinedButton>
                    </div>
                </div>
                {currentProduct ? (
                    <React.Fragment>
                        <_Product product={currentProduct} />
                        <BuildsTable product={currentProduct} />
                    </React.Fragment>
                ) : null}
            </PageContainer>
            <ConfirmDialog
                title="Delete product"
                open={isDeleteProductDialogOpen}
                onConfirm={confirmDeleteProductDialog}
                onClose={() => setIsDeleteProductDialogOpen(false)}
                content={
                    <>
                        Are you sure you want to delete{" "}
                        <span className={classes.boldText}>
                            &quot;{currentProduct?.customBuildConfig?.productName || ""}&quot;
                        </span>
                        ? This will also delete all build artifacts and logs related to the product. This cannot be
                        undone!
                    </>
                }
                dangerButton
                primaryActionText="Delete"
            />
            <CreateDialog
                open={isCreateDialogOpen}
                toggleDialog={setIsCreateDialogOpen}
                onSuccess={(product) => setCurrentProductId(product.id)}
            />
        </React.Fragment>
    );
};

const ProductPage = () => {
    const isLoading = useSelector((state: RootState) => state.products.list.status);
    const products = useSelector(selectProducts);
    const sortedProducts = _.sortBy([...products], (item) => [item.customBuildConfig?.productName.toLowerCase()]);

    if (isLoading !== "fulfilled") {
        return <LoadingBox />;
    }

    return <ProductManagerPage products={sortedProducts} />;
};

export default ProductPage;
