import { DeviceStatus, LicenseActivationStatus, PolicyStatus } from "@dashboard/devices/types";

export const licenseStatusFilterOptions: { value: LicenseActivationStatus; title: string }[] = [
    { value: "Used", title: "Used" },
    { value: "Unused", title: "Unused" },
];
export const licenseStatusFilterOptionsArray = licenseStatusFilterOptions.map((item) => item.value);

export const deviceStatusFilterOptions: { value: DeviceStatus; title: string }[] = [
    { value: "Online", title: "Online" },
    { value: "Offline", title: "Offline" },
];
export const deviceStatusFilterOptionsArray = deviceStatusFilterOptions.map((item) => item.value);

export const policyStatusFilterOptions: { value: PolicyStatus; title: string }[] = [
    { value: "Compliant", title: "Compliant" },
    { value: "Non-compliant", title: "Non-compliant" },
];
export const policyStatusFilterOptionsArray = policyStatusFilterOptions.map((item) => item.value);

const groupNameFilterOptions: Record<string, boolean> = {};
const codenameFilterOptions: Record<string, boolean> = {};
const versionFilterOptions: Record<string, boolean> = {};

type AvailableFilterStatusOptions =
    | typeof licenseStatusFilterOptions
    | typeof deviceStatusFilterOptions
    | typeof policyStatusFilterOptions
    | typeof groupNameFilterOptions
    | typeof codenameFilterOptions
    | typeof versionFilterOptions;

export type DevicesFilterTypes =
    | "license_activationStatus"
    | "device_status"
    | "policy_status"
    | "group_name"
    | "codename"
    | "system_version";

export const propertiesOptionsFilterMap: Record<DevicesFilterTypes, AvailableFilterStatusOptions> = {
    "license_activationStatus": licenseStatusFilterOptions,
    "device_status": deviceStatusFilterOptions,
    "policy_status": policyStatusFilterOptions,
    "group_name": groupNameFilterOptions,
    "codename": groupNameFilterOptions,
    "system_version": groupNameFilterOptions,
};

export const getFiltersCount = (items: Record<DevicesFilterTypes, string[]>): number => {
    return items.device_status.length + items.license_activationStatus.length + items.policy_status.length;
};
