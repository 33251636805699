import clsx from "clsx";
import React from "react";
import Highlight from "react-highlighter";
import { permissionService } from "src/services/permissionService/permissionService";
import { RootState, useDispatch, useSelector } from "src/store";
import { selectGroupById } from "src/ui/containers/dashboard/groups/store/selectors";
import { selectWorkspace } from "src/ui/containers/dashboard/workspaces/store/selectors";
import { Tooltip } from "src/ui/shared/Tooltip";

import { getSummaryIndicatorColor, useDeviceStatus, usePolicyStatus } from "@dashboard/devices/DevicesPage.utils";
import {
    addBatchSelection,
    removeBatchSelection,
    selectBatchActionIsActiveLicense,
    selectBatchActionIsLicenseSelectable,
    selectBatchActionIsLicenseSelected,
    selectDetailsIsLicenseSelected,
    selectSearchQuery,
    showDetails,
} from "@dashboard/devices/store";
import { getIPs } from "@dashboard/devices/store/helpers";
import { DeviceState, License as LicenseType } from "@dashboard/devices/types";
import { getDeviceOnlineSince, getHumanReadableDeviceUptime } from "@dashboard/devices/utils/device";
import { Checkbox, TableRow, useTheme } from "@mui/material";

import { Connection, getInternetConnection } from "./internetUtils";
import { IndicatorTableCell, NoPaddingTableCell, TableCell, useStyles } from "./License.style";

interface Props {
    license: LicenseType;
}

const License = (props: Props) => {
    const theme = useTheme();
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentWorkspace = useSelector(selectWorkspace);
    const licenseGroup = useSelector((state: RootState) => selectGroupById(state, props.license.groupId));
    const batchActionIsActiveLicense = useSelector(selectBatchActionIsActiveLicense);
    const isLicenseSelected = useSelector((state) => selectDetailsIsLicenseSelected(state, props.license.id));
    const isLicenseChecked = useSelector((state) => selectBatchActionIsLicenseSelected(state, props.license?.id));
    const isLicenseCheckable = useSelector((state) => selectBatchActionIsLicenseSelectable(state, props.license));
    const searchQuery = useSelector(selectSearchQuery);
    const {
        getOnlineStatusHint,
        getOnlineStatusColor,
        getLicenseStatusColor,
        getLicenseStatusHint,

        getSummaryIndicatorHint,
    } = useDeviceStatus();
    const { getPolicyStatusHint, getPolicyStatusColor } = usePolicyStatus();

    const device = props.license?.device;
    const deviceState: Partial<DeviceState> = device?.lastState ?? {};
    const deviceIps = getIPs(deviceState);
    const activationCode = props.license?.activationCode ?? "-";
    const comment = props.license?.comment ?? "-";
    const name = device?.name ?? "-";
    const description = device?.description ?? "-";
    const product = device?.product ?? "-";
    const serial = device?.serial ?? "-";
    const ipv4 = deviceIps?.ipv4 ?? "-";
    const ipv6 = deviceIps?.ipv6 ?? "-";
    const emteriaVersion = deviceState.version?.emteriaVersion ?? "-";
    const uptimeHumanReadable = getHumanReadableDeviceUptime(deviceState?.datetime?.elapsedMs, device?.isOnline) ?? "-";
    const onlineSinceHumanReadable = getDeviceOnlineSince(device) ?? "-";

    const { groupAbility } = permissionService();
    const mayUpdateLicenseGroup = licenseGroup
        ? groupAbility(licenseGroup, currentWorkspace).can("update", "Group")
        : false;

    const handleLicenseClick = () => {
        if (props.license.id) {
            dispatch(
                showDetails({
                    type: "license",
                    selectedId: props.license.id,
                }),
            );
        }
    };
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.license.id) {
            if (event.target.checked) {
                dispatch(addBatchSelection(props.license.id));
            } else {
                dispatch(removeBatchSelection(props.license.id));
            }
        }
    };
    const currentConnection = getInternetConnection(deviceState.networks);

    const getInternetIconClass = (connection: Connection): string => {
        if (connection.interface?.startsWith("eth")) {
            return "fas fa-fw fa-network-wired";
        } else {
            return "fas fa-fw fa-wifi";
        }
    };

    const getInternetTooltip = (connection: Connection) => {
        if (!connection.ip) {
            return "No connection";
        }
        if (connection.interface?.startsWith("eth")) {
            return "Ethernet connection";
        }
        if (connection.interface?.startsWith("wlan")) {
            return "WIFI connection";
        } else {
            return "No connection";
        }
    };

    return (
        <>
            <TableRow
                onClick={handleLicenseClick}
                style={{
                    cursor: "pointer",
                    backgroundColor: isLicenseSelected ? theme.palette.blue[50] : "unset",
                }}
            >
                <NoPaddingTableCell>
                    <Checkbox
                        className={classes.licenseItemCheckbox}
                        size="small"
                        onChange={handleCheckboxChange}
                        checked={isLicenseChecked}
                        disabled={!isLicenseCheckable || !mayUpdateLicenseGroup}
                        style={{ visibility: batchActionIsActiveLicense ? "visible" : "hidden" }}
                    />
                </NoPaddingTableCell>
                <IndicatorTableCell>
                    <Tooltip placement="right" title={getSummaryIndicatorHint(props.license)}>
                        <div
                            className={classes.licenseSummaryLegacyIndicator}
                            style={{
                                backgroundColor: getSummaryIndicatorColor(getSummaryIndicatorHint(props.license)),
                            }}
                        />
                    </Tooltip>
                </IndicatorTableCell>
                <TableCell>
                    <div className={classes.tableCellContent}>
                        <Tooltip placement="top-start" title={`Activation code: ${activationCode}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-at")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {activationCode}
                                </Highlight>
                            </div>
                        </Tooltip>
                        <Tooltip placement="top-start" title={`Comment: ${comment}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-comment")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {comment}
                                </Highlight>
                            </div>
                        </Tooltip>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={classes.tableCellContent}>
                        <Tooltip placement="top-start" title={`Name: ${name}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-tag")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {name}
                                </Highlight>
                            </div>
                        </Tooltip>
                        <Tooltip placement="top-start" title={`Description: ${description}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-pen-to-square")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {description}
                                </Highlight>
                            </div>
                        </Tooltip>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={classes.tableCellContent}>
                        <Tooltip
                            placement="top-start"
                            title={`Codename: ${product}`}
                            className={classes.licensePropertyContainer}
                        >
                            <div>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-microchip")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {product}
                                </Highlight>
                            </div>
                        </Tooltip>
                        <Tooltip placement="top-start" title={`Serial: ${serial}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-hashtag")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {serial}
                                </Highlight>
                            </div>
                        </Tooltip>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={classes.tableCellContent}>
                        <Tooltip placement="top-start" title={`OS version: ${emteriaVersion}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-code-fork")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {emteriaVersion}
                                </Highlight>
                            </div>
                        </Tooltip>
                        <Tooltip placement="top-start" title={`Device uptime: ${uptimeHumanReadable}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-clock")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {uptimeHumanReadable}
                                </Highlight>
                            </div>
                        </Tooltip>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={classes.tableCellContent}>
                        <Tooltip placement="top" title={getInternetTooltip(currentConnection)}>
                            <div className={classes.licensePropertyContainer}>
                                <i
                                    className={clsx(
                                        classes.licensePropertyIcon,
                                        getInternetIconClass(currentConnection),
                                    )}
                                />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {currentConnection.interface?.startsWith("eth")
                                        ? "Ethernet"
                                        : device?.lastState?.wifi?.ssid || "-"}
                                </Highlight>
                            </div>
                        </Tooltip>
                        <Tooltip placement="top-start" title={`Online since: ${onlineSinceHumanReadable}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-calendar")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {onlineSinceHumanReadable}
                                </Highlight>
                            </div>
                        </Tooltip>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={classes.tableCellContent}>
                        <Tooltip placement="top-start" title={`IPv4: ${ipv4}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-globe")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {ipv4}
                                </Highlight>
                            </div>
                        </Tooltip>
                        <Tooltip placement="top-start" title={`IPv6: ${ipv6}`}>
                            <div className={classes.licensePropertyContainer}>
                                <i className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-globe")} />
                                <Highlight search={searchQuery} className={classes.licensePropertyValue}>
                                    {ipv6}
                                </Highlight>
                            </div>
                        </Tooltip>
                    </div>
                </TableCell>
                <TableCell>
                    <div className={classes.licensePropertyValue}>
                        <Tooltip placement="top" title={getOnlineStatusHint(props.license)}>
                            <span
                                className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-signal")}
                                style={{ color: getOnlineStatusColor(props.license) }}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={getPolicyStatusHint(props.license)}>
                            <span
                                className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-table-list")}
                                style={{ color: getPolicyStatusColor(props.license) }}
                            />
                        </Tooltip>
                        <Tooltip placement="top" title={getLicenseStatusHint(props.license)}>
                            <span
                                className={clsx(classes.licensePropertyIcon, "fas fa-fw fa-file")}
                                style={{ color: getLicenseStatusColor(props.license) }}
                            />
                        </Tooltip>
                    </div>
                </TableCell>
            </TableRow>
            <TableRow style={{ height: 10 }} />
        </>
    );
};

export default React.memo(License);
