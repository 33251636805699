import React from "react";
import { LocalStorage, LocalStorageKey } from "src/services/localStorageService";

import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, BoxProps, Collapse, IconButton } from "@mui/material";

interface CollapsibleGroupContextType {
    expandAll: boolean | null;
    setExpandAll: React.Dispatch<React.SetStateAction<boolean | null>>;
}
export const CollapsibleGroupContext = React.createContext<CollapsibleGroupContextType>({
    expandAll: null,
    setExpandAll: () => null,
});
type CollapsibleGroupProps = React.PropsWithChildren<{
    initialValue?: boolean | null;
}>;
export const CollapsibleGroupProvider = (props: CollapsibleGroupProps) => {
    const [expandAll, setExpandAll] = React.useState<boolean | null>(props.initialValue || null);

    return (
        <CollapsibleGroupContext.Provider value={{ expandAll, setExpandAll }}>
            {props.children}
        </CollapsibleGroupContext.Provider>
    );
};

type CollapsibleContextType = {
    open: boolean;
    setOpen: (open: boolean) => void;
};
const CollapsibleContext = React.createContext<CollapsibleContextType>({
    open: false,
    setOpen: () => null,
});
type CollapsibleProviderType = React.PropsWithChildren<{
    initialValue?: boolean;
    onChange?: (open: boolean) => void;
}>;
export const CollapsibleProvider = (props: CollapsibleProviderType) => {
    const [open, setOpen] = React.useState(!!props.initialValue);
    const { expandAll, setExpandAll } = React.useContext(CollapsibleGroupContext);

    const onOpen = (value: boolean) => {
        setOpen(value);
        if (props.onChange) {
            props.onChange(value);
            setExpandAll(null);
        }
    };

    React.useEffect(() => {
        if (expandAll !== null) {
            setOpen(expandAll);
        }
        if (expandAll !== null && props.onChange) {
            setOpen(expandAll);
            props.onChange(expandAll);
        }
    }, [expandAll, props]);

    return (
        <CollapsibleContext.Provider value={{ open, setOpen: onOpen }}>{props.children}</CollapsibleContext.Provider>
    );
};

export const CollapsibleSectionSummary = ({ children, ...props }: React.PropsWithChildren<BoxProps>) => {
    const context = React.useContext(CollapsibleContext);

    if (!context) {
        throw new Error("CollapsibleSectionSummary have to be used inside CollapsibleContext");
    }

    return (
        <Box display="flex" alignItems="center" {...props}>
            <IconButton onClick={() => context.setOpen(!context.open)} size="large">
                {context.open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
            <div style={{ width: "100%" }}>{children}</div>
        </Box>
    );
};

export const CollapsibleSectionDetails = (props: React.PropsWithChildren<object>) => {
    const context = React.useContext(CollapsibleContext);

    if (!context) {
        throw new Error("CollapsibleSectionDetails have to be used inside CollapsibleContext");
    }

    return (
        <Collapse in={context.open} collapsedSize={0}>
            <Box style={{ padding: 0, paddingTop: 0 }}>{props.children}</Box>
        </Collapse>
    );
};

export const useCollapsiblePersistence = (storageKey: LocalStorageKey, sectionkey: string) => {
    const localStorageValue = () => LocalStorage.getItem<Record<string, boolean>>(storageKey) || {};
    const onChange = (open: boolean) => {
        const collapsedSection = localStorageValue();
        collapsedSection[sectionkey] = open;
        LocalStorage.setItem(storageKey, collapsedSection);
    };
    return { value: localStorageValue()[sectionkey], onChange };
};
