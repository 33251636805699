import { useDispatch, useSelector } from "src/store";
import * as yup from "yup";

import ProductEditor from "@dashboard/products/components/ProductDetails/ProductEditor/ProductEditor";
import { createProduct } from "@dashboard/products/store";
import { selectProducts } from "@dashboard/products/store/selectors/products";
import { Product } from "@dashboard/products/types";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { defaultBuildVariantOption } from "./fragments/Recipes";
import { productSchema } from "./product.validation";

const defaultValues: Product = {
    id: 0,
    workspaceId: 0,
    isPublic: false,
    groupId: null,
    codename: "",
    androidVersion: "14",
    nightlyPeriod: 0,
    customBuildConfig: {
        productName: "",
        rootDevice: "caas",
        compatibility: ">=14",
        applications: [],
        removePackages: [],
        provisioningId: null,
        buildVariant: defaultBuildVariantOption,
    },
    changes: null,
};

interface Props {
    open: boolean;
    toggleDialog: VoidFunction;
    onSuccess?: (product: Product) => void;
}

export const CreateDialog = (props: Props) => {
    const workspace = useSelector(selectWorkspace);
    const workspaceGuid = useSelector(selectWorkspace)?.guid;
    const dispatch = useDispatch();
    const products = useSelector(selectProducts);
    const codenameAndroidVersion = products.map((item) => item.codename + item.androidVersion);

    const onSubmit = async (values: Product) => {
        try {
            const fullCodename = workspaceGuid ? `${workspaceGuid}_${values.codename}` : values.codename;
            const modifiedProduct = { ...values, codename: fullCodename };
            const data = await dispatch(createProduct(modifiedProduct)).unwrap();
            showSuccessToast("Product created");
            props.toggleDialog();
            if (props.onSuccess) {
                props.onSuccess(data);
            }
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Error while creating product");
        }
    };
    const onValidate = async (values: Product) => {
        const errors: Partial<Record<keyof Product, string>> = {};

        if (codenameAndroidVersion.includes(values.codename + values.androidVersion)) {
            errors.codename = "Product with this codename and android version already exists";
            errors.androidVersion = "Product with this codename and android version already exists";
        }

        try {
            await productSchema.validate(values, { abortEarly: false });
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.inner.forEach((validationError) => {
                    if (validationError.path) {
                        errors[validationError.path as keyof Product] = validationError.message;
                    }
                });
            }
        }

        return errors;
    };

    if (!props.open || !workspace?.id) {
        return null;
    }

    return (
        <ProductEditor
            product={{
                ...defaultValues,
                workspaceId: workspace?.id,
            }}
            onValidate={onValidate}
            onConfirm={onSubmit}
            onClose={props.toggleDialog}
        />
    );
};
