import _ from "lodash";
import { RootState } from "src/store";

import { DevicesFilterTypes, propertiesOptionsFilterMap } from "@dashboard/devices/components/Filtering/Filters.utils";
import { localState } from "@dashboard/devices/store";
import { typeSafeObjectKeys } from "@dashboard/devices/utils/typeSafeObjectKeys";
import { ReleasesFilterTypes } from "@dashboard/downloads/components/DownloadsTable/Filter/Filter.utils";
import { createSelector } from "@reduxjs/toolkit";

export type FilterState = {
    isOpen: boolean;
    isActive: boolean;
    properties: Record<DevicesFilterTypes | ReleasesFilterTypes, string[]>;
};

const defaultFilter: FilterState = {
    isOpen: false,
    isActive: true,
    properties: {
        "license_activationStatus": [],
        "device_status": [],
        "policy_status": [],
        "group_name": [],
        "releasesCodename": [],
        "releasesProduct": [],
        "codename": [],
        "system_version": [],
        "channel": ["Live", "Beta"],
    },
};

const devicesPageFilterState = (state: RootState): FilterState => localState(state).filters;
const selectFilterProperties = (state: RootState): Record<DevicesFilterTypes | ReleasesFilterTypes, string[]> =>
    localState(state).filters?.properties;
const selectMergedFiltersByProperty = <T extends DevicesFilterTypes | ReleasesFilterTypes>() =>
    createSelector(selectFilterProperties, (properties) => (property: T, options: string[]) => {
        const currentFilterProperties = properties[property] ?? [];
        return _.fromPairs(options.map((key) => [key, currentFilterProperties.includes(key)]));
    });
const selectFilterIsActive = (state: RootState): boolean => localState(state).filters?.isActive;
const selectActiveFilters = createSelector(selectFilterProperties, (properties) => {
    return typeSafeObjectKeys(properties).filter((key) => {
        const isValidFilter = key in propertiesOptionsFilterMap;
        if (!isValidFilter) {
            return false;
        }

        const currentFilterOptionLength = properties[key]?.length;
        return currentFilterOptionLength > 0;
    });
});

export {
    defaultFilter,
    devicesPageFilterState,
    selectActiveFilters,
    selectFilterIsActive,
    selectFilterProperties,
    selectMergedFiltersByProperty,
};
