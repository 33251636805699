import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import { changePassword } from "src/services/accountService";
import ApmLink from "src/ui/shared/ApmLink";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { PageContainer } from "@dashboard/shared/styles";
import { Box, InputLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        padding: 10,
    },
    pos: {
        marginBottom: 20,
    },
});

type FormValues = {
    currentPassword: string;
    newPasswordValue: string;
    newPasswordRepeat: string;
};

const PasswordChangePage = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const onSubmit = async (values: FormValues) => {
        try {
            await changePassword(values);
            showSuccessToast("Password changed successfully");
            navigate("/login");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        }
    };
    const onValidate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.currentPassword) {
            errors.currentPassword = "Current password cannot be empty";
        }

        if (!values.newPasswordValue) {
            errors.newPasswordValue = "New password cannot be empty";
        }

        if (!values.newPasswordRepeat) {
            errors.newPasswordRepeat = "New password must be repeated";
        } else if (values.newPasswordValue !== values.newPasswordRepeat) {
            errors.newPasswordRepeat = "Passwords do not match";
        }

        return errors;
    };

    return (
        <PageContainer style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
            <Form<FormValues>
                onSubmit={onSubmit}
                validate={onValidate}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <Typography variant="h5" component="h2" style={{ marginBottom: 5 }}>
                            Change Password
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                            Please enter your current password before choosing a new one. If you have forgot your
                            current password, please reset it <ApmLink to="/account/recover">here</ApmLink>.
                        </Typography>
                        <Box style={{ marginBottom: 12 }}>
                            <InputLabel style={{ marginBottom: 5 }}>Current password</InputLabel>
                            <TextField name="currentPassword" type="password" fullWidth />
                        </Box>
                        <Box style={{ marginBottom: 12 }}>
                            <InputLabel style={{ marginBottom: 5 }}>New password</InputLabel>
                            <TextField name="newPasswordValue" type="password" fullWidth />
                        </Box>
                        <Box style={{ marginBottom: 24 }}>
                            <InputLabel style={{ marginBottom: 5 }}>Repeat new password</InputLabel>
                            <TextField name="newPasswordRepeat" type="password" fullWidth />
                        </Box>

                        <Box style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
                            <SecondaryButton onClick={() => navigate("/account/edit")} disabled={submitting}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton type="submit" loading={submitting} disabled={submitting}>
                                Submit
                            </PrimaryButton>
                        </Box>
                    </form>
                )}
            />
        </PageContainer>
    );
};

export default PasswordChangePage;
