import React, { useMemo, useState } from "react";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";
import GroupTableRow from "src/ui/containers/dashboard/groups/components/table/GroupTableRow";
import TableCell from "src/ui/containers/dashboard/groups/components/table/TableCell";
import { SecondaryButton } from "src/ui/shared/CustomButton";
import FreshAccountMessage from "src/ui/shared/FreshAccountMessage";
import { Header } from "src/ui/shared/table/TableHeader";

import { Search } from "@dashboard/devices/components/Header/Search";
import { closeBatchMode, closeDetails, showDetails } from "@dashboard/devices/store/index";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Paper, TableBody, TableContainer, TableRow } from "@mui/material";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import { fuzzyFilter } from "@shared/table/Tanstack.utils";
import {
    ColumnDef,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { SidePanel } from "../shared/components/SidePanel/SidePanel";
import CreateGroupDialog from "./components/CreateGroupDialog";
import { ComplianceStatusIcon } from "./components/table/ComplianceStatus";
import { OnlineStatusIcon } from "./components/table/OnlineStatus";
import { useStyles } from "./GroupsPage.style";
import { selectGroupsOverviewData } from "./store/selectors";

export interface GroupOverviewData {
    name: string;
    description: string;
    onlineDevices: string;
    compliantDevices: string;
    numberOfFiles: string;
    numberOfDevices: string;
    id: number;
    isDefault: boolean;
}

const GroupsPage = () => {
    const [globalFilter, setGlobalFilter] = useState("");
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const { groupAbility } = permissionService();
    const tableData = useSelector(selectGroupsOverviewData);
    const workspace = useSelector(selectWorkspace);
    const isExpired = useSelector(selectSubscriptionExpired);

    const tableColumns: ColumnDef<GroupOverviewData, unknown>[] = useMemo(
        () => [
            {
                header: "Name",
                accessorKey: "name",
                cell: (info) => <TableCell {...info} globalFilter={globalFilter} />,
                enableSorting: true,
            },
            {
                header: "Description",
                accessorKey: "description",
                cell: (info) => <TableCell {...info} globalFilter={globalFilter} />,
                enableSorting: true,
            },
            {
                header: "Files",
                accessorKey: "numberOfFiles",
                cell: (info) => <TableCell {...info} globalFilter={globalFilter} />,
                enableSorting: true,
                sortingFn: "alphanumeric",
            },
            {
                header: "Devices",
                accessorKey: "numberOfDevices",
                cell: (info) => <TableCell {...info} globalFilter={globalFilter} />,
                enableSorting: true,
                sortingFn: "alphanumeric",
            },
            {
                header: "Online",
                accessorKey: "onlineDevices",
                cell: (info) => (
                    <TableCell {...info} globalFilter={globalFilter}>
                        <OnlineStatusIcon {...info} />
                    </TableCell>
                ),
                enableSorting: true,
                sortingFn: "alphanumeric",
            },
            {
                header: "Compliance",
                accessorKey: "compliantDevices",
                cell: (info) => (
                    <TableCell {...info} globalFilter={globalFilter}>
                        <ComplianceStatusIcon {...info} />
                    </TableCell>
                ),
                enableSorting: true,
                sortingFn: "alphanumeric",
            },
        ],
        [globalFilter],
    );

    const table = useReactTable<GroupOverviewData>({
        data: tableData,
        columns: tableColumns,
        filterFns: { fuzzyFilter },
        state: {
            globalFilter,
        },
        onGlobalFilterChange: setGlobalFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });
    const handleRowClick = (groupId: number) => {
        if (groupId) {
            dispatch(
                showDetails({
                    type: "group",
                    selectedId: groupId,
                }),
            );
        }
    };

    React.useEffect(() => {
        return () => {
            dispatch(closeDetails());
            dispatch(closeBatchMode());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContainer className={classes.modalContainer}>
            <SidePanel />
            <PageTitleWithIcon title="Groups" iconName="fa-solid fa-folder-tree" />
            <SectionDescription>
                Get detailed insights into the current status of your device groups.
            </SectionDescription>
            {workspace ? (
                <>
                    <div className={classes.searchContainer}>
                        <Search
                            defaultValue={globalFilter}
                            handleChange={(value) => setGlobalFilter(String(value))}
                            handleClear={() => setGlobalFilter("")}
                            placeholder="Enter a keyword to search..."
                        />
                        <SecondaryButton
                            onClick={() => setCreateDialogOpen(true)}
                            disabled={groupAbility(null, workspace).cannot("create", "Group") || isExpired}
                            startIcon={<i className="fa-solid fa-plus" />}
                            size="small"
                        >
                            Add group
                        </SecondaryButton>
                    </div>
                    <TableContainer component={Paper}>
                        <MinimalTable>
                            <MinimalTableHeader>
                                {table.getHeaderGroups().map((headerGroup) => (
                                    <TableRow key={headerGroup.id}>
                                        {headerGroup.headers.map((header) => (
                                            <Header key={header.id} {...header} />
                                        ))}
                                        <MinimalTableHeaderCell align="right">Actions</MinimalTableHeaderCell>
                                    </TableRow>
                                ))}
                            </MinimalTableHeader>
                            <TableBody>
                                {table.getRowModel().rows.length === 0 ? (
                                    <TableRow>
                                        <MinimalTableCell
                                            colSpan={tableColumns.length}
                                            className={classes.noResultsRow}
                                        >
                                            This workspace has no groups. You can create a new group by using the button
                                            above.
                                        </MinimalTableCell>
                                    </TableRow>
                                ) : (
                                    table
                                        .getRowModel()
                                        .rows.map((row, index) => (
                                            <GroupTableRow
                                                index={index}
                                                key={row.id}
                                                row={row}
                                                onClick={handleRowClick}
                                            />
                                        ))
                                )}
                            </TableBody>
                        </MinimalTable>
                    </TableContainer>
                    <CreateGroupDialog
                        open={createDialogOpen}
                        onClose={() => {
                            setCreateDialogOpen(false);
                        }}
                    />
                </>
            ) : (
                <FreshAccountMessage />
            )}
        </PageContainer>
    );
};

export default GroupsPage;
