import { Device } from ".";

export type MdmCommand = {
    id: number;
    fingerprint: string;
    // null for GroupCommands
    device: Device | null;
    created: string;
    creator: string;
    comment: string | null;
    origin: string;
    payload: string;
    updated: string;
    status: MdmCommandStatus;
    statuses?: MdmCommandStatus[];
    report: string | null;
};

export enum MdmCommandStatus {
    Unknown = 0,
    Pending = 1,
    Running = 2,
    Successful = 3,
    Failed = 4,
    Received = 5,
    Ignored = 6,
    Queued = 7,
}

export const DeviceMdmCommandStatusIcon = {
    [MdmCommandStatus.Unknown]: "fas fa-solid fa-calendar-days",
    [MdmCommandStatus.Pending]: "fas fa-solid fa-hourglass-start",
    [MdmCommandStatus.Running]: "fas fa-solid fa-refresh",
    [MdmCommandStatus.Successful]: "fas fa-solid fa-check",
    [MdmCommandStatus.Failed]: "fas fa-solid fa-times",
    [MdmCommandStatus.Received]: "fas fa-solid fa-database",
    [MdmCommandStatus.Ignored]: "fas fa-solid fa-ban",
    [MdmCommandStatus.Queued]: "fas fa-solid fa-hourglass-o",
};

export enum MdmCommandOrigin {
    DeviceOrigin = "device/",
    GroupOrigin = "group/",
}

export const MdmCommandOriginIcon = {
    [MdmCommandOrigin.DeviceOrigin]: "fas fa-solid fa-desktop",
    [MdmCommandOrigin.GroupOrigin]: "fas fa-solid fa-sitemap",
};

export type MdmCommands = MdmCommand[];

export enum ComplexCommandNames {
    StartPackage = "startPackage",
    StopPackage = "stopPackage",
    UninstallPackage = "uninstallPackage",
}
