import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    dropzoneContainer: {
        paddingBottom: 15,
        margin: "0 auto",
        marginTop: 10,
        display: "block",
    },
    uploadContainer: {
        width: "100%",
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: 20,
        marginTop: 10,
        marginBottom: 5,
        "& .MuiInputBase-root.Mui-disabled": {
            color: theme.palette.black[100],
            borderColor: theme.palette.black[100],
        },
    },
    contentBox: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    uploadTooltipContainer: {
        width: "100%",
    },
}));
