import React from "react";
import { LocalStorage } from "src/services/localStorageService";

import { Menu } from "@devices/components/Header/Menu";
import { Search } from "@devices/components/Header/Search";
import { Dropdown, DropdownItem, DropdownList } from "@devices/components/shared";
import { DeviceTableData } from "@devices/DevicesPage.utils";
import { Box, Checkbox, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { SecondaryButton } from "@shared/CustomButton";
import { Table } from "@tanstack/react-table";

const useStyles = makeStyles((_theme: Theme) => ({
    menuItem: {
        paddingTop: "5px",
        height: "1.85rem",
    },
    box: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        gap: "5px",
    },
    dropdownBox: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        width: "100%",
        padding: "25px 40px",
    },
}));

type Props = {
    searchQuery: string;
    setSearchQuery: (value?: string | number | readonly string[]) => void;
    onClearSearchQuery: () => void;
    setVisibleColumns: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    table: Table<DeviceTableData>;
};
export const ManagementSection = (props: Props) => {
    const [isColumnToggleOpen, setIsColumnToggleOpen] = React.useState(false);

    const classes = useStyles();
    const anchorRefVisibility = React.useRef(null);

    // exclude actions columns from the list of columns where visibility can be toggled
    const tableColumns = props.table.getAllColumns().filter((column) => column.columnDef.id !== "moveLicenseCheckbox");

    const handleColumnToggle = () => {
        setIsColumnToggleOpen((prev) => !prev);
    };
    const handleColumnVisibilityChange = (id: string) => {
        props.setVisibleColumns((prevVisibleColumns) => {
            const newVisibilityState = {
                ...prevVisibleColumns,
                [id]: prevVisibleColumns[id] !== undefined ? !prevVisibleColumns[id] : false,
            };
            LocalStorage.setItem("devicesPageVisibleColumns", newVisibilityState);
            return newVisibilityState;
        });
    };

    return (
        <Box style={{ display: "flex", marginBottom: 10, justifyContent: "space-between", alignItems: "center" }}>
            <Search
                defaultValue={props.searchQuery}
                handleChange={props.setSearchQuery}
                handleClear={props.onClearSearchQuery}
                placeholder="Enter a keyword to search..."
            />

            <Box className={classes.box}>
                <Menu />
                <Dropdown>
                    <div ref={anchorRefVisibility}>
                        <SecondaryButton
                            onClick={handleColumnToggle}
                            size="small"
                            startIcon={<i className="fas fa-tasks" />}
                        >
                            Columns
                        </SecondaryButton>
                    </div>
                    <DropdownList
                        isOpen={isColumnToggleOpen}
                        onClickAway={() => setIsColumnToggleOpen(false)}
                        anchorRef={anchorRefVisibility}
                        eventType="onKeyDown"
                    >
                        <Box className={classes.dropdownBox}>
                            {tableColumns.map((column) => (
                                <Box key={column.id}>
                                    <div style={{ textAlign: "center" }}>
                                        <Typography variant="body2" style={{ display: "inline", fontWeight: 500 }}>
                                            {column.id}
                                        </Typography>
                                    </div>
                                    {column.getLeafColumns().map((leafColumn) => (
                                        <DropdownItem
                                            key={leafColumn.columnDef.id}
                                            className={classes.menuItem}
                                            onClick={() => handleColumnVisibilityChange(leafColumn.id)}
                                        >
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Checkbox
                                                    checked={leafColumn.getIsVisible()}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onChange={() => handleColumnVisibilityChange(leafColumn.id)}
                                                    inputProps={{ "aria-labelledby": `label-${leafColumn.id}` }}
                                                />
                                                <Typography
                                                    id={`label-${leafColumn.id}`}
                                                    variant="body2"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {leafColumn.columnDef.header
                                                        ? (leafColumn.columnDef.header as string)
                                                        : "Status"}
                                                </Typography>
                                            </Box>
                                        </DropdownItem>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    </DropdownList>
                </Dropdown>
            </Box>
        </Box>
    );
};
