import { useSelector } from "src/store";

import { selectGroups } from "@groups/store/selectors";
import { permissionService } from "@services/permissionService/permissionService";
import { selectWorkspace } from "@workspaces/store/selectors";

export const useVisibilityOptions = () => {
    const workspace = useSelector(selectWorkspace);
    const workspaceGroups = useSelector(selectGroups);
    const { workspaceAbility, groupAbility } = permissionService();

    if (workspace) {
        const workspaceOptions = {
            category: "Workspace",
            options: [
                {
                    label: workspace.name,
                    value: workspace.id,
                    disabled: workspaceAbility(workspace).cannot("manage", "File"),
                },
            ],
        };
        const groupOptions = {
            category: "Groups",
            options: workspaceGroups.map((group) => {
                return {
                    label: group.name,
                    value: group.id,
                    disabled: groupAbility(group, workspace).cannot("manage", "File"),
                };
            }),
        };

        return [workspaceOptions, groupOptions];
    }

    return [];
};
