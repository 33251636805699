import config from "../config.json";

const { env, reduxVersion } = config;

type EnvConfig = {
    apiLegacyUrl: string;
    homepageUrl: string;
    apiGatewayUrl: string;
    paypalClientId: string;
    authClientId: string;
    authClientSecret: string;
};

type EnvConfigs = {
    Development: EnvConfig;
    Staging: EnvConfig;
    Production: EnvConfig;
    Test: EnvConfig;
};
const getEnvironment = () => {
    const domain = window.location.hostname;
    const port = window.location.port;

    if (domain === "hub.emteria.com") {
        return "Production";
    } else if (domain === "localhost" && port === "3000") {
        return "Development";
    } else if (domain === "localhost") {
        return "Test";
    } else {
        return "Staging";
    }
};
export function getEnv(): keyof EnvConfigs {
    const envName = getEnvironment();
    if (envName !== "Development" && envName !== "Staging" && envName !== "Production" && envName !== "Test") {
        throw new Error("Environment name not found");
    }

    return envName;
}

export function getApiGatewayUrl(): string {
    const name = getEnv();
    const _env = env[name];
    return `${_env.apiGatewayUrl}`;
}

export function getHomepageUrl(): string {
    const name = getEnv();
    const _env = env[name];
    return _env.homepageUrl;
}
export function getMvcLegacyUrl(): string {
    const name = getEnv();
    const _env = env[name];
    return `${_env.apiLegacyUrl}`;
}
export function getManagementUrl(): string {
    const envName = getEnv();
    const envConfig = env[envName];

    return `${envConfig.deviceManagement}/Home/ListDevices`;
}

type AuthClient = {
    clientId: string;
    clientSecret: string;
};
export function getAuthClient(): AuthClient {
    const name = getEnv();
    const _env = env[name];
    return {
        clientId: _env.authClientId,
        clientSecret: _env.authClientSecret,
    };
}

export function getReduxVersion(): string {
    if (typeof reduxVersion !== "string") {
        return "";
    }

    return reduxVersion;
}
