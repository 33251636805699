import { Form } from "react-final-form";
import { useNavigate } from "react-router";
import authService from "src/services/authService";
import { useDispatch, useSelector } from "src/store";
import { RadioField, RadioFieldGorup } from "src/ui/shared/form/RadioField";
import { TextField } from "src/ui/shared/form/TextField";

import { claimStarterSubscription } from "@devices/store";
import CircleIcon from "@mui/icons-material/Circle";
import {
    Alert,
    Box,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Step,
    StepLabel,
    Stepper,
    Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PrimaryButton } from "@shared/CustomButton";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";
import { fetchWorkspaces } from "@workspaces/store";
import { selectWorkspace } from "@workspaces/store/selectors";

import { selectDevices } from "../../devices/store/selectors/devices";

const useStyles = makeStyles(() => ({
    container: { width: 800, padding: 4, margin: "0 auto" },
    radioGrupe: { marginBottom: 20, marginTop: 20 },
    inputBox: { display: "flex", flexDirection: "column", alignItems: "center", gap: 20, marginTop: 8 },
    buttonBox: { display: "flex", justifyContent: "center", marginTop: 20 },
    list: { marginBottom: 20 },
    stepper: { marginBottom: 32 },
    switchButtonBox: { display: "flex", justifyContent: "center" },
    icon: { fontSize: 10 },
}));

type FormValues = {
    action: "join" | "create";
    email: string;
};

const steps = ["Your workspace", "Device enrollment"];

const WorkspaceStep = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const user = authService.getCurrentUser();

    const onFreePlanConfirm = async () => {
        try {
            await dispatch(claimStarterSubscription()).unwrap();
            await dispatch(fetchWorkspaces()).unwrap();
            showSuccessToast("Starter subscription successfully claimed");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message || "Couldn't claim the starter subscription");
        }
    };

    const onCopyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                showSuccessToast("Copied to clipboard");
            })
            .catch((err) => {
                console.error("Failed to copy text to clipboard", err);
            });
    };

    return (
        <Form<FormValues>
            onSubmit={() => undefined}
            initialValues={{ action: "join", email: user?.email || "" }}
            render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    <Box>
                        <Typography>
                            A workspace is a digital environment for managing your devices, files, configurations and
                            updates. Get started by joining a corporate workspace or create your own private workspace
                            with a free starter plan:
                        </Typography>

                        <RadioFieldGorup name="action" className={classes.radioGrupe}>
                            <RadioField label="Join existing corporate workspace" value="join" />
                            <RadioField label="Create a free workspace with a starter plan" value="create" />
                        </RadioFieldGorup>

                        {values.action === "join" ? (
                            <Box>
                                <Alert severity="info">
                                    Get started by sending your email to a collegue to invite you to their workspace.
                                </Alert>
                                <Box className={classes.inputBox}>
                                    <TextField name="email" label="Email" fullWidth disabled />
                                    <PrimaryButton onClick={() => onCopyToClipboard(values.email)}>
                                        Copy to clipboard
                                    </PrimaryButton>
                                </Box>
                            </Box>
                        ) : null}
                        {values.action === "create" ? (
                            <Box>
                                <Alert severity="warning">
                                    Please note that if you enroll into your free starter plan, you wont be able to join
                                    other free workspaces.
                                </Alert>
                                <Box className={classes.buttonBox}>
                                    <PrimaryButton size="large" onClick={onFreePlanConfirm}>
                                        Start free plan now
                                    </PrimaryButton>
                                </Box>
                            </Box>
                        ) : null}
                    </Box>
                </form>
            )}
        />
    );
};
const DeviceStep = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const onClick = () => navigate("/dashboard/downloads");

    return (
        <Box>
            <Typography>
                You are ready to install Android on your device and enroll it into your workspace for remote management
                and monitoring. Emteria offers different types of installation:
            </Typography>
            <List className={classes.list}>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon className={classes.icon} color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Downloadable GPT images: You can download Android releases directly from our website and flash it to
                    your device with any existing third-party software."
                    />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <CircleIcon className={classes.icon} color="primary" />
                    </ListItemIcon>
                    <ListItemText
                        primary="Dedicated OS installer: Our installer was built for providing all-in-one solution for downloading a
                    Android releases and flashing it to SD cards, mounted eMMC or USB flash drives."
                    />
                </ListItem>
            </List>

            <Box className={classes.switchButtonBox}>
                <PrimaryButton {...{ onClick }} size="large">
                    Switch to downloads
                </PrimaryButton>
            </Box>
        </Box>
    );
};

export const TutorialSection = () => {
    const classes = useStyles();
    const workspace = useSelector(selectWorkspace);
    const devices = useSelector(selectDevices);

    let activeStep = -1;

    if (!workspace && !devices.length) {
        activeStep = 0;
    } else if (!devices.length) {
        activeStep = 1;
    } else {
        activeStep = -1;
    }

    if (activeStep === -1) {
        return null;
    }
    return (
        <Card className={classes.container} elevation={3}>
            <CardContent>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 0 ? <WorkspaceStep /> : null}
                {activeStep === 1 ? <DeviceStep /> : null}
            </CardContent>
        </Card>
    );
};
