import fileSize from "filesize";
import { FormApi } from "final-form";
import _ from "lodash";
import React from "react";
import { Form } from "react-final-form";
import { EmteriaRoles } from "src/data/roles";
import { fetchAllFiles, FetchAllFilesPayload } from "src/ui/containers/admin/api";
import { convertToLocalDate } from "src/ui/containers/dashboard/devices/utils/dates";
import { withRequiredRole } from "src/ui/navigation/utils/withRequiredRole";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";
import { LoadingBox } from "src/ui/shared/Loading";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableCellWithTooltip,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { Header } from "src/ui/shared/table/TableHeader";
import { showErrorToast } from "src/ui/shared/toasts/Toasts";
import { Tooltip } from "src/ui/shared/Tooltip";

import { PageContainer } from "@dashboard/shared/styles";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Paper,
    TableBody,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import {
    createColumnHelper,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { useStyles } from "./AdminFilesPage.style";
import TableItem from "./TableItem";

type FormValues = { fileName: string; workspaceId: number };
type Status = "pending" | "fulfilled" | "rejected" | "uninitialized";
export type State = {
    data: API.File[];
    count: number;
    status: Status;
};
const defaultState: State = {
    data: [],
    count: 0,
    status: "uninitialized",
};

const columnHelper = createColumnHelper<API.File>();

const tableColumns = [
    columnHelper.display({
        id: "collapseButton",
        header: "",
        enableSorting: false,
    }),
    columnHelper.accessor("filename", {
        id: "filename",
        header: "Name",
        cell: (info) => (
            <MinimalTableCellWithTooltip title={info.getValue()} maxLength={40} style={{ maxWidth: "300px" }}>
                {info.getValue()}
            </MinimalTableCellWithTooltip>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor("workspaceId", {
        id: "workspaceId",
        header: () => (
            <Tooltip title="Workspace ID" placement="top">
                <span style={{ fontWeight: 600 }}>WID</span>
            </Tooltip>
        ),
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("groupId", {
        id: "groupId",
        header: () => (
            <Tooltip title="Group ID" placement="top">
                <span style={{ fontWeight: 600 }}>GID</span>
            </Tooltip>
        ),
        cell: (info) => <MinimalTableCell>{info.getValue() ?? "-"}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor((row) => fileSize(row.size || 0) ?? "-", {
        id: "fileSize",
        header: "Size",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
    }),
    columnHelper.accessor("ownerId", {
        id: "ownerId",
        header: "Owner",
        cell: (info) => <MinimalTableCell width={320}> {info.getValue()}</MinimalTableCell>,
        enableSorting: false,
    }),
    columnHelper.accessor((row) => (row.status ? "Uploaded" : "Initialized"), {
        id: "sourceType",
        header: "Status",
        cell: (info) => <MinimalTableCell>{info.getValue()}</MinimalTableCell>,
    }),
    columnHelper.accessor((row) => convertToLocalDate(row.createdDate, "ISO", "date"), {
        id: "modifiedDate",
        header: "Created date",
        cell: (info) => (
            <MinimalTableCell>
                <Tooltip title={convertToLocalDate(info.row.original.createdDate, "ISO", "dateTime")}>
                    <span>{info.getValue()}</span>
                </Tooltip>
            </MinimalTableCell>
        ),
        sortingFn: (rowA, rowB) => {
            const timeA = new Date(rowA.original.createdDate).getTime();
            const timeB = new Date(rowB.original.createdDate).getTime();
            return timeA - timeB;
        },
    }),
];

const AdminFilesPage = () => {
    const [state, setState] = React.useState<State>(defaultState);
    const [filterState, setFilterState] = React.useState<Partial<FormValues>>({});
    const classes = useStyles();
    const table = useReactTable<API.File>({
        data: state.data,
        columns: tableColumns,
        manualPagination: true,
        manualSorting: true,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        initialState: {
            sorting: [{ id: "modifiedDate", desc: true }],
            pagination: {
                pageIndex: 0,
                pageSize: 10,
            },
        },
    });

    const { pageIndex, pageSize } = table.getState().pagination;

    const onSubmit = (values: FormValues) => {
        table.setPageIndex(0);
        setFilterState(values);
    };
    const onClear = (form: FormApi<FormValues, Partial<FormValues>>) => {
        form.reset();
        setFilterState({});
        table.setPageIndex(0);
    };

    React.useLayoutEffect(() => {
        const sorting = table.getState().sorting[0] || { id: "modifiedDate", desc: false };

        setState((prev) => ({ ...prev, status: "pending" }));
        void fetchAllFiles({
            ...filterState,
            pageSize: pageSize,
            pageIndex: pageIndex + 1,
            sortCriteria: sorting.id as FetchAllFilesPayload["sortCriteria"],
            sortDirection: sorting.desc ? "desc" : "asc",
        })
            .then((data) => setState({ data: data.list, count: data.count, status: "fulfilled" }))
            .catch((error) => {
                const err = error as Error;
                showErrorToast(err.message);
                setState((prev) => ({ ...prev, status: "rejected" }));
            });
        // eslint want us to add just "table" to dependencies array, sorting doesn't work when we add it
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState, pageIndex, pageSize, table.getState().sorting]);

    if (state.status === "uninitialized") {
        return <LoadingBox />;
    }

    return (
        <PageContainer>
            <Form<FormValues>
                onSubmit={onSubmit}
                render={({ handleSubmit, form, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Card className={classes.card}>
                            <CardHeader
                                title={
                                    <Box className={classes.cardTitleBox}>
                                        <Typography variant="h6">Filters</Typography>
                                        {!_.isEmpty(filterState) ? (
                                            <Typography variant="caption" color="error">
                                                * You have active filters
                                            </Typography>
                                        ) : null}
                                    </Box>
                                }
                            />

                            <CardContent className={classes.cardContent}>
                                <TextField name="fileName" label="File name" fullWidth />
                                <TextField name="workspaceId" label="Workspace ID" fullWidth />
                                <PrimaryButton type="submit" disabled={_.isEmpty(values)}>
                                    Apply
                                </PrimaryButton>
                                <SecondaryButton disabled={_.isEmpty(filterState)} onClick={() => onClear(form)}>
                                    Clear
                                </SecondaryButton>
                            </CardContent>
                        </Card>
                    </form>
                )}
            />

            <TableContainer component={Paper}>
                <MinimalTable>
                    <MinimalTableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Header key={header.id} {...header} />
                                ))}
                                <MinimalTableHeaderCell width={80} align="right">
                                    Actions
                                </MinimalTableHeaderCell>
                            </TableRow>
                        ))}
                    </MinimalTableHeader>
                    <TableBody>
                        {!table.getRowModel().rows.length ? (
                            <TableRow>
                                <MinimalTableCell colSpan={tableColumns.length + 1} className={classes.noResultsRow}>
                                    No downloads available yet
                                </MinimalTableCell>
                            </TableRow>
                        ) : (
                            table
                                .getRowModel()
                                .rows.map((row) => <TableItem key={row.id} setState={setState} row={row} />)
                        )}
                    </TableBody>
                </MinimalTable>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 20, 50]}
                count={state.count}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(_event, page) => table.setPageIndex(page)}
                onRowsPerPageChange={(e) => {
                    const size = e.target.value ? Number(e.target.value) : 10;
                    table.setPagination({ pageIndex: 0, pageSize: size });
                }}
            />
        </PageContainer>
    );
};
export default withRequiredRole(AdminFilesPage, [EmteriaRoles.EmteriaAdminRole]);
