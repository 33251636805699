import fileSize from "filesize";
import { capitalize } from "lodash";
import React from "react";

import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import { ReleaseFile } from "@dashboard/downloads/types/index";
import { Paper, TableBody, TableContainer, TablePagination, TableRow } from "@mui/material";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import { Header } from "@shared/table/TableHeader";
import { fuzzyFilter } from "@shared/table/Tanstack.utils";
import { Tooltip } from "@shared/Tooltip";
import {
    ColumnDef,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import TableItem from "../TableItem";
import { useStyles } from "./DownloadsTable.style";
import { FilterWrapper } from "./Filter/Filters";

const tableColumns: ColumnDef<ReleaseFile, string>[] = [
    {
        header: "Date",
        accessorKey: "created",
        accessorFn: (row) => row.created,
        cell: (info) => (
            <MinimalTableCell title="">
                <Tooltip title={convertToLocalDate(info.getValue(), "ISO", "dateTime")}>
                    <div>{convertToLocalDate(info.getValue(), "ISO", "date")}</div>
                </Tooltip>
            </MinimalTableCell>
        ),
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
            const timeA = new Date(rowA.original.created).getTime();
            const timeB = new Date(rowB.original.created).getTime();
            return timeA - timeB;
        },
    },
    {
        header: "Product",
        accessorKey: "productName",
        cell: (info) => <MinimalTableCell maxWidth={300}>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
        meta: {
            filterVariant: "releasesProduct",
        },
    },
    {
        header: "Codename",
        accessorKey: "codename",
        cell: (info) => <MinimalTableCell maxWidth={300}>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
        meta: {
            filterVariant: "releasesCodename",
        },
    },
    {
        header: "Version",
        accessorKey: "version",
        cell: (info) => <MinimalTableCell maxWidth={100}>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
        sortingFn: "alphanumeric",
    },
    {
        header: "Size",
        accessorKey: "size",
        accessorFn: (row) => fileSize(row.size || 0) ?? "-",
        cell: (info) => <MinimalTableCell maxWidth={100}>{info.getValue()}</MinimalTableCell>,
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
            const sizeA = rowA.original.size;
            const sizeB = rowB.original.size;
            return sizeA - sizeB;
        },
    },
    {
        header: "Channel",
        accessorKey: "channel",
        accessorFn: (row) => capitalize(row.channel ?? "none"),
        cell: (info) => <MinimalTableCell maxWidth={100}>{info.getValue()}</MinimalTableCell>,
        meta: {
            filterVariant: "channel",
        },
    },
    {
        header: "Visibility",
        accessorKey: "visibility",
        accessorFn: (row) => (row.isPublic ? "Public" : "Private"),
        cell: (info) => <MinimalTableCell maxWidth={100}>{info.getValue()}</MinimalTableCell>,
    },
];

type Props = {
    allFiles: ReleaseFile[];
    filteredFiles: ReleaseFile[];
};

const DownloadsTable = (props: Props) => {
    const classes = useStyles();
    const table = useReactTable<ReleaseFile>({
        data: props.filteredFiles,
        columns: tableColumns,
        filterFns: { fuzzyFilter },
        initialState: {
            sorting: [{ id: "created", desc: true }],
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const tableHeaderLength = tableColumns.length + 1;
    const { pageSize, pageIndex } = table.getState().pagination;

    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <MinimalTable>
                    <MinimalTableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Header key={header.id} {...header}>
                                        <FilterWrapper allFiles={props.allFiles} column={header.column} />
                                    </Header>
                                ))}
                                <MinimalTableHeaderCell align="right">Actions</MinimalTableHeaderCell>
                            </TableRow>
                        ))}
                    </MinimalTableHeader>
                    <TableBody>
                        {!table.getRowModel().rows.length ? (
                            <TableRow>
                                <MinimalTableCell colSpan={tableHeaderLength} className={classes.noResultsRow}>
                                    No downloads available yet
                                </MinimalTableCell>
                            </TableRow>
                        ) : (
                            table
                                .getRowModel()
                                .rows.map((row, index) => <TableItem key={row.id} row={row} index={index} />)
                        )}
                    </TableBody>
                </MinimalTable>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 25, 50]}
                count={table.getFilteredRowModel().rows.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(_event, page) => table.setPageIndex(page)}
                onRowsPerPageChange={(e) => {
                    const size = e.target.value ? Number(e.target.value) : 10;
                    table.setPageSize(size);
                }}
            />
        </React.Fragment>
    );
};

export default DownloadsTable;
