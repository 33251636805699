import _ from "lodash";
import React from "react";
import { useField, useForm } from "react-final-form";
import { shallowEqual, useSelector } from "react-redux";
import { androidVersionOptions, products } from "src/data/products";
import { Tooltip } from "src/ui/shared/Tooltip";

import { selectFilesWithZipFilter } from "@dashboard/files/store/selectors";
import { BuildVariantLabel } from "@dashboard/products/components/Product/Builds/shared";
import { Product, ProductBootAnimation } from "@dashboard/products/types";
import { selectProvisionings } from "@dashboard/provisioning/store/selectors";
import { Autocomplete, Typography } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import { SelectField, UncontrolledSelectField } from "@shared/form/SelectField";
import { TextField, UncontrolledTextField } from "@shared/form/TextField";

import { InputItemContainer, InputItemLabel, useStyles as useCommonStyles } from "../styles";

const generateVersionOptions = (value: string | undefined) => {
    const versionNames = Object.keys(androidVersionOptions);
    const versions =
        value && versionNames.includes(value) ? androidVersionOptions[value] : ["15", "14", "13", "12", "11"];
    return versions.map((version) => ({
        label: version,
        value: version,
    }));
};
const normalizeVersionValue = (value: string) => {
    const product = products.find((item) => item.rootDevice === value);
    const label = product ? product.deviceName : value;
    return { value, label };
};

export const buildVariantOptions = [
    { label: "User ", value: "user" },
    { label: "Debug", value: "userdebug" },
    { label: "Engineering ", value: "eng" },
];
type Option = { label: string; value: string };
export const defaultBuildVariantOption = "user";

export const Recipes = () => {
    const form = useForm<Product>();
    const formErrors = form.getState().errors || {};
    const { input: provisioningIdInput } = useField<number | null>("customBuildConfig.provisioningId");
    const { input: bootAnimationInput } = useField<ProductBootAnimation | undefined>("customBuildConfig.bootanimation");
    const { input: rootDeviceInput } = useField<string | undefined>("customBuildConfig.rootDevice");
    const { input: compatibilityDeviceInput } = useField<string | undefined>("customBuildConfig.compatibility");

    const [versionOptions, setVersionOptions] = React.useState<Option[]>(generateVersionOptions(rootDeviceInput.value));

    const commonStyles = useCommonStyles();
    const workspaceZipFiles = useSelector(selectFilesWithZipFilter, shallowEqual);
    const provisionings = useSelector(selectProvisionings);

    const platformOptions = _(products)
        .map(({ deviceName, rootDevice }) => ({ label: deviceName, value: rootDevice }))
        .uniqBy("label")
        .sortBy("label")
        .value();

    const bootAnimationFiles = workspaceZipFiles.map((item) => ({
        label: item.filename,
        value: item.id,
    }));
    const provisioningFiles = provisionings.map((item) => ({
        label: item.name,
        value: item.id,
    }));

    const bootAnimationOptions = bootAnimationFiles.length
        ? bootAnimationFiles
        : [{ label: "Select boot animation file", value: undefined }];
    const provisioningOptions = provisioningFiles.length
        ? provisioningFiles
        : [{ label: "Select provisioning file", value: undefined }];

    const onProvisioningIdChange: SelectInputProps["onChange"] = (e) => {
        if (!e.target.value) {
            provisioningIdInput.onChange(null);
        }
        if (e.target.value) {
            provisioningIdInput.onChange(e.target.value);
        }
    };
    const onBootAnimationChange: SelectInputProps["onChange"] = (e) => {
        if (!e.target.value) {
            bootAnimationInput.onChange(undefined);
        }
        if (e.target.value) {
            bootAnimationInput.onChange({
                source: {
                    type: "storage",
                    fileId: e.target.value,
                },
            });
        }
    };
    const onVersionChange: SelectInputProps["onChange"] = (e) => {
        if (typeof e.target.value === "string") {
            compatibilityDeviceInput.onChange(`>=${e.target.value}`);
        }
    };
    const onPlatformChange = (value: string) => {
        const options = generateVersionOptions(value);
        const newVersion = options[0].value;

        setVersionOptions(options);
        form.change("customBuildConfig.rootDevice" as keyof Product, value);
        form.change("androidVersion", newVersion);
        compatibilityDeviceInput.onChange(`>=${newVersion}`);
    };

    return (
        <>
            <Typography className={commonStyles.sectionHeader}>Config</Typography>
            <div className={commonStyles.sectionContainer}>
                <InputItemContainer>
                    <InputItemLabel>Hardware platform</InputItemLabel>
                    <Autocomplete
                        freeSolo
                        disableClearable
                        style={{ flex: 0.7 }}
                        options={platformOptions}
                        getOptionLabel={(option) => {
                            if (typeof option === "object") {
                                return option.label;
                            }
                            return option;
                        }}
                        value={normalizeVersionValue(rootDeviceInput.value || "")}
                        onChange={(_e, option) => {
                            if (typeof option === "object" && option.value) {
                                onPlatformChange(option.value);
                            }
                        }}
                        renderInput={(params) => (
                            <UncontrolledTextField
                                {...params}
                                error={formErrors["customBuildConfig.rootDevice"]}
                                helperText={formErrors["customBuildConfig.rootDevice"]}
                                onChange={(e) => onPlatformChange(e.target.value)}
                                InputProps={{ ...params.InputProps, type: "search" }}
                            />
                        )}
                    />
                </InputItemContainer>
                <InputItemContainer>
                    <InputItemLabel>Android version</InputItemLabel>
                    <SelectField
                        name="androidVersion"
                        className={commonStyles.input}
                        options={versionOptions}
                        onChange={onVersionChange}
                        required
                    />
                </InputItemContainer>
                <InputItemContainer>
                    <InputItemLabel>Compatibility </InputItemLabel>
                    <UncontrolledTextField
                        value={compatibilityDeviceInput.value}
                        onChange={compatibilityDeviceInput.onChange}
                        className={commonStyles.input}
                        error={formErrors["customBuildConfig.compatibility"]}
                        helperText={formErrors["customBuildConfig.compatibility"]}
                        required
                    />
                </InputItemContainer>
                <InputItemContainer>
                    <InputItemLabel>Nightly period</InputItemLabel>
                    <TextField
                        className={commonStyles.input}
                        name="nightlyPeriod"
                        type="number"
                        placeholder="e.g. 7 (nightly build triggered once a week)"
                        required
                    />
                </InputItemContainer>

                <Tooltip
                    title={!bootAnimationFiles.length ? "No boot animation files available" : ""}
                    disableHoverListener={!!bootAnimationFiles.length}
                    childrenBoxStyles={{ display: "block" }}
                    className={commonStyles.tooltipInputContainer}
                >
                    <InputItemContainer style={{ paddingTop: 0 }}>
                        <InputItemLabel>Boot animation</InputItemLabel>
                        <UncontrolledSelectField
                            variant="outlined"
                            value={bootAnimationInput.value?.source?.fileId}
                            placeholder="Select boot animation file (e.g., intro_animation.zip)"
                            onChange={onBootAnimationChange}
                            className={commonStyles.input}
                            options={bootAnimationOptions}
                            disabled={!bootAnimationFiles.length}
                        />
                    </InputItemContainer>
                </Tooltip>
                <Tooltip
                    title={!provisioningOptions.length ? "No provisioning files available" : ""}
                    disableHoverListener={!!provisioningOptions.length}
                    childrenBoxStyles={{ display: "block" }}
                    className={commonStyles.tooltipInputContainer}
                >
                    <InputItemContainer style={{ paddingTop: 0 }}>
                        <InputItemLabel>Provisioning</InputItemLabel>
                        <UncontrolledSelectField
                            variant="outlined"
                            value={provisioningIdInput.value}
                            onChange={onProvisioningIdChange}
                            className={commonStyles.input}
                            placeholder="Select provisioning file"
                            options={provisioningOptions}
                            disabled={!provisioningOptions.length}
                        />
                    </InputItemContainer>
                </Tooltip>
                <InputItemContainer>
                    <BuildVariantLabel />
                    <SelectField
                        fullWidth
                        className={commonStyles.input}
                        name="customBuildConfig.buildVariant"
                        options={buildVariantOptions}
                        defaultValue={defaultBuildVariantOption}
                    />
                </InputItemContainer>
            </div>
        </>
    );
};
