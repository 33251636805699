import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    buttonBox: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 5,
    },
    tagsBox: {
        overflowY: "scroll",
        overflowX: "hidden",
        maxWidth: 400,
        maxHeight: 150,
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
    },
    link: {
        textDecoration: "underline",
    },
    isUploading: {
        backgroundColor: theme.palette.shadow[200],
    },
    tableRow: {
        borderBottom: `1px solid ${theme.palette.black[50]}`,
    },
    visibilitySelect: {
        width: 250,
        backgroundColor: "inherit",
        marginTop: 10,
        marginBottom: 10,

        "& .MuiSelect-select": {
            fontSize: "16px",
            padding: "6px 14px",
        },
    },
}));

export const getLastTableRowStyle = (index: number, length: number) => {
    return index === length - 1 ? { paddingBottom: 10 } : {};
};
