import { encode as base64encode } from "base64-arraybuffer";
import jwtDecode, { JwtPayload as _JwtPayload } from "jwt-decode";
import { DateTime } from "luxon";
import queryString from "query-string";
import RouteService from "src/services/routeService";
import { persistentStore } from "src/store/persistentStore";

import roles, { AccessRole, EmteriaRoles } from "../data/roles";
import { getAuthClient, getReduxVersion } from "./configService";
import httpService from "./httpService";
import { LocalStorage } from "./localStorageService";

const { clientId, clientSecret } = getAuthClient();

// s. https://www.valentinog.com/blog/challenge/
function generateCodeVerifier(): string {
    const array = new Uint32Array(28);
    window.crypto.getRandomValues(array);
    return Array.from(array, (item) => `0${item.toString(16)}`.substr(-2)).join("");
}

async function generateCodeChallenge(codeVerifier: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest("SHA-256", data);
    const base64Digest = base64encode(digest);
    return base64Digest.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

const authHelpers = {
    generateCodeVerifier,
    generateCodeChallenge,
};

type SessionToken = {
    token_type: string;
    scope: string;
    access_token: string;
    refresh_token: string;
    expires_in: number;
    expires_at?: string | null;
    refresh_token_expires_in: number;
    refresh_token_expires_at?: string | null;
};

type JwtPayload = _JwtPayload & {
    email: string;
    sub: string;
    role: string | string[];
    tft?: string;
};

const clearSession = (): void => {
    LocalStorage.removeItem("token");
    LocalStorage.removeItem("totp");
};

const getSession = (): SessionToken | null => {
    try {
        const sessionToken = LocalStorage.getItem<SessionToken>("token");

        const currentReduxVersion = getReduxVersion();

        if (LocalStorage.getItem("reduxVersion") !== currentReduxVersion) {
            LocalStorage.setItem("reduxVersion", currentReduxVersion);

            persistentStore.set({
                devices: {
                    filters: {
                        isOpen: false,
                        isActive: true,
                        properties: {
                            ["license_activationStatus"]: [],
                            ["device_status"]: [],
                        },
                    },
                },
            });
        }

        // TODO:
        // Let's make sure the returned session is always valid.
        // For this, we could refresh the session 1 min before its actual expiration to avoid "Unauthorized" responses completely.
        /*
        if (!sessionToken || !sessionToken.expires_at) {
            console.error("Failed session validation due to missing 'expires_at' value");
            return null;
        }

        const tokenExpirationDateMs = DateTime.fromISO(sessionToken.expires_at).diffNow().toSeconds();
        if (tokenExpirationDateMs <= 60) {
            sessionToken = refreshSession(sessionToken.refresh_token);
        }
        */

        return sessionToken;
    } catch (ex) {
        clearSession();
        return null;
    }
};

const saveSession = (token: SessionToken): void => {
    if (!token) {
        return;
    }

    if (token.expires_in && !token.expires_at) {
        token.expires_at = DateTime.now()
            .plus({ seconds: Number(token.expires_in) })
            .toISO();
    }

    if (token.refresh_token_expires_in && !token.refresh_token_expires_at) {
        token.refresh_token_expires_at = DateTime.now()
            .plus({ seconds: Number(token.refresh_token_expires_in) })
            .toISO();
    }

    LocalStorage.setItem("token", token);
};

type RequestJwtParams = {
    body: Record<string, string>;
};

const requestJwt = async ({ body }: RequestJwtParams) => {
    const endpoint = await RouteService.getTokensRoute();
    const configData = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    };

    try {
        const { data: token }: { data: SessionToken } = await httpService.post(
            endpoint,
            queryString.stringify(body),
            configData,
        );
        return token;
    } catch (ex) {
        return Promise.reject(ex);
    }
};

export enum AuthGrantType {
    password = "password",
    refresh_token = "refresh_token",
    client_credentials = "client_credentials",
    authorization_code = "authorization_code",
}

const requestRefreshJwt = (refreshToken: string) => {
    const bodyData = {
        grant_type: AuthGrantType.refresh_token,
        refresh_token: refreshToken,
    };

    const token = requestJwt({ body: bodyData });
    return token;
};

// The JWT token is used to authorize calls to API methods.
// It is also used - for now - to login into the MVC code to get required cookies.
const initSessionViaPass = async (email: string, password: string): Promise<SessionToken> => {
    const bodyData = {
        grant_type: AuthGrantType.password,
        username: email,
        password: password,
    };

    const token = await requestJwt({ body: bodyData });

    saveSession(token);
    return token;
};

interface BuildAuthCodeEndpoint {
    codeChallenge: string;
}

export const buildAuthCodeEndpoint = async ({ codeChallenge }: BuildAuthCodeEndpoint) => {
    const endpoint = await RouteService.getTokensRoute();
    return (
        endpoint +
        `authorization?
        response_type=code&
        client_id=${clientId}&
        code_challenge=${codeChallenge}&
        code_challenge_method=S256&
        redirect_uri=${window.location.origin}/account/confirm`.replace(/\s*\n*/g, "")
    );
};

interface InitSessionViaAuthCode {
    codeVerifier: string;
    code: string;
}

const initSessionViaAuthCode = async ({ code, codeVerifier }: InitSessionViaAuthCode): Promise<SessionToken> => {
    const bodyData = {
        grant_type: AuthGrantType.authorization_code,
        client_id: clientId,
        client_secret: clientSecret,
        code: code,
        code_verifier: codeVerifier,
        redirect_uri: `${window.location.origin}${window.location.pathname}`,
    };

    const token = await requestJwt({ body: bodyData });

    saveSession(token);
    return token;
};

const refreshSession = async (refreshToken = getSession()?.refresh_token): Promise<SessionToken> => {
    if (!refreshToken) {
        return Promise.reject();
    }
    const token = await requestRefreshJwt(refreshToken);
    saveSession(token);
    return token;
};

// Login to get the cookie to access MVC controllers
// Will be removed when the application is completely migrated to React
const loadCookie = async (email: string, password: string, tft: string, totp: string): Promise<void> => {
    const configData = {
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
    };

    const bodyData = {
        Email: email,
        Password: password,
        TotpToken: tft,
        TotpCode: totp,
    };
    const endpoint = await RouteService.getAccountsRoute();
    await httpService.post(endpoint + "login", bodyData, configData);
};

const unloadCookie = async () => {
    const endpoint = await RouteService.getAccountsRoute();
    await httpService.get(endpoint + "logout", { withCredentials: true });
};

const logout = async (): Promise<void> => {
    clearSession();
    await unloadCookie();
};

const getCurrentUser = (): JwtPayload | null => {
    try {
        const token = getSession();
        if (!token) {
            return null;
        }

        const decodedJwt = jwtDecode<JwtPayload>(token.access_token);
        return decodedJwt;
    } catch (ex) {
        return null;
    }
};

const hasUserTotp = (): boolean | null => {
    const user = getCurrentUser();
    if (!user) {
        return false;
    }
    if (!user.tft) {
        return false;
    }
    return true;
};

const setIsUserTotpValid = (isValid: boolean): void => {
    LocalStorage.setItem("totp", isValid);
};
const isUserTotpValid = (): boolean => {
    return Boolean(LocalStorage.getItem<boolean>("totp"));
};

const isUserInRole = (neededRoles: AccessRole): boolean => {
    const user = getCurrentUser();
    if (!user || !user.role) {
        return false;
    }

    if (!Array.isArray(user.role)) {
        user.role = [user.role];
    }
    return user.role.some((role) => neededRoles.includes(role as EmteriaRoles));
};

const isUserInAnyAccessRole = (): boolean => {
    return isUserInRole(roles.accessRoles.AdminAllAccessRoles);
};

const isUserAdmin = (): boolean => {
    return isUserInRole([roles.emteriaRoles.EmteriaAdminRole]);
};

const isUserDeveloper = (): boolean => {
    return isUserInRole(roles.accessRoles.AdminDeveloperAccessRoles);
};

const isUserMerchant = (): boolean => {
    return isUserInRole(roles.accessRoles.AdminSalesAccessRoles);
};

const isUserAccountant = (): boolean => {
    return isUserInRole(roles.accessRoles.AdminAccountantAccessRoles);
};

const isUserSupporter = (): boolean => {
    return isUserInRole(roles.accessRoles.AdminSupportAccessRoles);
};

const authService = {
    initSessionViaPass,
    initSessionViaAuthCode,
    saveSession,
    refreshSession,
    getSession,
    loadCookie,
    logout,
    getCurrentUser,
    hasUserTotp,
    isUserTotpValid,
    setIsUserTotpValid,
    isUserInRole,
    isUserSupporter,
    isUserMerchant,
    isUserAccountant,
    isUserDeveloper,
    isUserAdmin,
    isUserInAnyAccessRole,
    helpers: authHelpers,
};

export default authService;
