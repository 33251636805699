const isValidUrl = (str: string): boolean => {
    try {
        new URL(str);
        return true;
    } catch (error) {
        return false;
    }
};

export const isValidZipUrl = (str: string): boolean => {
    return isValidUrl(str) && str.endsWith(".zip");
};

export const isValidApkUrl = (str: string): boolean => {
    return isValidUrl(str) && str.endsWith(".apk");
};

export const isValidSSHKey = (str: string): boolean => {
    const keyRegex = /^ssh-rsa [0-9A-Za-z+/]{128,}[=]{0,3}.*/;
    return keyRegex.test(str);
};
