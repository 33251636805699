export * from "./selectors";
import _, { flatten, isArray, mergeWith, remove } from "lodash";
import { redeemVoucher as apiRedeemVoucher } from "src/services/voucherService";
import { RootState } from "src/store";
import { persistentStore } from "src/store/persistentStore";
import { PartialDeep } from "type-fest";

import {
    cancelSubscription as apiCancelSubscription,
    claimStarterSubscription as apiClaimStarterSubscription,
    createDeviceMdmCommand as apiCreateDeviceMdmCommand,
    createGroup as apiCreateGroup,
    createGroupMdmCommand as apiCreateGroupMdmCommand,
    deleteDeviceMdmCommand as apiDeleteDeviceMdmCommand,
    deleteGroup as apiDeleteGroup,
    deleteGroupMdmCommand as apiDeleteGroupMdmCommand,
    fetchDeviceMdmCommands as apiFetchDeviceMdmCommands,
    fetchGroupMdmCommands as apiFetchGroupMdmCommands,
    fetchGroups as apiFetchGroups,
    fetchLicenses as apiFetchLicenses,
    generateLicenses as apiGenerateLicenses,
    revokeLicense as apiRevokeLicense,
    updateDevice as apiUpdateDevice,
    updateGroup as apiUpdateGroup,
    UpdateGroupPayload,
    updateLicense as apiUpdateLicense,
    updatePolicy as apiUpdatePolicy,
    updateSubscription as apiSubscriptionsUpdate,
} from "@dashboard/devices/api";
import { DevicesFilterTypes } from "@dashboard/devices/components/Filtering/Filters.utils";
import {
    devicesAdapter,
    getDevicesStoreFromUrl,
    groupsAdapter,
    licensesAdapter,
    subscriptionsAdapter,
} from "@dashboard/devices/store/helpers";
import { Device, Group, License, Subscription } from "@dashboard/devices/types";
import { getDefaultSubscription } from "@dashboard/devices/utils/subscription";
import { ReleasesFilterTypes } from "@dashboard/downloads/components/DownloadsTable/Filter/Filter.utils";
import {
    createFdroidRepo as apiCreateFdroid,
    deleteFdroidRepo as apiDeleteFdroid,
    deleteUniversalLicenseGroup,
    fetchFdroidRepos as apiFetchFdroids,
    updateUniversalLicenseGroup as apiUpdateUniversalLicenseGroup,
} from "@dashboard/provisioning/api";
import { setCurrentWorkspaceId } from "@dashboard/workspaces/store";
import workspacesAdapter from "@dashboard/workspaces/store/selectors";
import { createAsyncThunk, createSlice, EntityState } from "@reduxjs/toolkit";

import { normalizeLicenses, normalizeSubscriptions } from "./helpers";
import { BatchActionState, defaultFilter, DetailsState, FilterState, ViewState } from "./selectors";

const updateSubscription = createAsyncThunk("subscriptions/update", apiSubscriptionsUpdate);
export const cancelSubscription = createAsyncThunk("subscriptions/cancel", apiCancelSubscription);
export const claimStarterSubscription = createAsyncThunk("subscriptionStarter/claim", apiClaimStarterSubscription);

export const fetchFdroidRepos = createAsyncThunk("fdroids/fetch", apiFetchFdroids);
export const createFdroidRepo = createAsyncThunk("fdroids/create", apiCreateFdroid);
export const deleteFdroidRepo = createAsyncThunk("fdroids/delete", apiDeleteFdroid);

export const fetchGroups = createAsyncThunk("groups/fetch", apiFetchGroups);
export const createGroup = createAsyncThunk("groups/create", apiCreateGroup);
export const deleteGroup = createAsyncThunk("groups/delete", apiDeleteGroup);
export const updateGroup = createAsyncThunk<[Group] | [], UpdateGroupPayload>(
    "groups/update",
    async (arg, { getState, dispatch }) => {
        const state = getState() as RootState;
        const response = await apiUpdateGroup(arg);
        const updatedGroup = response[0];

        if (updatedGroup && (arg.removedMembers || arg.changedMembers)) {
            const workspaces = workspacesAdapter.getSelectors().selectAll(state.workspaces.items);
            const workspace = workspaces.find((item) => item.subscription === updatedGroup?.subscriptionId);

            const groups = groupsAdapter.getSelectors().selectAll(state.devices.list.groups.items);
            const filteredGroups = groups.filter((item) => item.id !== updatedGroup?.id);
            const workspaceGroups = filteredGroups.filter((item) => item?.subscriptionId === workspace?.subscription);
            const groupsMembers = workspaceGroups.map((item) => item?.members).flat();

            const allMembers = workspace?.members
                ? _.uniqBy([...workspace.members, ...groupsMembers], "email")
                : _.uniqBy(groupsMembers, "email");

            const userEmail = state.account.profile?.email;
            const isWorkspaceMember = allMembers.find((item) => item.email === userEmail);

            if (!isWorkspaceMember) {
                const subscriptions = subscriptionsAdapter
                    .getSelectors()
                    .selectAll(state.devices.list.subscriptions.items);
                const defaultSubscription = getDefaultSubscription(subscriptions);

                if (defaultSubscription) {
                    const defaultWorkspace = workspaces.find((item) => item.subscription === defaultSubscription.id);

                    if (defaultWorkspace) {
                        dispatch(setCurrentWorkspaceId(defaultWorkspace.id));
                    }
                }
            }
        }

        return response;
    },
);

export const updatePolicy = createAsyncThunk("policy/update", apiUpdatePolicy);

export const fetchLicenses = createAsyncThunk("licenses/fetch", apiFetchLicenses);
export const updateLicense = createAsyncThunk("licenses/update", apiUpdateLicense);
export const revokeLicense = createAsyncThunk("licenses/revoke", apiRevokeLicense);
export const generateLicenses = createAsyncThunk("licenses/generate", apiGenerateLicenses);

const redeemVoucher = createAsyncThunk("voucher/fetch", apiRedeemVoucher);

export const updateUniversalLicense = createAsyncThunk(
    "provisioning/updateUniversalLicense",
    apiUpdateUniversalLicenseGroup,
);
export const deleteUniversalLicense = createAsyncThunk(
    "provisioning/DeleteUniversalLicense",
    deleteUniversalLicenseGroup,
);

export const updateDevice = createAsyncThunk("devices/update", apiUpdateDevice);
export const fetchDeviceMdmCommands = createAsyncThunk("devices/fetchDeviceMdmCommands", apiFetchDeviceMdmCommands);
export const refetchDeviceMdmCommands = createAsyncThunk("devices/refetchDeviceMdmCommands", apiFetchDeviceMdmCommands);
export const createDeviceMdmCommand = createAsyncThunk("devices/createDeviceMdmCommand", apiCreateDeviceMdmCommand);
export const deleteDeviceMdmCommand = createAsyncThunk("devices/deleteDeviceMdmCommand", apiDeleteDeviceMdmCommand);
export const fetchGroupMdmCommands = createAsyncThunk("groups/fetchGroupMdmCommands", apiFetchGroupMdmCommands);
export const refetchGroupMdmCommands = createAsyncThunk("groups/refetchGroupMdmCommands", apiFetchGroupMdmCommands);
export const createGroupMdmCommand = createAsyncThunk("groups/createGroupMdmCommand", apiCreateGroupMdmCommand);
export const deleteGroupMdmCommand = createAsyncThunk("groups/deleteGroupMdmCommand", apiDeleteGroupMdmCommand);

const lastStore = persistentStore.get();
const lastDevicesStore = lastStore?.devices as LocalState;
const urlDevicesStore = getDevicesStoreFromUrl();

export type ListStatus = "pending" | "fulfilled" | "rejected";
type LocalState = {
    list: {
        status: ListStatus | null;
        subscriptions: {
            items: EntityState<Subscription>;
        };
        groups: {
            status: ListStatus | null;
            items: EntityState<Group>;
        };
        licenses: {
            status: ListStatus | null;
            statusByGroupId: Record<number, ListStatus>;
            batchFetch: boolean;
            items: EntityState<License>;
        };
        devices: {
            items: EntityState<Device>;
        };
    };
    details: DetailsState;
    filters: FilterState;
    batchAction: BatchActionState;
    pane: {
        tab: string;
        status: ListStatus | null;
        editEnabled: boolean;
    };
    search: {
        query: string;
    };
    view: ViewState;
};

export const localState = (state: RootState): LocalState => state?.devices;

const initialState: LocalState = {
    list: {
        status: null,
        subscriptions: {
            items: subscriptionsAdapter.getInitialState(),
        },
        groups: { status: null, items: groupsAdapter.getInitialState() },
        licenses: {
            status: null,
            statusByGroupId: {},
            batchFetch: true,
            items: licensesAdapter.getInitialState(),
        },
        devices: {
            items: devicesAdapter.getInitialState(),
        },
    },
    details: {
        isOpen: false,
        type: null,
        selectedId: null,
    },
    filters: _.cloneDeep(defaultFilter),
    search: {
        query: "",
    },
    pane: {
        tab: "SUMMARY",
        editEnabled: false,
        status: null,
    },
    batchAction: {
        isActive: false,
        // action type
        type: null,
        // entity type for a batch action
        entity: null,
        // entity-ids selected for a batch action
        selection: [],
        targetId: null,
    },
    view: {
        groups: {
            collapse: [],
        },
        licenses: {
            pageSize: 10,
        },
    },
};

const initialWithLastPersistedState: LocalState = mergeWith(
    initialState,
    lastDevicesStore,
    urlDevicesStore,
    {
        // override anything from "initial-state" & "last-persisted-state" here
    },
    (obj: PartialDeep<LocalState>, src: PartialDeep<LocalState>) => {
        if (isArray(obj)) {
            return src;
        }
    },
);

const resetDetails = (state: LocalState) => {
    state.details = initialState.details;
};

const resetFilters = (state: LocalState) => {
    state.filters = {
        isActive: defaultFilter.isActive,
        isOpen: true,
        properties: defaultFilter.properties,
    };
};

const resetBatchAction = (state: LocalState) => {
    state.batchAction = initialState.batchAction;
};

const subscriptionsSlice = createSlice({
    name: "subscription",
    initialState: initialWithLastPersistedState,
    reducers: {
        showDetails(state, { payload }: { payload: { type: DetailsState["type"]; selectedId: number } }) {
            const { type, selectedId } = payload;
            state.details.isOpen = true;
            state.details.type = type;
            state.details.selectedId = selectedId;
        },
        showLicenseById(state, { payload }: { payload: { device: Device; groupId: number } }) {
            state.search.query = payload.device.serial || "";
            state.filters = {
                isOpen: false,
                isActive: defaultFilter.isActive,
                properties: defaultFilter.properties,
            };
            state.view.groups.collapse = state.list.groups.items.ids as number[];
            state.view.groups.collapse = [
                ...new Set([...state.view.groups.collapse.filter((gId) => ![payload.groupId].includes(gId))]),
            ];
            state.details.isOpen = true;
            state.details.type = "license";
            state.details.selectedId = payload.device.licenseId;
        },
        closeDetails(state) {
            state.details.isOpen = false;
            state.details.type = null;
            state.details.selectedId = null;
            state.pane.editEnabled = false;
        },
        toggleFilters(state, { payload }: { payload: FilterState["isOpen"] }) {
            state.filters.isOpen = payload;
        },
        resetFiltersToDefault(state) {
            resetFilters(state);
        },
        updateFilterProperty(
            state,
            { payload }: { payload: { property: DevicesFilterTypes | ReleasesFilterTypes; values: string[] } },
        ) {
            const { property, values } = payload;

            state.filters.properties[property] = values;

            const hasActiveFilters = Object.values(state.filters.properties).some((filter) => filter.length);
            state.filters.isActive = hasActiveFilters;
        },
        updateFilterProperties(state, action: { payload: Partial<FilterState["properties"]> }) {
            state.filters = {
                isActive: true,
                isOpen: true,
                properties: { ...defaultFilter.properties, ...action.payload },
            };
        },
        updateSearchQuery(state, { payload }: { payload: string }) {
            state.search.query = payload ?? "";
        },
        setBatchMode(
            state,
            {
                payload,
            }: {
                payload: {
                    type: BatchActionState["type"];
                    entity: BatchActionState["entity"];
                };
            },
        ) {
            const { type, entity } = payload;
            // ignore if same mode active
            if (entity === state.batchAction.entity) {
                return;
            }

            state.batchAction = {
                ...state.batchAction,
                isActive: true,
                type,
                entity,
                selection: [],
                targetId: null,
            };
            resetDetails(state);
        },
        closeBatchMode(state) {
            resetBatchAction(state);
        },
        addBatchSelection(state, { payload }: { payload: number }) {
            const entityId = payload;
            state.batchAction.selection.push(entityId);
        },
        removeBatchSelection(state, { payload }: { payload: number }) {
            const entityId = payload;
            remove(state.batchAction.selection, (selectionId) => selectionId === entityId);
        },
        setBatchTargetId(state, { payload }: { payload: BatchActionState["targetId"] }) {
            state.batchAction.targetId = payload;
        },
        setViewGroupsCollapseById(state, { payload }: { payload: number | number[] }) {
            const groupIds = payload;
            const currentGroups = state.view.groups.collapse;
            const addedGroups = Array.isArray(groupIds) ? groupIds : [groupIds];
            state.view.groups.collapse = [...new Set([...currentGroups, ...addedGroups])];
        },
        setViewGroupsExpandById(state, { payload }: { payload: number | number[] }) {
            const groupIds = payload;
            const currentGroups = state.view.groups.collapse;
            const removedGroups = Array.isArray(groupIds) ? groupIds : [groupIds];
            state.view.groups.collapse = [...new Set([...currentGroups.filter((gId) => !removedGroups.includes(gId))])];
        },
        setViewGroupsCollapseAll(state, { payload }: { payload: boolean }) {
            if (payload === true) {
                state.view.groups.collapse = state.list.groups.items.ids as number[];
            } else {
                state.view.groups.collapse = [];
            }
        },
        setViewPageSize(state, { payload }: { payload: number }) {
            state.view.licenses.pageSize = payload;
        },
        removeGroupLocally(state, { payload }: { payload: number }) {
            const subscription = subscriptionsAdapter
                .getSelectors()
                .selectAll(state.list.subscriptions.items)
                .find((item) => item.groupIds.includes(payload));

            if (subscription) {
                groupsAdapter.removeOne(state.list.groups.items, payload);
                subscriptionsAdapter.updateOne(state.list.subscriptions.items, {
                    id: subscription.id,
                    changes: {
                        groupIds: subscription.groupIds.filter((item) => item !== payload),
                    },
                });
            }
        },
        setPaneTab(state, { payload }: { payload: string }) {
            state.pane.tab = payload;
        },
        setPaneEditEnabled(state, { payload }: { payload: boolean }) {
            state.pane.editEnabled = payload;
        },
        addSubscriptions(state, { payload }: { payload: Record<number, Subscription> }) {
            subscriptionsAdapter.addMany(state.list.subscriptions.items, payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateSubscription.fulfilled, (state, { payload }) => {
                const subscriptions = payload;

                const subscriptionUpdates = subscriptions.map((s) => ({
                    id: s.id,
                    changes: {
                        name: s.name,
                        description: s.description,
                        groupIds: s.groupIds,
                    },
                }));
                subscriptionsAdapter.updateMany(state.list.subscriptions.items, subscriptionUpdates);

                const groupsUpdate = subscriptions.map((s) =>
                    s.groupIds.map((item) => ({
                        id: item,
                        changes: {
                            subscriptionId: s.id,
                        },
                    })),
                );
                groupsAdapter.updateMany(state.list.groups.items, flatten(groupsUpdate));

                resetBatchAction(state);
            })
            .addCase(cancelSubscription.fulfilled, (state, { payload }) => {
                const subscriptions = payload;

                const subscriptionUpdates = subscriptions.map((s) => ({
                    id: s.id,
                    changes: {
                        status: s.status,
                        isCancelled: s.isCancelled,
                        cancellationDate: s.cancellationDate,
                    },
                }));
                subscriptionsAdapter.updateMany(state.list.subscriptions.items, subscriptionUpdates);
            })
            .addCase(claimStarterSubscription.fulfilled, (state, { payload }) => {
                const subscriptions = normalizeSubscriptions([payload]);
                subscriptionsAdapter.setAll(state.list.subscriptions.items, subscriptions);
            })
            .addCase(fetchFdroidRepos.pending, (state) => {
                state.list.status = "pending";
            })
            .addCase(fetchFdroidRepos.fulfilled, (state, { meta, payload }) => {
                state.list.status = "fulfilled";
                const subscriptionId = meta.arg;

                const subscription = subscriptionsAdapter
                    .getSelectors()
                    .selectAll(state.list.subscriptions.items)
                    .find((s) => s.id === subscriptionId);

                if (subscription) {
                    subscriptionsAdapter.updateOne(state.list.subscriptions.items, {
                        id: subscriptionId,
                        changes: {
                            fdroidRepos: payload,
                        },
                    });
                }
            })
            .addCase(fetchFdroidRepos.rejected, (state) => {
                state.list.status = "rejected";
            })
            .addCase(createFdroidRepo.fulfilled, (state, { meta, payload }) => {
                const subscriptionId = meta.arg.subscriptionId;

                const subscription = subscriptionsAdapter
                    .getSelectors()
                    .selectAll(state.list.subscriptions.items)
                    .find((s) => s.id === subscriptionId);

                const repos = subscription?.fdroidRepos || [];

                subscriptionsAdapter.updateOne(state.list.subscriptions.items, {
                    id: subscriptionId,
                    changes: {
                        fdroidRepos: [...repos, payload],
                    },
                });
            })
            .addCase(deleteFdroidRepo.fulfilled, (state, { meta }) => {
                const { subscriptionId, repoId } = meta.arg;

                const subscription = subscriptionsAdapter
                    .getSelectors()
                    .selectAll(state.list.subscriptions.items)
                    .find((s) => s.id === subscriptionId);

                const repos = subscription?.fdroidRepos || [];

                subscriptionsAdapter.updateOne(state.list.subscriptions.items, {
                    id: subscriptionId,
                    changes: {
                        fdroidRepos: repos.filter((r) => r.id !== repoId),
                    },
                });
            })
            .addCase(setCurrentWorkspaceId, (state) => {
                groupsAdapter.removeAll(state.list.groups.items);
            })
            .addCase(fetchGroups.pending, (state) => {
                state.list.groups.status = "pending";
            })
            .addCase(fetchGroups.rejected, (state) => {
                state.list.groups.status = "rejected";
            })
            .addCase(fetchGroups.fulfilled, (state, { payload }) => {
                state.list.groups.status = "fulfilled";
                groupsAdapter.setAll(state.list.groups.items, payload);
            })
            .addCase(createGroup.fulfilled, (state, { payload }) => {
                resetDetails(state);

                const group = payload;
                if (!group) {
                    return;
                }

                groupsAdapter.addOne(state.list.groups.items, group);
                const subscription = subscriptionsAdapter
                    .getSelectors()
                    .selectById(state.list.subscriptions.items, group.subscriptionId);
                const subscriptionGroupIds = subscription ? subscription.groupIds : [];
                subscriptionsAdapter.updateOne(state.list.subscriptions.items, {
                    id: group.subscriptionId,
                    changes: {
                        groupIds: [...subscriptionGroupIds, group.id],
                    },
                });
            })
            .addCase(updateGroup.fulfilled, (state, { payload }) => {
                const groupsUpdate = payload.map((g) => {
                    const licenseIds = g.licenseIds;
                    return {
                        id: g.id,
                        changes: {
                            licenseIds,
                            name: g.name,
                            description: g.description,
                            members: g.members,
                        },
                    };
                });
                groupsAdapter.updateMany(state.list.groups.items, groupsUpdate);

                const licensesUpdate = payload.map((g) =>
                    g.licenseIds.map((licenseId) => ({
                        id: licenseId,
                        changes: {
                            groupId: g.id,
                            groupName: g.name,
                        },
                    })),
                );

                licensesAdapter.updateMany(state.list.licenses.items, flatten(licensesUpdate));
                resetBatchAction(state);
            })
            .addCase(fetchGroupMdmCommands.pending, (state) => {
                state.pane.status = "pending";
            })
            .addCase(fetchGroupMdmCommands.rejected, (state) => {
                state.pane.status = "rejected";
            })
            .addCase(fetchGroupMdmCommands.fulfilled, (state, { payload, meta }) => {
                state.pane.status = "fulfilled";
                const { groupId } = meta.arg;

                const group = groupsAdapter.getSelectors().selectById(state.list.groups.items, groupId);
                if (!group) {
                    return;
                }

                groupsAdapter.updateOne(state.list.groups.items, {
                    id: groupId,
                    changes: {
                        commands: payload,
                    },
                });
            })
            .addCase(refetchGroupMdmCommands.fulfilled, (state, { payload, meta }) => {
                const { groupId } = meta.arg;

                const group = groupsAdapter.getSelectors().selectById(state.list.groups.items, groupId);
                if (!group) {
                    return;
                }

                // Check if re-fetched commands have a change in status
                const updatedCommands = payload.filter((newCommand) => {
                    const command = group?.commands?.find((c) => c.id === newCommand.id);
                    return command && command.status !== newCommand.status;
                });

                // Check if there are any new commands
                const newCommands = payload.filter(
                    (newCommand) => !group?.commands?.find((c) => c.id === newCommand.id),
                );
                if (updatedCommands.length === 0 && newCommands.length === 0) {
                    return;
                }

                groupsAdapter.updateOne(state.list.groups.items, {
                    id: groupId,
                    changes: {
                        commands: payload,
                    },
                });
            })
            .addCase(createGroupMdmCommand.fulfilled, (state, { payload, meta }) => {
                const { groupId } = meta.arg;

                const group = groupsAdapter.getSelectors().selectById(state.list.groups.items, groupId);
                if (!group) {
                    return;
                }

                const commands = group.commands ?? [];
                const newCommands = payload ?? [];

                groupsAdapter.updateOne(state.list.groups.items, {
                    id: groupId,
                    changes: {
                        commands: [...commands, ...newCommands],
                    },
                });
            })
            .addCase(deleteGroupMdmCommand.fulfilled, (state, { meta }) => {
                const { groupId, command } = meta.arg;

                const group = groupsAdapter.getSelectors().selectById(state.list.groups.items, groupId);
                if (!group) {
                    return;
                }

                const commands = group.commands ?? [];

                groupsAdapter.updateOne(state.list.groups.items, {
                    id: groupId,
                    changes: {
                        commands: commands.filter((c) => c.fingerprint !== command.fingerprint),
                    },
                });
            })
            .addCase(updatePolicy.fulfilled, (state, { meta }) => {
                if (!meta || !meta.arg) {
                    return;
                }
                const { groupId, policy } = meta.arg;
                if (!groupId || !policy) {
                    return;
                }

                const group = groupsAdapter.getSelectors().selectById(state.list.groups.items, groupId);
                if (group) {
                    groupsAdapter.updateOne(state.list.groups.items, {
                        id: groupId,
                        changes: {
                            policy: JSON.parse(policy),
                        },
                    });
                }
            })
            .addCase(deleteGroup.fulfilled, (state, { meta }) => {
                if (!meta || !meta.arg) {
                    return;
                }
                const { id: groupId } = meta.arg;
                if (!groupId) {
                    return;
                }

                const group = groupsAdapter.getSelectors().selectById(state.list.groups.items, groupId);
                if (!group) {
                    return;
                }

                const subscription = subscriptionsAdapter
                    .getSelectors()
                    .selectById(state.list.subscriptions.items, group?.subscriptionId);

                if (!subscription) {
                    return;
                }
                const subscriptionGroupIds = subscription ? subscription.groupIds : [];
                subscriptionsAdapter.updateOne(state.list.subscriptions.items, {
                    id: subscription.id,
                    changes: {
                        groupIds: subscriptionGroupIds.filter((gId) => gId !== groupId),
                    },
                });
                groupsAdapter.removeOne(state.list.groups.items, groupId);
            })
            .addCase(fetchLicenses.pending, (state, { meta }) => {
                const { type, groupId } = meta.arg;
                if (type === "all" || type === "some") {
                    state.list.licenses.status = "pending";
                }
                if (type === "some") {
                    groupId.forEach((id) => {
                        state.list.licenses.statusByGroupId[id] = "pending";
                    });
                }
            })
            .addCase(fetchLicenses.fulfilled, (state, { payload, meta }) => {
                const { type, groupId } = meta.arg;
                if (type === "all") {
                    state.list.licenses.batchFetch = false;
                }
                if (type === "all" || type === "some") {
                    state.list.licenses.status = "fulfilled";
                }

                let newLicenses = {};
                let newDevices = {};

                payload.forEach((item) => {
                    const { licenses, devices } = normalizeLicenses(item.licenses.entries);
                    newLicenses = { ...newLicenses, ...licenses };
                    newDevices = { ...newDevices, ...devices };

                    if (type !== "all") {
                        groupId.forEach((id) => {
                            state.list.licenses.statusByGroupId[id] = "fulfilled";
                        });
                    }
                });

                licensesAdapter.setAll(state.list.licenses.items, newLicenses);
                devicesAdapter.setAll(state.list.devices.items, newDevices);
            })
            .addCase(fetchLicenses.rejected, (state, { meta }) => {
                const { type, groupId } = meta.arg;
                if (type === "all" || type === "some") {
                    state.list.licenses.status = "rejected";
                }
                if (type !== "all") {
                    groupId.forEach((id) => {
                        state.list.licenses.statusByGroupId[id] = "rejected";
                    });
                }
            })
            .addCase(fetchDeviceMdmCommands.pending, (state) => {
                state.pane.status = "pending";
            })
            .addCase(fetchDeviceMdmCommands.rejected, (state) => {
                state.pane.status = "rejected";
            })
            .addCase(fetchDeviceMdmCommands.fulfilled, (state, { payload, meta }) => {
                state.pane.status = "fulfilled";
                const { deviceId } = meta.arg;

                const device = devicesAdapter.getSelectors().selectById(state.list.devices.items, deviceId);
                if (!device) {
                    return;
                }

                devicesAdapter.updateOne(state.list.devices.items, {
                    id: deviceId,
                    changes: {
                        commands: payload,
                    },
                });
            })
            .addCase(refetchDeviceMdmCommands.fulfilled, (state, { payload, meta }) => {
                const { deviceId } = meta.arg;

                const device = devicesAdapter.getSelectors().selectById(state.list.devices.items, deviceId);
                if (!device) {
                    return;
                }

                // Check if re-fetched commands have a change in status
                const updatedCommands = payload.filter((newCommand) => {
                    const command = device?.commands?.find((c) => c.id === newCommand.id);
                    return command && command.status !== newCommand.status;
                });

                // Check if there are any new commands
                const newCommands = payload.filter(
                    (newCommand) => !device?.commands?.find((c) => c.id === newCommand.id),
                );
                if (updatedCommands.length === 0 && newCommands.length === 0) {
                    return;
                }

                devicesAdapter.updateOne(state.list.devices.items, {
                    id: deviceId,
                    changes: {
                        commands: payload,
                    },
                });
            })
            .addCase(createDeviceMdmCommand.fulfilled, (state, { payload, meta }) => {
                const { deviceId } = meta.arg;

                const device = devicesAdapter.getSelectors().selectById(state.list.devices.items, deviceId);
                if (!device) {
                    return;
                }

                const commands = device.commands ?? [];
                const newCommands = payload ?? [];

                devicesAdapter.updateOne(state.list.devices.items, {
                    id: deviceId,
                    changes: {
                        commands: [...commands, ...newCommands],
                    },
                });
            })
            .addCase(deleteDeviceMdmCommand.fulfilled, (state, { meta }) => {
                const { deviceId, commandId } = meta.arg;

                const device = devicesAdapter.getSelectors().selectById(state.list.devices.items, deviceId);
                if (!device) {
                    return;
                }

                const commands = device.commands ?? [];

                devicesAdapter.updateOne(state.list.devices.items, {
                    id: deviceId,
                    changes: {
                        commands: commands.filter((c) => c.id !== commandId),
                    },
                });
            })
            .addCase(updateLicense.fulfilled, (state, { meta }) => {
                if (!meta || !meta.arg) {
                    return;
                }

                const { id, comment } = meta.arg;

                const license = licensesAdapter.getSelectors().selectById(state.list.licenses.items, id);

                if (!license) {
                    return;
                }

                licensesAdapter.updateOne(state.list.licenses.items, {
                    id,
                    changes: {
                        comment: comment,
                    },
                });

                resetBatchAction(state);
            })
            .addCase(generateLicenses.fulfilled, (state, { payload, meta }) => {
                const { licenses } = normalizeLicenses(payload);
                const group = groupsAdapter.getSelectors().selectById(state.list.groups.items, meta.arg.groupId);
                const replacedLicenesIds = payload.map((license) => license.id);
                if (group) {
                    groupsAdapter.updateOne(state.list.groups.items, {
                        id: meta.arg.groupId,
                        changes: {
                            licenseIds: [...group.licenseIds, ...replacedLicenesIds],
                        },
                    });
                }
                licensesAdapter.addMany(state.list.licenses.items, licenses);
            })
            .addCase(revokeLicense.fulfilled, (state, { payload }) => {
                const { id, revocationDate, revocationReason } = payload;
                licensesAdapter.updateOne(state.list.licenses.items, {
                    id,
                    changes: {
                        revocationDate,
                        revocationReason,
                    },
                });
            })
            .addCase(updateUniversalLicense.fulfilled, (state, { payload }) => {
                const { model: license } = payload;

                licensesAdapter.updateOne(state.list.licenses.items, {
                    id: license.id,
                    changes: {
                        universalGroupName: license.universalGroupName,
                        universalGroupId: license.universalGroupId,
                    },
                });
            })
            .addCase(deleteUniversalLicense.fulfilled, (state, { payload }) => {
                const { model: license } = payload;

                licensesAdapter.updateOne(state.list.licenses.items, {
                    id: license.id,
                    changes: {
                        universalGroupName: null,
                        universalGroupId: null,
                    },
                });
            })
            .addCase(updateDevice.fulfilled, (state, { meta }) => {
                if (!meta || !meta.arg) {
                    return;
                }

                const { id, name, comment, description } = meta.arg;
                const device = devicesAdapter.getSelectors().selectById(state.list.devices.items, id);

                if (!device) {
                    return;
                }

                licensesAdapter.updateOne(state.list.licenses.items, {
                    id: device.licenseId,
                    changes: {
                        comment: comment,
                        device: { ...device, name, description },
                    },
                });

                resetBatchAction(state);
            })
            .addCase(redeemVoucher.fulfilled, (state, { payload }) => {
                subscriptionsAdapter.addOne(state.list.subscriptions.items, payload);
            });
    },
});

export default subscriptionsSlice.reducer;

export const {
    showDetails,
    closeDetails,
    toggleFilters,
    resetFiltersToDefault,
    updateFilterProperties,
    updateFilterProperty,
    updateSearchQuery,
    setBatchMode,
    addBatchSelection,
    removeBatchSelection,
    closeBatchMode,
    setBatchTargetId,
    setViewGroupsCollapseById,
    setViewGroupsExpandById,
    setViewGroupsCollapseAll,
    setViewPageSize,
    removeGroupLocally,
    showLicenseById,
    setPaneTab,
    setPaneEditEnabled,
    addSubscriptions,
} = subscriptionsSlice.actions;
