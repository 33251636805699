import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    section: {
        display: "flex",
        flexDirection: "column",
    },
    tileBox: {
        display: "flex",
        flexWrap: "wrap",
        padding: "12px 0",
        gap: 8,
        marginBottom: 24,
    },
    tileContainer: {
        backgroundColor: theme.palette.blue[50],
        border: "1px solid " + theme.palette.grey[300],
        display: "inline-flex",
        position: "relative",
        width: "100%",
        height: 88,
        borderRadius: 4,
    },
    tileLabel: { textOverflow: "ellipsis", overflow: "hidden", fontWeight: "normal", fontSize: 16 },
    tileContent: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        padding: "12px 0",
    },
    limitIconContainer: {
        display: "flex",
        justifyContent: "center",
    },
}));
