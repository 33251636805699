import clsx from "clsx";
import { diff } from "deep-diff";
import _ from "lodash";
import { bindPopover } from "material-ui-popup-state";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import React from "react";
import { permissionService } from "src/services/permissionService/permissionService";
import { useDispatch, useSelector } from "src/store";
import { createRolloutTag } from "src/ui/containers/dashboard/shared/tags";
import { Tooltip } from "src/ui/shared/Tooltip";

import { ConfirmDialog } from "@dashboard/devices/components/shared/index";
import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import { LogStreamer } from "@dashboard/products/components/LogStreamer/LogStreamer";
import { cancelBuild, deleteBuild, fetchFileDownloadLinks } from "@dashboard/products/store";
import { updateBuildChannel } from "@dashboard/products/store/index";
import { Build, Product } from "@dashboard/products/types";
import { Tag } from "@dashboard/shared/components/Tag/Tag";
import { alternatingBg } from "@dashboard/shared/styles";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Box, Popover, TableRow, Theme, Typography, useTheme } from "@mui/material";
import { SelectInputProps } from "@mui/material/Select/SelectInput";
import makeStyles from "@mui/styles/makeStyles";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { UncontrolledSelectField } from "@shared/form/SelectField";
import { MinimalTableCell } from "@shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { BuildSettingsDialog } from "../Builds/BuildSettingsDialog";
import { Artifacts } from "./Artifacts";

const channelOptions = [
    { label: "Live", value: "live" },
    { label: "Beta", value: "beta" },
    { label: "Nightly", value: "nightly" },
    { label: "None", value: "none" },
];

const useStyles = makeStyles((theme: Theme) => ({
    artifactsBox: {
        display: "flex",
    },
    actionBox: {
        display: "flex",
        gap: 6,
        justifyContent: "flex-end",
    },
    tagsBox: {
        whiteSpace: "normal",
        padding: "10px 0px 10px 0px",
        display: "flex",
        flexWrap: "wrap",
        height: "auto",
        gap: 2,
        maxWidth: 400,
        alignItems: "center",
    },
    channelSelect: {
        width: 100,
        height: 40,
        display: "flex",
        justifyContent: "center",
        "& .MuiInput-formControl": {
            marginTop: 0,
        },
    },
    popover: {
        padding: "20px",
        borderRadius: "8px",
        whiteSpace: "nowrap",
        maxWidth: "none",
    },
    oldConfigIcon: {
        color: theme.palette.orange[100],
        fontSize: 16,
        marginLeft: 6,
    },
    changeList: {
        paddingLeft: 16,
    },
    downloadableFileList: {
        paddingLeft: "20px",
        marginTop: "10px",
    },
}));

const getDifference = (obj1: object | null, obj2: object | null) => {
    const differences = diff(obj1 || {}, obj2 || {});

    if (differences) {
        const differencesGroupByKind = _.groupBy(differences, "kind");
        return _.mapValues(differencesGroupByKind, (g) =>
            g.map((item) => {
                if (item.path && item.path.length === 1) {
                    return item.path[0].toString();
                }
                if (item.path && item.path.length > 1) {
                    return item.path.join(".");
                }
                return "-";
            }),
        );
    }
};

type ChangesTooltipProps = {
    kind: string;
    changes: string[];
};
const ChangesTooltip = (props: ChangesTooltipProps) => {
    let title = "-";

    switch (props.kind) {
        case "N":
            title = "Additional";
            break;
        case "D":
            title = "Missing";
            break;
        case "E":
            title = "Different";
            break;
        case "A":
            title = "Different";
            break;
    }

    return (
        <React.Fragment>
            <Typography variant="subtitle2">{title}:</Typography>
            <ul style={{ paddingLeft: 16 }}>
                {props.changes.map((value) => (
                    <li key={value}>
                        <Typography variant="caption">{value}</Typography>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
};

const StatusIcon = (props: { buildStatus: API.Build["buildStatus"] | null }) => {
    const theme = useTheme();

    switch (props.buildStatus) {
        case "running":
            return (
                <Tooltip title="Build is running" placement="right">
                    <i className="fas fa-spinner fa-spin" style={{ color: theme.palette.blue[300] }} />
                </Tooltip>
            );
        case "pending":
            return (
                <Tooltip title="Build is pending" placement="right" style={{ color: theme.palette.blue[300] }}>
                    <i className="fas fa-hourglass-half" />
                </Tooltip>
            );
        case "canceled":
            return (
                <Tooltip title="Build canceled" placement="right">
                    <i className="fas fa-ban" style={{ color: theme.palette.grey[500] }} />
                </Tooltip>
            );
        case "skipped":
            return (
                <Tooltip title="Build skipped" placement="right">
                    <i className="fa-regular fa-circle-right" style={{ color: theme.palette.grey[500] }} />
                </Tooltip>
            );
        case "succeeded":
            return (
                <Tooltip title="Build succeeded" placement="right">
                    <i className="fa-regular fa-circle-check" style={{ color: theme.palette.green[50] }} />
                </Tooltip>
            );
        case "failed":
            return (
                <Tooltip title="Build failed" placement="right">
                    <i className="fa-regular fa-circle-xmark" style={{ color: theme.palette.red[50] }} />
                </Tooltip>
            );
        default:
            return (
                <Tooltip title="Unknown status" placement="right">
                    <i className="fa-regular fa-circle-question" style={{ color: theme.palette.red[50] }} />
                </Tooltip>
            );
    }
};

interface Props {
    index: number;
    product: Product;
    build: Build;
}
const BuildTableItem = (props: Props) => {
    const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isFetchingLink, setIsFetchingLink] = React.useState(false);
    const [isCanceling, setIsCanceling] = React.useState(false);
    const [isDeleteBuildDialogOpen, setIsDeleteBuildDialogOpen] = React.useState(false);

    const classes = useStyles();
    const dispatch = useDispatch();

    const downloadableFiles = props.build.files.filter((file) => file.type !== "ota");
    const buildHasNoFiles = !downloadableFiles.length;
    const buildProcessing = props.build.buildStatus === "pending" || props.build.buildStatus === "running";
    const fullBuildFile = props.build.files.find((item) => item.metadata?.updateType === "full");
    const fullBuildFileCompatibility = fullBuildFile?.metadata?.compatibility;
    const compatibility = props.build.compatibility || fullBuildFileCompatibility;
    const popupState = usePopupState({ variant: "popover", popupId: "file-download-menu" });
    const differences = getDifference(props.build?.configSnapshot, props.product.customBuildConfig);
    const currentWorkspace = useSelector(selectWorkspace);

    const removableTags = props.build.metadata?.user?.tags;
    const rolloutTag = createRolloutTag(props.build.rolloutPercentage);
    const hasTags = removableTags?.length || rolloutTag;

    const { productAbility } = permissionService();
    const cannotManageProducts = productAbility(currentWorkspace).cannot("manage", "Product");
    const canceledOrFailedBuild = props.build.buildStatus === "canceled" || props.build.buildStatus === "failed";

    const handleFetchLink = async () => {
        try {
            setIsFetchingLink(true);
            await dispatch(fetchFileDownloadLinks(props.build)).unwrap();
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
        } finally {
            setIsFetchingLink(false);
        }
    };
    const handleCancel = async () => {
        try {
            setIsCanceling(true);
            await dispatch(cancelBuild(props.build.buildId)).unwrap();
            showSuccessToast("Build canceled successfully");
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
        } finally {
            setIsCanceling(false);
        }
    };
    const handleDelete = async () => {
        try {
            setIsDeleting(true);
            await dispatch(deleteBuild({ productId: props.build.productId, buildId: props.build.buildId })).unwrap();
            showSuccessToast("Build deleted successfully");
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
        } finally {
            setIsDeleting(false);
            setIsDeleteBuildDialogOpen(false);
        }
    };
    const handlePopoverOpen: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
        bindTrigger(popupState).onClick(e);
        await handleFetchLink().then(() => popupState.open());
    };
    const onChannelChange: SelectInputProps["onChange"] = (event) => {
        const selectedChannel = event.target.value as Build["channel"];
        dispatch(
            updateBuildChannel({
                productId: props.build.productId,
                buildId: props.build.buildId,
                channel: selectedChannel,
            }),
        )
            .unwrap()
            .then(() => {
                showSuccessToast("The build channel was successfully changed");
            })
            .catch(({ message = "An error occurred while changing the build channel" }) => {
                showErrorToast(message);
            });
    };

    const getChannelInputTooltip = () => {
        if (cannotManageProducts) {
            return "Your role does not allow you to edit builds";
        } else if (canceledOrFailedBuild) {
            return "Cancelled and failed builds have no release channel";
        } else {
            return "";
        }
    };

    return (
        <React.Fragment>
            <TableRow style={alternatingBg(props.index)}>
                <MinimalTableCell width={40}>
                    <StatusIcon buildStatus={props.build.buildStatus} />
                    {differences ? (
                        <Tooltip
                            placement="right"
                            title={Object.entries(differences).map(([kind, changes]) => (
                                <ChangesTooltip key={kind} {...{ kind, changes }} />
                            ))}
                        >
                            <i className={clsx(classes.oldConfigIcon, "fa fa-info-circle")} />
                        </Tooltip>
                    ) : null}
                </MinimalTableCell>
                <MinimalTableCell width={100}>{props.build.version}</MinimalTableCell>
                <MinimalTableCell width={100}>
                    <Tooltip title={convertToLocalDate(props.build.created, "ISO", "dateTime")}>
                        <span>{convertToLocalDate(props.build.created, "ISO", "date")}</span>
                    </Tooltip>
                </MinimalTableCell>
                <MinimalTableCell width={100}>
                    {props.build.built ? (
                        <Tooltip title={convertToLocalDate(props.build.built, "ISO", "dateTime")}>
                            <span>{convertToLocalDate(props.build.built, "ISO", "date")}</span>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </MinimalTableCell>
                <MinimalTableCell width={100}>{compatibility || "-"}</MinimalTableCell>
                <MinimalTableCell>
                    <div className={classes.artifactsBox}>
                        {props.build.artifacts?.length ? (
                            <Artifacts artifacts={props.build.artifacts} />
                        ) : (
                            <Typography>-</Typography>
                        )}
                    </div>
                </MinimalTableCell>
                <MinimalTableCell className={classes.tagsBox}>
                    {removableTags?.length ? removableTags.map((item) => <Tag key={item.key} item={item} />) : null}
                    {rolloutTag ? <Tag key={rolloutTag.key} item={rolloutTag} theme="extra" /> : null}
                    {!hasTags ? <Typography>-</Typography> : null}
                </MinimalTableCell>
                <MinimalTableCell width={140} align="center">
                    <Tooltip title={getChannelInputTooltip()}>
                        <UncontrolledSelectField
                            className={classes.channelSelect}
                            value={props.build.channel}
                            name="channel"
                            options={channelOptions}
                            onChange={onChannelChange}
                            disabled={canceledOrFailedBuild || cannotManageProducts}
                        />
                    </Tooltip>
                </MinimalTableCell>
                <MinimalTableCell width={120}>
                    <div className={classes.actionBox}>
                        <SecondaryButton
                            onClick={() => setOpenSettingsDialog(true)}
                            tooltipProps={{
                                title: cannotManageProducts
                                    ? "Your role does not allow you to edit builds"
                                    : "Settings",
                            }}
                            disabled={isDeleting || cannotManageProducts}
                        >
                            <i className="fa-solid fa-gear" />
                        </SecondaryButton>
                        <LogStreamer
                            buildId={props.build.buildId}
                            hasLogs={props.build.hasLogs}
                            disabled={isDeleting}
                        />
                        <div>
                            <SecondaryButton
                                onClick={handlePopoverOpen}
                                disabled={buildHasNoFiles || isDeleting}
                                tooltipProps={{ title: "Download artifacts" }}
                                loading={isFetchingLink}
                            >
                                <i className="fas fa-cloud-arrow-down" />
                            </SecondaryButton>
                            <Popover
                                {...bindPopover(popupState)}
                                hidden={isFetchingLink}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <Box className={classes.popover}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Downloadable Files:
                                    </Typography>
                                    <ul className={classes.downloadableFileList}>
                                        {downloadableFiles.map((item) =>
                                            item.link ? (
                                                <li key={item.id} style={{ marginBottom: "8px" }}>
                                                    <a
                                                        href={item.link ?? ""}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ textDecoration: "none" }}
                                                    >
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ) : null,
                                        )}
                                    </ul>
                                </Box>
                            </Popover>
                        </div>
                        {buildProcessing ? (
                            <DangerOutlinedButton
                                onClick={handleCancel}
                                tooltipProps={{
                                    title: cannotManageProducts
                                        ? "Your role does not allow you to cancel builds"
                                        : "Cancel build",
                                }}
                                disabled={isCanceling || cannotManageProducts}
                                loading={isCanceling}
                            >
                                <i className="fa-solid fa-ban" />
                            </DangerOutlinedButton>
                        ) : (
                            <DangerOutlinedButton
                                onClick={() => setIsDeleteBuildDialogOpen(true)}
                                tooltipProps={{
                                    title: cannotManageProducts
                                        ? "Your role does not allow you to delete build"
                                        : "Remove build",
                                }}
                                disabled={isDeleting || cannotManageProducts}
                                loading={isDeleting}
                            >
                                <i className="fas fa-trash-alt" />
                            </DangerOutlinedButton>
                        )}
                    </div>
                </MinimalTableCell>
            </TableRow>
            <BuildSettingsDialog
                open={openSettingsDialog}
                build={props.build}
                onClose={() => setOpenSettingsDialog(false)}
            />
            <ConfirmDialog
                title="Delete build"
                open={isDeleteBuildDialogOpen}
                onConfirm={handleDelete}
                onClose={() => setIsDeleteBuildDialogOpen(false)}
                dangerButton
                primaryActionText="Delete"
            />
        </React.Fragment>
    );
};

export default BuildTableItem;
