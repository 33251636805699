import { styled, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    sectionHeader: {
        fontSize: 18,
        lineHeight: "23.94px",
        paddingBottom: 8,
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        flexShrink: 0,
    },
    sectionContainer: {
        padding: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: theme.spacing(4),
    },
    input: {
        marginTop: 4,
        height: 40,
        flex: 0.7,
    },
    hints: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: theme.spacing(0.5),
        marginTop: 14,
    },
    hintText: {
        fontSize: 12,
    },
    switchButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    adornment: {
        "& .MuiOutlinedInput-root": {
            paddingLeft: 0,
        },
        "& .MuiInputAdornment-root": {
            padding: "20px 0px 20px 14px",
            marginRight: "0px",
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
        },
        pageTitleBox: {
            display: "flex",
        },
    },
    tooltipInputContainer: {
        marginTop: 20,
    },
}));

export const InputItemContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: 20,
    flexShrink: 0,
});

export const InputItemLabel = styled(Typography)({
    fontSize: 16,
    lineHeight: "18.62px",
    flexShrink: 0,
    flex: 0.3,
});

export const FieldsContainer = styled("div")({
    paddingTop: 24,
    paddingBottom: 8,
});
