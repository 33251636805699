import clsx from "clsx";
import { FormApi } from "final-form";
import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { Form } from "react-final-form";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { CheckboxField } from "src/ui/shared/form/CheckboxField";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { Box, Checkbox, Dialog, DialogProps, FormControlLabel, Typography } from "@mui/material";
import { CustomBadge } from "@shared/CustomBadge";

import { useStyles } from "./TagFilterDialog.style";

export const useTagFilters = (files: API.File[]) => {
    const [filters, setFilters] = React.useState<Record<string, boolean>>({});

    const activeFilters = _.pickBy(filters, (value) => value);
    const activeFiltersCount = Object.values(activeFilters).length;

    const prevTags = React.useRef<API.Tag[]>();
    const tags = _.compact(_.flatMap(files, (file) => file.metadata?.user?.tags));

    const tagsChanged = !_.isEqual(
        _.uniq(_.sortBy(prevTags.current?.map((item) => item.key))),
        _.uniq(_.sortBy(tags.map((item) => item.key))),
    );
    if (tagsChanged) {
        prevTags.current = tags;
        setFilters(_.mapValues(_.keyBy(tags, "key"), () => false));
    }

    return { filters, activeFiltersCount, setFilters };
};

const changeAllValuesTo = (obj: Record<string, boolean>, value: boolean) => {
    const copy = { ...obj };
    for (const key of Object.keys(copy)) {
        copy[key] = value;
    }
    return copy;
};

interface FormValues {
    [x: string]: boolean;
}

interface Props extends DialogProps {
    filters: Record<string, boolean>;
    setFilters: Dispatch<SetStateAction<Record<string, boolean>>>;
    setOpenFilterDialog: Dispatch<SetStateAction<boolean>>;
}

export const TagFilterDialog = ({ filters, setFilters, setOpenFilterDialog, ...props }: Props) => {
    const classes = useStyles();
    const filtersArr = Object.keys(filters);
    const truthyFilters = changeAllValuesTo(filters, true);
    const falsyFilters = changeAllValuesTo(filters, false);

    const onSubmit = (values: FormValues) => {
        setFilters(values);
        setOpenFilterDialog(false);
    };

    const handleCloseButton = (form: FormApi<FormValues, Partial<FormValues>>) => {
        form.reset(filters);
        setOpenFilterDialog(false);
    };

    return (
        <>
            <CustomBadge
                className={classes.filterBadge}
                badgeContent={Object.values(filters).filter((item) => item).length}
                onClick={() => setOpenFilterDialog(true)}
                overlap="rectangular"
                data-testid="tag-filter-button"
            >
                <Icon size={IconSize.small} className={classes.tagIcon} name="fa-solid fa-filter" />
            </CustomBadge>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={filters}
                render={({ form, handleSubmit, values }) => {
                    return (
                        <Dialog
                            {...props}
                            onClose={(_event, reason) => reason === "backdropClick" && handleCloseButton(form)}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className={classes.container}>
                                    <div className={classes.dialogTitleBox}>
                                        <Typography className={classes.title}>Tag filtering</Typography>
                                        <div
                                            className={clsx("fa-solid fa-times", classes.closeBtn)}
                                            onClick={() => handleCloseButton(form)}
                                        />
                                    </div>
                                    <div className={classes.content}>
                                        {!filtersArr.length ? (
                                            <Box className={classes.empty}>
                                                <Typography>No tags</Typography>
                                            </Box>
                                        ) : null}
                                        <div className={classes.checkboxContainer}>
                                            {filtersArr.map((item) => (
                                                <CheckboxField key={item} name={item} label={item} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.selectManyContainer}>
                                        {Object.values(values).length ? (
                                            <FormControlLabel
                                                label={<span className={classes.informationText}>Select all</span>}
                                                control={
                                                    <Checkbox
                                                        checked={Object.values(values).every((item) => item)}
                                                        indeterminate={false}
                                                        onChange={(
                                                            _e: React.ChangeEvent<HTMLInputElement>,
                                                            checked: boolean,
                                                        ) => {
                                                            form.reset(checked ? truthyFilters : falsyFilters);
                                                        }}
                                                    />
                                                }
                                            />
                                        ) : null}
                                    </div>
                                    <Box className={classes.buttonBox}>
                                        <SecondaryButton onClick={() => handleCloseButton(form)}>
                                            Cancel
                                        </SecondaryButton>
                                        <PrimaryButton type="submit">Save</PrimaryButton>
                                    </Box>
                                </div>
                            </form>
                        </Dialog>
                    );
                }}
            />
        </>
    );
};
