import clsx from "clsx";
import { PropsWithChildren } from "react";

import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { styled, Table, TableCell, TableCellProps, TableHead, TableSortLabel, TooltipProps } from "@mui/material";

import { CustomBadge } from "../CustomBadge";
import { Tooltip } from "../Tooltip";
import { useStyles } from "./TableHeader.style";

export const MinimalTable = styled(Table)(({ theme }) => ({
    border: `1px solid ${theme.palette.black[50]}`,
}));
export const MinimalTableHeader = styled(TableHead)(({ theme }) => ({
    "& .MuiTableRow-root": {
        border: `1px solid ${theme.palette.black[50]}`,
    },
}));

const StyledMinimalTableCell = styled(TableCell)(({ theme }) => ({
    height: 50,
    fontSize: 16,
    padding: "0px 10px",
    borderTop: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottomWidth: 0,
    borderColor: theme.palette.black[50],
}));

const TruncatedContent = ({
    children,
    maxWidth = "200px",
    testid,
}: {
    children: React.ReactNode;
    maxWidth?: string;
    testid?: string;
}) => (
    <span
        style={{
            display: "inline-block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth,
        }}
        data-testid={testid}
    >
        {children}
    </span>
);

interface MinimalTableCellProps extends TableCellProps {
    maxWidth?: number;
    border?: boolean;
}

export const MinimalTableCell = ({ border, maxWidth, children, ...props }: MinimalTableCellProps) => {
    return (
        <StyledMinimalTableCell
            {...props}
            style={{
                maxWidth: maxWidth ? `${maxWidth}px` : "unset",
                borderWidth: border ? "1px" : "0px",
                ...props.style,
            }}
        >
            {children}
        </StyledMinimalTableCell>
    );
};

interface CellWithTooltipProps extends PropsWithChildren {
    title: string;
    maxLength?: number;
    testid?: string;
    style?: React.CSSProperties;
    tooltipProps?: Omit<TooltipProps, "children" | "title">;
}
export const MinimalTableCellWithTooltip = ({ maxLength = 1000, title, ...rest }: CellWithTooltipProps) => {
    const maxWidth =
        typeof rest.style?.maxWidth === "number" ? `${rest.style?.maxWidth}px` : rest.style?.maxWidth || "200px";

    return (
        <StyledMinimalTableCell
            {...rest}
            style={{
                ...rest.style,
                maxWidth,
                overflow: "hidden",
            }}
        >
            <Tooltip
                placement="top"
                title={title}
                hide={title.length <= maxLength}
                {...rest.tooltipProps}
                style={{
                    ...rest.tooltipProps?.style,
                    maxWidth,
                }}
            >
                <TruncatedContent maxWidth={maxWidth} testid={rest.testid}>
                    {rest.children}
                </TruncatedContent>
            </Tooltip>
        </StyledMinimalTableCell>
    );
};

const StyledMinimalHeaderCell = styled((props: MinimalTableHeaderCellProps) => <TableCell {...props} />)(
    ({ theme }) => ({
        height: 50,
        padding: "0px 10px 0px 10px",
        fontSize: 16,
        fontWeight: 600,
        backgroundColor: theme.palette.blue[50],
        color: `${theme.palette.black[100]} !important`,
        "& .MuiTableSortLabel-root": {
            color: `${theme.palette.black[100]} !important`,
            fontWeight: 600,
            "&:hover": {
                color: `${theme.palette.black[100]} !important`,
            },
            "&.MuiTableSortLabel-active": {
                color: `${theme.palette.black[100]} !important`,
            },
            "& .MuiTableSortLabel-icon": {
                color: "inherit !important",
            },
        },
    }),
);

type Item = {
    id: string;
    label: string;
};
interface MinimalTableHeaderCellProps extends TableCellProps {
    item?: Item;
    sort?: { orderBy: string; orderDirection: "asc" | "desc" };
    activeFiltersCount?: number;
    onSort?: (orderBy: string) => void;
    onFilter?: (item: Item) => void;
    sortableColumnIds?: string[];
    filterableColumnIds?: string[];
    align?: "inherit" | "left" | "center" | "right" | "justify";
    sortable?: boolean;
    sortDirection?: "asc" | "desc" | false;
    cellProps?: TableCellProps;
}
export const MinimalTableHeaderCell = (props: MinimalTableHeaderCellProps) => {
    const classes = useStyles();
    const { item, sort, activeFiltersCount, sortableColumnIds, filterableColumnIds, children, ...rest } = props;

    if (!item) {
        return <StyledMinimalHeaderCell {...rest}>{children}</StyledMinimalHeaderCell>;
    }

    const isSortable = sortableColumnIds?.includes(item.id);
    const isFilterable = filterableColumnIds?.includes(item.id);

    const onSort = () => {
        if (props.onSort) {
            props.onSort(item.id);
        }
    };

    const onFilter = () => {
        if (props.onFilter) {
            props.onFilter(item);
        }
    };

    if (isSortable) {
        return (
            <StyledMinimalHeaderCell align="left" sortDirection={sort?.orderDirection} {...props.cellProps}>
                <TableSortLabel
                    active={sort?.orderBy === item.id}
                    direction={sort?.orderDirection || "asc"}
                    className={classes.sortLabel}
                    onClick={onSort}
                >
                    {item.label}
                </TableSortLabel>
            </StyledMinimalHeaderCell>
        );
    }

    if (isFilterable) {
        return (
            <StyledMinimalHeaderCell {...props.cellProps}>
                {item.label}
                <CustomBadge
                    className={clsx(classes.badge, classes.withPointer)}
                    badgeContent={activeFiltersCount}
                    onClick={onFilter}
                    data-testid="tag-filter-button"
                    overlap="rectangular"
                >
                    <Icon size={IconSize.small} className={classes.tagIcon} name="fa-solid fa-filter" />
                </CustomBadge>
            </StyledMinimalHeaderCell>
        );
    }

    return (
        <StyledMinimalHeaderCell align="left" {...props.cellProps}>
            {item.label}
        </StyledMinimalHeaderCell>
    );
};
