import { Form } from "react-final-form";
import { useDispatch, useSelector } from "src/store";

import { PaneElement } from "@dashboard/shared/components/SidePanel/shared/PaneElement";
import { selectPaneEditEnabled, setPaneEditEnabled, updateDevice } from "@devices/store";
import { License } from "@devices/types";
import { convertToLocalDate } from "@devices/utils/dates";
import { getDeviceOnlineSince, getHumanReadableDeviceUptime } from "@devices/utils/device";
import { getLicenseActivationStatus, getLicenseStatusTooltip } from "@devices/utils/license";
import { Box } from "@mui/material";
import { TextField } from "@shared/form/TextField";
import { Tooltip } from "@shared/Tooltip";
import { EditPrimaryButtons } from "@sidePanel/shared/EditActionButtons";
import { PropertyKey, PropertyRow, PropertyValue } from "@sidePanel/shared/Property";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

type FormValues = {
    name: string | null;
    description: string | null;
    comment: string | null;
};

function DeviceSummaryTab(props: License): JSX.Element {
    const dispatch = useDispatch();
    const { lastState: deviceState } = props.device ?? { lastState: null };
    const editEnabled = useSelector(selectPaneEditEnabled);

    const lastOnlineDate = props.device?.lastOnline
        ? convertToLocalDate(props.device.lastOnline, "ISO", "dateTime")
        : "-";
    const lastStateDate = props.device?.lastStateDate
        ? convertToLocalDate(props.device.lastStateDate, "ISO", "dateTime")
        : "-";

    const uptimeHumanReadable = getHumanReadableDeviceUptime(deviceState?.datetime?.elapsedMs, props.device?.isOnline);
    const onlineSinceHumanReadable = getDeviceOnlineSince(props.device);

    const onSubmit = async (payload: FormValues) => {
        if (props.device?.id) {
            try {
                await dispatch(updateDevice({ id: props.device.id, ...payload })).unwrap();
                dispatch(setPaneEditEnabled(false));
                showSuccessToast("Successfully updated the device");
            } catch (error) {
                const err = error as Error;
                showErrorToast(err.message);
            }
        }
    };

    return (
        <PaneElement.Container>
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    name: props.device?.name || null,
                    description: props.device?.description || null,
                    comment: props.comment,
                }}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <PaneElement.Section>
                            <PaneElement.Header>License</PaneElement.Header>
                            <PropertyRow>
                                <PropertyKey>Id</PropertyKey>
                                <PropertyValue>{props.id ?? "-"}</PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Comment</PropertyKey>
                                <PropertyValue>
                                    {editEnabled ? (
                                        <TextField name="comment" style={{ marginTop: 5 }} disabled={submitting} />
                                    ) : (
                                        <Box component="span">{props.comment ?? "-"}</Box>
                                    )}
                                </PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Owner</PropertyKey>
                                <PropertyValue>{props.ownerEmail || "-"}</PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Status</PropertyKey>
                                <Tooltip placement="right" title={getLicenseStatusTooltip(props)}>
                                    <span>{getLicenseActivationStatus(props)}</span>
                                </Tooltip>
                            </PropertyRow>
                        </PaneElement.Section>

                        <PaneElement.Section>
                            <PaneElement.Header>Device</PaneElement.Header>
                            <PropertyRow>
                                <PropertyKey>Name</PropertyKey>
                                <PropertyValue>
                                    {editEnabled ? (
                                        <TextField name="name" style={{ marginTop: 5 }} disabled={submitting} />
                                    ) : (
                                        <Box component="span">{props.device?.name ?? "-"}</Box>
                                    )}
                                </PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Description</PropertyKey>
                                <PropertyValue>
                                    {editEnabled ? (
                                        <TextField name="description" style={{ marginTop: 5 }} disabled={submitting} />
                                    ) : (
                                        <Box component="span">{props.device?.description ?? "-"}</Box>
                                    )}
                                </PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Product</PropertyKey>
                                <PropertyValue>{props.device?.product ?? "-"}</PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Serial no.</PropertyKey>
                                <PropertyValue>{props.device?.serial ?? "-"}</PropertyValue>
                            </PropertyRow>
                        </PaneElement.Section>

                        <PaneElement.Section>
                            <PaneElement.Header>OS version</PaneElement.Header>
                            <PropertyRow>
                                <PropertyKey>Android version</PropertyKey>
                                <PropertyValue>
                                    {deviceState ? props.device?.lastState?.version.androidVersion : "-"}
                                </PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Emteria version</PropertyKey>
                                <PropertyValue>
                                    {deviceState ? props.device?.lastState?.version.emteriaVersion : "-"}
                                </PropertyValue>
                            </PropertyRow>
                        </PaneElement.Section>

                        <PaneElement.Section withoutBorder>
                            <PaneElement.Header>Timestamps</PaneElement.Header>
                            <PropertyRow>
                                <PropertyKey>Last connection</PropertyKey>
                                <PropertyValue>{lastOnlineDate}</PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Last report</PropertyKey>
                                <PropertyValue>{lastStateDate}</PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Device uptime</PropertyKey>
                                <PropertyValue>{uptimeHumanReadable ?? "-"}</PropertyValue>
                            </PropertyRow>
                            <PropertyRow>
                                <PropertyKey>Online since</PropertyKey>
                                <PropertyValue>{onlineSinceHumanReadable ?? "-"}</PropertyValue>
                            </PropertyRow>
                        </PaneElement.Section>
                        <EditPrimaryButtons loading={submitting} />
                    </form>
                )}
            />
        </PaneElement.Container>
    );
}
export default DeviceSummaryTab;
