import fileSize from "filesize";
import { useEffect, useRef, useState } from "react";
import Highlight from "react-highlighter";
import { useDispatch, useSelector } from "src/store";
import { CustomTileLink } from "src/ui/shared/CustomTile";
import { UncontrolledCheckboxField } from "src/ui/shared/form/CheckboxField";

import { IndicatorTableCell } from "@dashboard/devices/components/Listing/License/License.style";
import { getSummaryIndicatorColor } from "@dashboard/devices/DevicesPage.utils";
import {
    addBatchSelection,
    removeBatchSelection,
    selectBatchActionSelectionTransformed,
    selectSearchQuery,
} from "@dashboard/devices/store";
import { SwitchBaseProps } from "@mui/material/internal/SwitchBase";
import makeStyles from "@mui/styles/makeStyles";
import { Tooltip } from "@shared/Tooltip";

const useStyles = makeStyles({
    tableCell: {
        cursor: "pointer",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    licenseSummaryIndicator: {
        cursor: "pointer",
        width: "5px",
        borderRadius: "24px",
        height: 34,
    },
});

const TableCell = (props: { value: string }) => {
    const classes = useStyles();
    const searchQuery = useSelector(selectSearchQuery);

    const [isTruncated, setIsTruncated] = useState(false);
    const textRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        const el = textRef.current;
        if (el) {
            setIsTruncated(el.scrollWidth > el.clientWidth);
        }
    }, [props.value]);

    return (
        <Tooltip
            title={isTruncated ? props.value : ""}
            style={{ display: "inline-grid" }}
            placement="top"
            PopperProps={{ disablePortal: false }}
        >
            <span ref={textRef} className={classes.tableCell}>
                <Highlight search={searchQuery}>{props.value ?? "-"}</Highlight>
            </span>
        </Tooltip>
    );
};
const MoveLicenseCell = (props: { value: number }) => {
    const dispatch = useDispatch();

    const selectBatchActionSelected = useSelector(selectBatchActionSelectionTransformed);
    const checked = props.value in selectBatchActionSelected;

    const onChange: SwitchBaseProps["onChange"] = (event) => {
        const fn = event.target.checked ? addBatchSelection : removeBatchSelection;
        dispatch(fn(props.value));
    };

    return <UncontrolledCheckboxField {...{ checked, onChange }} />;
};
const StatusCell = (props: { value: string }) => {
    const classes = useStyles();
    const backgroundColor = getSummaryIndicatorColor(props.value);

    return (
        <IndicatorTableCell>
            <Tooltip placement="right" title={props.value}>
                <div className={classes.licenseSummaryIndicator} style={{ backgroundColor }} />
            </Tooltip>
        </IndicatorTableCell>
    );
};
const FileSizeCell = (props: { value: number }) => {
    return <TableCell value={fileSize(props.value)} />;
};
const LinkCell = (props: { title: string; value: string }) => {
    return (
        <Tooltip title={props.title}>
            <CustomTileLink>{props.value}</CustomTileLink>
        </Tooltip>
    );
};

export { LinkCell, FileSizeCell, MoveLicenseCell, StatusCell };
