import { compareVersions } from "compare-versions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { CustomTileContainer, CustomTileTitle } from "src/ui/shared/CustomTile";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "src/ui/shared/table/MinimalTableComponents";
import { Tooltip } from "src/ui/shared/Tooltip";

import { setPaneTab, showLicenseById, updateFilterProperty } from "@dashboard/devices/store";
import { selectDevicesByGroupIds } from "@dashboard/devices/store/selectors/devices";
import { Paper, TableBody, TableContainer, TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
    container: { height: "100%" },
    table: { marginTop: 12, boxShadow: "none" },
    link: { color: theme.palette.blue[150], cursor: "pointer", padding: 0, display: "inline-block" },
    tooltip: { display: "inline-block" },
}));

const compareOsVersions = (a: OperatingSystemWithCount, b: OperatingSystemWithCount): number => {
    if (a.count !== b.count) {
        return b.count - a.count;
    }

    return compareVersions(b.version, a.version);
};
type Props = {
    filter: number[];
};

type OperatingSystemWithCount = {
    version: string;
    count: number;
};

export const OperatingSystemsCard = ({ filter }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const groups = useSelector(selectGroups);
    const filteredGroups = filter.length > 0 ? groups.filter((group) => filter.includes(group.id)) : groups;
    const filteredGroupIds = filteredGroups.map((g) => g.id);
    const filteredDevices = useSelector((state) => selectDevicesByGroupIds(state, filteredGroupIds));

    const countOsArray: { [key: string]: number } = filteredDevices.reduce((acc, device) => {
        const version = device?.lastState?.version?.emteriaVersion || device?.lastState?.version?.androidVersion;
        if (version) {
            acc[version] = (acc[version] || 0) + 1;
        }
        return acc;
    }, {} as { [key: string]: number });

    const operatingSystemsWithCount: OperatingSystemWithCount[] = Object.entries(countOsArray).map(
        ([version, count]) => ({ version, count }),
    );

    const sortedOperatingSystemsWithCount = [...operatingSystemsWithCount].sort(compareOsVersions);

    const handleSingleDeviceClick = (system: OperatingSystemWithCount) => {
        const device = filteredDevices.filter((item) => {
            const version = item?.lastState?.version?.emteriaVersion || item?.lastState?.version?.androidVersion;
            return version === system.version;
        })[0];

        if (!device) {
            return;
        }
        const group = filteredGroups.find((item) => item.licenseIds.includes(device.licenseId));
        if (group) {
            dispatch(showLicenseById({ device, groupId: group.id }));
            dispatch(setPaneTab("SUMMARY"));
            navigate("/dashboard/devices");
        }
    };

    const handleVersionClick = (version: string) => {
        dispatch(
            updateFilterProperty({
                property: "system_version",
                values: [version],
            }),
        );
        navigate("/dashboard/devices");
    };

    return (
        <CustomTileContainer className={classes.container}>
            <CustomTileTitle>OS versions</CustomTileTitle>
            <TableContainer component={Paper} className={classes.table}>
                <MinimalTable stickyHeader>
                    <MinimalTableHeader>
                        <TableRow>
                            <MinimalTableHeaderCell>Version</MinimalTableHeaderCell>
                            <MinimalTableHeaderCell align="right">Devices</MinimalTableHeaderCell>
                        </TableRow>
                    </MinimalTableHeader>
                    <TableBody>
                        {sortedOperatingSystemsWithCount.length === 0 && (
                            <TableRow>
                                <MinimalTableCell align="center" colSpan={2}>
                                    No devices
                                </MinimalTableCell>
                            </TableRow>
                        )}
                        {sortedOperatingSystemsWithCount.map((item, index) => (
                            <TableRow key={index}>
                                <MinimalTableCell border>
                                    <span className={classes.link} onClick={() => handleVersionClick(item.version)}>
                                        {item.version}
                                    </span>
                                </MinimalTableCell>
                                {item.count === 1 ? (
                                    <MinimalTableCell
                                        border
                                        onClick={() => handleSingleDeviceClick(item)}
                                        align="right"
                                    >
                                        <Tooltip title="Show this device" className={classes.tooltip}>
                                            <span className={classes.link}>{item.count}</span>
                                        </Tooltip>
                                    </MinimalTableCell>
                                ) : (
                                    <MinimalTableCell border align="right">
                                        <span className={classes.link} onClick={() => handleVersionClick(item.version)}>
                                            {item.count}
                                        </span>
                                    </MinimalTableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </MinimalTable>
            </TableContainer>
        </CustomTileContainer>
    );
};
