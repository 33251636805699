import { FormApi } from "final-form";
import _ from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch, useSelector } from "src/store";

import { DevicesFilterTypes } from "@dashboard/devices/components/Filtering/Filters.utils";
import { Icon, IconSize } from "@dashboard/devices/components/shared";
import { selectMergedFiltersByProperty, updateFilterProperty } from "@dashboard/devices/store";
import { ReleasesFilterTypes } from "@dashboard/downloads/components/DownloadsTable/Filter/Filter.utils";
import { Box, Checkbox, Dialog, DialogProps, FormControlLabel, Typography } from "@mui/material";
import { CustomBadge } from "@shared/CustomBadge";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { CheckboxField } from "@shared/form/CheckboxField";

import { useStyles } from "./FilterDialog.style";

interface FormValues {
    [x: string]: boolean;
}
type Props = DialogProps & {
    title: string;
    options: string[];
    setOpenFilterDialog: Dispatch<SetStateAction<boolean>>;
    property: DevicesFilterTypes | ReleasesFilterTypes;
    initialSorting?: "asc" | "desc" | "none";
};

const changeAllValuesTo = (obj: Record<string, boolean>, value: boolean) => {
    return Object.fromEntries(Object.keys(obj).map((key) => [key, value]));
};

export const FilterDialog = ({
    title,
    options,
    property,
    setOpenFilterDialog,
    initialSorting = "asc",
    ...props
}: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectMergedFilters = selectMergedFiltersByProperty<typeof property>();
    const filters = useSelector((state) => selectMergedFilters(state)(property, options));

    const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "none">(initialSorting);

    const filtersArr =
        sortOrder === "none" ? Object.keys(filters) : _.orderBy(Object.keys(filters), (item) => item, sortOrder);

    const truthyFilters = changeAllValuesTo(filters, true);
    const falsyFilters = changeAllValuesTo(filters, false);

    const onSubmit = (values: FormValues) => {
        const newValues = Object.keys(values).filter((key) => values[key]);
        dispatch(
            updateFilterProperty({
                property,
                values: newValues,
            }),
        );
        setOpenFilterDialog(false);
    };

    const handleCloseButton = (form: FormApi<FormValues, Partial<FormValues>>) => {
        form.reset(filters);
        setOpenFilterDialog(false);
    };

    const toggleSortOrder = () => {
        setSortOrder((prev) => (prev === "asc" ? "desc" : "asc"));
    };

    return (
        <>
            <CustomBadge
                className={classes.filterBadge}
                badgeContent={Object.values(filters).filter((item) => item).length}
                onClick={() => setOpenFilterDialog(true)}
                overlap="rectangular"
            >
                <Icon size={IconSize.small} className={classes.tagIcon} name="fa-solid fa-filter" />
            </CustomBadge>
            <Form<FormValues>
                onSubmit={onSubmit}
                initialValues={filters}
                render={({ form, handleSubmit, values }) => {
                    return (
                        <Dialog
                            {...props}
                            onClose={(_event, reason) => reason === "backdropClick" && handleCloseButton(form)}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className={classes.container}>
                                    <div className={classes.dialogTitleBox}>
                                        <Typography variant="h6" className={classes.title}>
                                            {title}
                                        </Typography>
                                        {initialSorting !== "none" && (
                                            <div className={classes.sortButtonContainer} onClick={toggleSortOrder}>
                                                <Typography className={classes.sortText}>Sort</Typography>
                                                <i
                                                    className={`fa-solid ${
                                                        sortOrder === "asc" ? "fa-arrow-up" : "fa-arrow-down"
                                                    } ${classes.sortIcon}`}
                                                ></i>
                                            </div>
                                        )}
                                    </div>

                                    <div className={classes.content}>
                                        <div className={classes.checkboxContainer}>
                                            {filtersArr.map((item) => (
                                                <CheckboxField key={item} name={`["${item}"]`} label={item} />
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.selectManyContainer}>
                                        {Object.values(values).length ? (
                                            <FormControlLabel
                                                label={<span className={classes.informationText}>Select all</span>}
                                                control={
                                                    <Checkbox
                                                        checked={Object.values(values).every((item) => item)}
                                                        indeterminate={false}
                                                        onChange={(
                                                            _e: React.ChangeEvent<HTMLInputElement>,
                                                            checked: boolean,
                                                        ) => {
                                                            form.reset(checked ? truthyFilters : falsyFilters);
                                                        }}
                                                    />
                                                }
                                            />
                                        ) : null}
                                    </div>
                                    <Box className={classes.buttonBox}>
                                        <SecondaryButton onClick={() => handleCloseButton(form)}>
                                            Cancel
                                        </SecondaryButton>
                                        <PrimaryButton type="submit">Save</PrimaryButton>
                                    </Box>
                                </div>
                            </form>
                        </Dialog>
                    );
                }}
            />
        </>
    );
};
