import { useContext } from "react";
import { SecondaryButton } from "src/ui/shared/CustomButton";

import makeStyles from "@mui/styles/makeStyles";

import { CollapsibleGroupContext } from "./CollapsibleSection";

const useStyles = makeStyles(() => ({
    collapseBtn: {
        marginRight: 16,
    },
}));

const ExpandAllButton = () => {
    const classes = useStyles();
    const { expandAll, setExpandAll } = useContext(CollapsibleGroupContext);

    return (
        <SecondaryButton
            className={classes.collapseBtn}
            onClick={() => setExpandAll((prev) => !prev)}
            startIcon={
                expandAll ? <i className="fas fa-solid fa-minimize" /> : <i className="fas fa-solid fa-maximize" />
            }
        >
            {expandAll ? "Collapse all" : "Expand all"}
        </SecondaryButton>
    );
};

export default ExpandAllButton;
