import React from "react";
import { useNavigate } from "react-router";

import { Paper, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { PrimaryButton } from "./CustomButton";

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[3],
        width: "100%",
        maxWidth: "600px",
        minHeight: "150px",
        margin: "0 auto",
        marginTop: 20,
    },
    message: {
        marginBottom: 14,
        textAlign: "center",
        color: theme.palette.text.primary,
    },
    button: {
        marginTop: 16,
    },
}));

const FreshAccountMessage: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <Paper className={classes.container} elevation={3}>
            <Typography className={classes.message}>
                Get started by asking a collegue to invite you to their workspace, or use the button below to claim your
                free plan.
            </Typography>
            <PrimaryButton className={classes.button} onClick={() => navigate("/dashboard/overview")}>
                Start free plan now
            </PrimaryButton>
        </Paper>
    );
};

export default FreshAccountMessage;
