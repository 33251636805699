import clsx from "clsx";
import { LoadingIcon } from "src/ui/shared/Loading";
import { MinimalTableCell } from "src/ui/shared/table/MinimalTableComponents";
import { Tooltip } from "src/ui/shared/Tooltip";

import { IconSize } from "@dashboard/devices/components/shared/index";
import { FileStatus } from "@dashboard/files/types/index";
import { hasUnsuccessfulAnalyzer } from "@dashboard/files/utils";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Row } from "@tanstack/react-table";

import { FileExtendedTableItem } from "./UploadTable/UploadTable.utils";

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        fontSize: 20,
        marginLeft: 5,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    tooltipBox: {
        width: 30,
    },
}));

type IconInfo = {
    iconClass: string;
    tooltipText: string;
};

const fileExtensionToIconMap: Record<string, IconInfo> = {
    doc: { iconClass: "fa-solid fa-file-word", tooltipText: "Text" },
    docx: { iconClass: "fa-solid fa-file-word", tooltipText: "Text" },
    exe: { iconClass: "fa-solid fa-windows", tooltipText: "Windows executable" },
    zip: { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
};

const contentTypeToIconMap: Record<string, IconInfo> = {
    "image/jpeg": { iconClass: "fa-solid fa-file-image", tooltipText: "Picture" },
    "image/png": { iconClass: "fa-solid fa-file-image", tooltipText: "Picture" },
    "image/gif": { iconClass: "fa-solid fa-file-image", tooltipText: "Picture" },
    "text/plain": { iconClass: "fa-solid fa-file-lines", tooltipText: "Text" },
    "text/csv": { iconClass: "fa-solid fa-file-lines", tooltipText: "Text" },
    "application/json": { iconClass: "fa-solid fa-file-code", tooltipText: "JSON" },
    "application/zip": { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
    "multipart/x-zip": { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
    "application/zip-compressed": { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
    "application/x-zip-compressed": { iconClass: "fa-solid fa-file-zipper", tooltipText: "Archive" },
    "application/vnd.android.package-archive": { iconClass: "fa-brands fa-android", tooltipText: "Android package" },
    "application/x-apk": { iconClass: "fa-brands fa-android", tooltipText: "Android package" },
};

const getIconByFileName = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase() || "";
    return fileExtensionToIconMap[extension] || { iconClass: "fa-solid fa-file", tooltipText: "Unknown type" };
};

interface FileIconProps {
    row: Row<FileExtendedTableItem>;
}

const FileIcon = ({ row }: FileIconProps) => {
    const classes = useStyles();
    const displaySpinner = hasUnsuccessfulAnalyzer(row.original);
    const contentType = row.original.contentType ? contentTypeToIconMap[row.original.contentType] : null;
    const { iconClass, tooltipText } = contentType || getIconByFileName(row.original.filename);

    if (row.original.warnings.length) {
        return (
            <MinimalTableCell>
                <Tooltip
                    placement="bottom"
                    title={
                        <ul style={{ paddingLeft: 20 }}>
                            {row.original.warnings.map((warning) => (
                                <li style={{ marginTop: 4 }} key={warning}>
                                    {warning}
                                </li>
                            ))}
                        </ul>
                    }
                >
                    <i className={clsx("fa-solid fa-triangle-exclamation", classes.icon, classes.warning)} />
                </Tooltip>
            </MinimalTableCell>
        );
    }

    return (
        <MinimalTableCell>
            <div>
                {displaySpinner || row.original.status === FileStatus.Pending ? (
                    <Tooltip placement="top" title="Processing...">
                        <div className={classes.tooltipBox}>
                            <LoadingIcon size={IconSize.medium} />
                        </div>
                    </Tooltip>
                ) : (
                    <Tooltip placement="top" title={tooltipText}>
                        <i className={clsx(iconClass, classes.icon)} />
                    </Tooltip>
                )}
            </div>
        </MinimalTableCell>
    );
};

export default FileIcon;
