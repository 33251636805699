import clsx from "clsx";
import { diff } from "deep-diff";
import { FormApi } from "final-form";
import _ from "lodash";
import { Form } from "react-final-form";
import { RootState, useDispatch, useSelector } from "src/store";

import { updateFileMetadata, updateFileRollout } from "@files/store";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    InputAdornment,
    ModalProps,
    Theme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InputItemLabel } from "@products/components/ProductDetails/styles";
import { PrimaryButton, SecondaryButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

import { TagManagementSection } from "./TagManagementSection";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    buttonBox: {
        marginTop: 24,
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    content: { width: 700, marginTop: 50 },
    section: {
        display: "flex",
        justifyContent: "space-between",
        gap: 16,
        marginTop: 12,
    },
    centeredLabel: {
        alignItems: "center",
    },
}));

type FormValues = {
    rolloutPercentage: number;
    tags: API.Tag[];
};

type Props = Omit<DialogProps, "onClose"> & {
    item?: API.File;
    onClose: (event?: object) => void;
};

const getFormDiff = (form: FormApi<FormValues>) => {
    const rolloutPercentageChanged = form.getFieldState("rolloutPercentage")?.dirty;
    const tagsChanged = diff(
        _.keyBy(form.getState().initialValues.tags, "key"),
        _.keyBy(form.getState().values.tags, "key"),
    );
    const pristine = !rolloutPercentageChanged && !tagsChanged;

    return { pristine, rolloutPercentageChanged, tagsChanged };
};
const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};
    if (typeof values.rolloutPercentage !== "number") {
        errors.rolloutPercentage = "Rollout must be a number";
    }
    if (values.rolloutPercentage < 0 || values.rolloutPercentage > 100) {
        errors.rolloutPercentage = "Rollout must be in the range from 0 to 100";
    }
    return errors;
};

export const FileSettingsDialog = (props: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const status = useSelector((state: RootState) => state.files.status);

    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        if (!props.item) {
            return;
        }

        const { rolloutPercentageChanged, tagsChanged } = getFormDiff(form);

        try {
            if (rolloutPercentageChanged) {
                await dispatch(
                    updateFileRollout({ id: props.item.id, rolloutPercentage: +values.rolloutPercentage }),
                ).unwrap();
            }
            if (tagsChanged) {
                await dispatch(
                    updateFileMetadata({
                        fileId: props.item.id,
                        metadata: { ...props.item.metadata?.user, tags: values.tags },
                    }),
                ).unwrap();
            }

            showSuccessToast("File settings updated successfully");
            props.onClose();
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Something went wrong during updating settings");
        }
    };
    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        if (e.key === "Enter" && !getFormDiff(form).pristine && status !== "pending") {
            await form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };
    const onClose: ModalProps["onClose"] = (_e, reason) => {
        if (reason !== "backdropClick") {
            props.onClose();
        }
    };

    return (
        <Form<FormValues>
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
                rolloutPercentage: props.item?.rolloutPercentage,
                tags: props.item?.metadata?.user?.tags || [],
            }}
            render={({ handleSubmit, form, values }) => (
                <Dialog {...props} maxWidth="lg" onKeyDown={(e) => onKeyDown(e, form)} onClose={onClose}>
                    <form onSubmit={handleSubmit}>
                        <DialogTitle className={classes.header}>File settings</DialogTitle>
                        <DialogContent className={classes.content}>
                            <TagManagementSection tags={values.tags} onChange={(data) => form.change("tags", data)} />

                            <Box className={clsx(classes.section, classes.centeredLabel)}>
                                <InputItemLabel>Rollout</InputItemLabel>
                                <TextField
                                    name="rolloutPercentage"
                                    type="number"
                                    fullWidth
                                    required
                                    style={{ flex: 0.9 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                    onChange={(e) => form.change("rolloutPercentage", Number(e.target.value))}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions className={classes.buttonBox}>
                            <SecondaryButton onClick={props.onClose} disabled={status === "pending"}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                type="submit"
                                disabled={status === "pending" || getFormDiff(form).pristine}
                                loading={status === "pending"}
                            >
                                Save
                            </PrimaryButton>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        />
    );
};
