import { Form } from "react-final-form";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router";
import { register } from "src/services/accountService";

import { Typography } from "@mui/material";
import { PrimaryButton } from "@shared/CustomButton";
import { TextField } from "@shared/form/TextField";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const useStyles = createUseStyles({
    formContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 16,
    },
    titleContainer: {
        marginBottom: 20,
    },
});

type FormValues = {
    email: string;
};

const validate = (values: FormValues) => {
    const errors: Partial<FormValues> = {};

    if (!values.email) {
        errors.email = "Email is required";
    }

    return errors;
};

const RegisterForm = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const onSubmit = async (values: FormValues) => {
        try {
            const response = await register(values.email);
            if (response.status === 200) {
                const { needEmailConfirmation, id, token } = response.data.model;
                if (!needEmailConfirmation) {
                    const url =
                        "/account/confirm?id=" +
                        encodeURIComponent(id) +
                        "&token=" +
                        encodeURIComponent(token) +
                        "&email=" +
                        encodeURIComponent(values.email);
                    window.location.href = url;
                }

                if (response?.data?.message) {
                    showSuccessToast(response.data.message);
                }
                navigate("/login");
            } else if (response.status === 201) {
                window.location.href = response.headers.location;
            }
        } catch (error: unknown) {
            const message = (error as { message?: string }).message || "Something went wrong, please try again.";
            showErrorToast(message);
        }
    };

    return (
        <>
            <div className={classes.titleContainer}>
                <Typography variant="h5">Create new account</Typography>
            </div>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit, submitting }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={classes.formContainer}>
                            <TextField name="email" type="email" placeholder="Email" />
                            <PrimaryButton type="submit" disabled={submitting} fullWidth>
                                Submit
                            </PrimaryButton>
                        </div>
                    </form>
                )}
            />
        </>
    );
};

export default RegisterForm;
