import { createUseStyles } from "react-jss";
import { useDispatch } from "src/store";

import { updateFilterProperty } from "@dashboard/devices/store";
import { ReleasesFilterTypes } from "@dashboard/downloads/components/DownloadsTable/Filter/Filter.utils";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";

import { DevicesFilterTypes } from "../Filters.utils";

const useStyles = createUseStyles({
    container: { display: "flex", flexDirection: "column", gap: 4, marginRight: 32 },
    box: { display: "flex", gap: 12 },
    option: { margin: "0 !important", padding: 0 },
});

type FilterOption = {
    title: string;
    value: string;
};

export interface Props {
    title: string;
    options: FilterOption[];
    property: DevicesFilterTypes | ReleasesFilterTypes;
    values: string[];
}

const FilterItem = (props: Props): JSX.Element => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const toggleFilter = (checked: boolean, value: string) => {
        let filterValues: string[] = [];

        if (props.values) {
            filterValues = [...props.values];
            if (checked) {
                filterValues = [...new Set([...filterValues, value])];
            } else {
                filterValues.splice(filterValues.indexOf(value), 1);
            }
        }

        dispatch(
            updateFilterProperty({
                property: props.property,
                values: filterValues,
            }),
        );
    };

    return (
        <Box className={classes.container}>
            <Typography style={{ fontWeight: "bold" }}>{props.title}</Typography>
            <Box className={classes.box}>
                {props.options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        label={option.title}
                        classes={{ root: classes.option }}
                        data-testid={"filter_item_" + option.title.toLowerCase()}
                        control={
                            <Checkbox
                                size="small"
                                onChange={(e) => toggleFilter(e.target.checked, option.value)}
                                checked={props.values?.includes(option.value)}
                                style={{ padding: 0 }}
                            />
                        }
                    />
                ))}
            </Box>
        </Box>
    );
};

export default FilterItem;
