import React from "react";
import { createUseStyles } from "react-jss";
import { cancelBuild } from "src/ui/containers/dashboard/products/api";
import { LogStreamer } from "src/ui/containers/dashboard/products/components/LogStreamer/LogStreamer";
import { DangerOutlinedButton } from "src/ui/shared/CustomButton";
import { MinimalTableCell } from "src/ui/shared/table/MinimalTableComponents";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { TableRow } from "@mui/material";
import { flexRender, Row } from "@tanstack/react-table";

import { State } from "./AdminBuildsPage";

type TableItemProps = {
    setState: (value: React.SetStateAction<State>) => void;
    row: Row<API.AdminBuild>;
};

const useStyles = createUseStyles({
    actionCell: { display: "flex", gap: 4, justifyContent: "flex-end" },
});

const TableItem: React.FC<TableItemProps> = ({ row, setState }) => {
    const [isCanceling, setIsCanceling] = React.useState(false);
    const classes = useStyles();

    const handleCancel = async () => {
        try {
            setIsCanceling(true);
            await cancelBuild(row.original.buildId);
            showSuccessToast("Build canceled successfully");
            setState((prevState) => ({
                ...prevState,
                data: prevState.data.filter((item) => item.buildId !== row.original.buildId),
            }));
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message);
        } finally {
            setIsCanceling(false);
        }
    };

    return (
        <React.Fragment>
            <TableRow>
                {row
                    .getVisibleCells()
                    .map((cell) => flexRender(cell.column.columnDef.cell, { ...cell.getContext(), key: cell.id }))}
                <MinimalTableCell>
                    <div className={classes.actionCell}>
                        <LogStreamer buildId={row.original.buildId} hasLogs={row.original.hasLogs} />
                        <DangerOutlinedButton onClick={handleCancel} disabled={isCanceling} loading={isCanceling}>
                            <i className="fa-solid fa-ban" />
                        </DangerOutlinedButton>
                    </div>
                </MinimalTableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default TableItem;
