import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: { display: "flex", flexDirection: "column", gap: 20, paddingBottom: 20 },
    card: { overflow: "visible" },
    list: { paddingTop: "0 !important" },
    warningBox: {
        backgroundColor: theme.palette.yellow[50],
        display: "flex",
        gap: 10,
    },
    headerColumn: {
        display: "inline-block",
        width: "140px",
        fontWeight: 500,
        whiteSpace: "nowrap",
    },
    valueColumn: {
        paddingLeft: "1em",
    },
    capitalizedColumn: {
        display: "inline-block",
        "&::first-letter": {
            textTransform: "uppercase",
        },
    },
    header: {
        backgroundColor: theme.palette.shadow[100],
        color: theme.palette.blue[200],
    },
    headerBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    headerButtonBox: { display: "flex", gap: 10 },
    switchLabel: {
        marginBottom: 4,
        fontWeight: 600,
    },
    switchBox: { display: "flex", alignItems: "center" },
    switchContent: { display: "flex", flexDirection: "column", marginLeft: 15 },
    copyButton: {
        marginLeft: 12,
    },
    apiKeyContainer: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
    },
    apiKeyActions: {
        display: "flex",
        gap: 8,
    },
}));
