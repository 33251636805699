import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, styled, TableHead } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { flexRender, Header as TanstackHeader } from "@tanstack/react-table";

import { MinimalTableHeaderCell } from "./MinimalTableComponents";

const useStyles = makeStyles(() => ({
    pointingCursor: { cursor: "pointer" },
    defaultCursor: { cursor: "default" },
    icon: {
        position: "relative",
        top: "2px",
        fontSize: "16px",
        opacity: 1,
    },
    title: {
        fontWeight: 600,
    },
    content: {
        display: "flex",
        alignItems: "left",
        height: "100%",
        gap: 1,
        padding: 10,
        paddingLeft: 0,
    },
}));

const Header = <T,>(props: React.PropsWithChildren<TanstackHeader<T, unknown>>) => {
    const classes = useStyles();
    const canSort = props.column.getCanSort();

    return (
        <MinimalTableHeaderCell
            title=""
            key={props.id}
            colSpan={props.colSpan}
            className={canSort ? classes.pointingCursor : classes.defaultCursor}
        >
            <Box className={classes.content}>
                <span className={classes.title} onClick={props.column.getToggleSortingHandler()}>
                    {flexRender(props.column.columnDef.header, props.getContext())}
                </span>
                <span>
                    {{
                        asc: <ArrowUpwardIcon color="primary" className={classes.icon} />,
                        desc: <ArrowDownwardIcon color="primary" className={classes.icon} />,
                    }[props.column.getIsSorted() as string] ?? null}
                </span>
                {props.children}
            </Box>
        </MinimalTableHeaderCell>
    );
};

const LegacyHeader = styled(TableHead)(({ theme }) => ({
    "& > .MuiTableRow-root": {
        "& > .MuiTableCell-head": {
            backgroundColor: theme.palette.blue[50],
            color: theme.palette.black[100] + " !important",
            "& .MuiTableSortLabel-root": {
                color: theme.palette.black[100] + " !important",
                "&:hover": {
                    color: theme.palette.black[100] + " !important",
                },
                "&.MuiTableSortLabel-active": {
                    color: theme.palette.black[100] + " !important",
                },
                "& .MuiTableSortLabel-icon": {
                    color: "inherit !important",
                },
            },
        },
    },
}));

export { Header, LegacyHeader as TableHeader };
