import { Device, DeviceId, Group, License, Subscription } from "@dashboard/devices/types";
import { daysToDate } from "@dashboard/devices/utils/dates";
import { Provisioning } from "@dashboard/provisioning/types/index";
import { createEntityAdapter } from "@reduxjs/toolkit";

export const subscriptionsAdapter = createEntityAdapter<Subscription>({
    sortComparer: (a, b) => a?.name?.localeCompare(b?.name),
});

export const provisioningsAdapter = createEntityAdapter<Provisioning>({
    sortComparer: (a, b) => a?.name?.localeCompare(b?.name),
});

export const groupsAdapter = createEntityAdapter<Group>({
    sortComparer: (a, b) => a?.name?.localeCompare(b?.name),
});

export const licensesAdapter = createEntityAdapter<License>({});

export const devicesAdapter = createEntityAdapter<Device>({});

type SubscriptionsNormalized = Record<number, Subscription>;

type LicensesNormalized = Record<number, License>;

type DevicesNormalized = Record<DeviceId, Device>;

type ProvisioningsNormalized = Record<number, Provisioning>;

type NormalizedEntities = {
    subscriptions: SubscriptionsNormalized;
    licenses: LicensesNormalized;
    devices: DevicesNormalized;
    provisionings: ProvisioningsNormalized;
};

export const normalizeSubscriptions = (subscriptions: API.Subscription[]): SubscriptionsNormalized => {
    const newSubs: SubscriptionsNormalized = {};

    subscriptions.forEach((item) => {
        const subscription: Subscription = { ...item, isExpired: false };

        if (subscription?.serviceName === "CustomerService") {
            return;
        }
        if (!subscription?.name) {
            subscription.name = `${subscription?.variantName} ${subscription.chargeId}`;
        }
        if (subscription?.expirationDate) {
            subscription.isExpired = daysToDate(subscription.expirationDate) <= 0;
        }

        newSubs[subscription.id] = subscription;
    });

    return subscriptions;
};

export const normalizeLicenses = (licenses: License[]): Pick<NormalizedEntities, "licenses" | "devices"> => {
    const newLicenses: LicensesNormalized = {};
    const newDevices: DevicesNormalized = {};

    licenses.forEach((license) => {
        newLicenses[license.id] = license;
        const { device } = license;
        if (device) {
            newDevices[device.id] = device;
        }
    });

    return {
        licenses: newLicenses,
        devices: newDevices,
    };
};

export const normalizeProvisionings = (provisionings: API.Provisioning[]): ProvisioningsNormalized => {
    const normalizedProvisionings: ProvisioningsNormalized = {};

    provisionings.forEach((provisioning) => {
        normalizedProvisionings[provisioning.id] = provisioning;
    });

    return normalizedProvisionings;
};
