import clsx from "clsx";
import { diff } from "deep-diff";
import { FormApi } from "final-form";
import _ from "lodash";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import semver from "semver";
import { RootState, useDispatch } from "src/store";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { SelectField } from "src/ui/shared/form/SelectField";
import { TextareaField } from "src/ui/shared/form/TextareaField";
import { TextField } from "src/ui/shared/form/TextField";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";

import { buildVariantOptions } from "@dashboard/products/components/ProductDetails/fragments/Recipes";
import { InputItemLabel } from "@dashboard/products/components/ProductDetails/styles";
import {
    setBuildChangelog,
    updateBuildCompatibility,
    updateBuildMetadata,
    updateBuildRollout,
} from "@dashboard/products/store";
import { selectProductChangelogsById } from "@dashboard/products/store/selectors/products";
import { Build } from "@dashboard/products/types";
import { TagManagementSection } from "@dashboard/shared/components/TagManagementSection";
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    InputAdornment,
    ModalProps,
    Typography,
} from "@mui/material";

import { useStyles } from "./BuildSettingsDialog.style";
import { BuildVariantLabel } from "./shared";

type FormValues = {
    compatibilitySection: string;
    tagSection: API.Tag[];
    changeLogSection: string;
    rolloutSection: number;
    buildVariant: string;
};

const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};
    if (values.compatibilitySection) {
        if (!semver.validRange(values.compatibilitySection)) {
            errors.compatibilitySection = "Semver range format required (e.g. >=1.0.0 or =1.2.3)";
        }
    }
    if (values.rolloutSection < 0 || values.rolloutSection > 100) {
        errors.rolloutSection = "Rollout must be in the range from 0 to 100";
    }
    return errors;
};
const getFormDiff = (form: FormApi<FormValues>) => {
    const rolloutChanged = form.getFieldState("rolloutSection")?.dirty;
    const compatibilityChanged = form.getFieldState("compatibilitySection")?.dirty;
    const changeLogChanged = form.getFieldState("changeLogSection")?.dirty;
    const tagsChanged = diff(
        _.keyBy(form.getState().initialValues.tagSection, "key"),
        _.keyBy(form.getState().values.tagSection, "key"),
    );
    const pristine = !compatibilityChanged && !changeLogChanged && !tagsChanged && !rolloutChanged;

    return { compatibilityChanged, changeLogChanged, tagsChanged, rolloutChanged, pristine };
};

type Props = Omit<DialogProps, "onClose"> & {
    build: Build;
    onClose: (event?: object) => void;
};

const getBuildVariant = (build: Build): string => {
    if (build.configOverride && "buildVariant" in build.configOverride) {
        return build.configOverride.buildVariant as string;
    }

    if (build.configSnapshot && build.configSnapshot?.buildVariant) {
        return build.configSnapshot.buildVariant;
    }

    return "-";
};

export const BuildSettingsDialog = ({ build, ...props }: Props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const changeLog = useSelector((state: RootState) => selectProductChangelogsById(state, build.productId));
    const fullBuildFile = build.files.find((item) => item.metadata?.updateType === "full");
    const fullBuildFileCompatibility = fullBuildFile?.metadata?.compatibility;
    const compatibility = build.compatibility || fullBuildFileCompatibility;
    const initialValues: FormValues = {
        compatibilitySection: compatibility || "",
        changeLogSection: changeLog?.[build.version]?.join("\n") || "",
        tagSection: build.metadata?.user?.tags || [],
        rolloutSection: build.rolloutPercentage,
        buildVariant: getBuildVariant(build),
    };

    const onKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>, form: FormApi<FormValues>) => {
        const { submitting, active } = form.getState();
        const { pristine } = getFormDiff(form);

        if (e.key === "Enter" && !pristine && !submitting && !active) {
            await form.submit();
        }
        if (e.key === "Escape") {
            props.onClose();
        }
    };
    const onSubmit = async (values: FormValues, form: FormApi<FormValues>) => {
        const { compatibilityChanged, changeLogChanged, tagsChanged, rolloutChanged } = getFormDiff(form);

        try {
            if (compatibilityChanged) {
                await dispatch(
                    updateBuildCompatibility({
                        productId: build.productId,
                        buildId: build.buildId,
                        compatibility: values.compatibilitySection,
                    }),
                ).unwrap();
            }
            if (changeLogChanged) {
                await dispatch(
                    setBuildChangelog({
                        productId: build.productId,
                        version: build.version,
                        changes: values.changeLogSection.split("\n").filter((item) => item.trim() !== ""),
                    }),
                ).unwrap();
            }
            if (tagsChanged) {
                await dispatch(
                    updateBuildMetadata({
                        productId: build.productId,
                        buildId: build.buildId,
                        metadata: { ...build.metadata?.user, tags: values.tagSection },
                    }),
                ).unwrap();
            }
            if (rolloutChanged) {
                await dispatch(
                    updateBuildRollout({
                        productId: build.productId,
                        buildId: build.buildId,
                        rolloutPercentage: +values.rolloutSection,
                    }),
                ).unwrap();
            }

            showSuccessToast("Build settings updated successfully");
            props.onClose();
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Something went wrong during updating settings");
        }
    };
    const onClose: ModalProps["onClose"] = (_e, reason) => {
        if (reason !== "backdropClick") {
            props.onClose();
        }
    };

    return (
        <Form<FormValues>
            {...{ initialValues, validate, onSubmit }}
            render={({ handleSubmit, submitting, valid, form, values }) => (
                <Dialog {...props} maxWidth="lg" onKeyDown={(e) => onKeyDown(e, form)} onClose={onClose}>
                    <DialogTitle className={classes.header}>Build settings</DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <DialogContent style={{ width: 700 }}>
                            <Box style={{ marginBottom: 12 }}>
                                <TagManagementSection
                                    tags={values.tagSection}
                                    onChange={(data) => form.change("tagSection", data)}
                                />
                            </Box>
                            <Box className={clsx(classes.section, classes.centeredLabel)}>
                                <InputItemLabel>Compatibility</InputItemLabel>
                                <TextField
                                    style={{ flex: 0.9 }}
                                    name="compatibilitySection"
                                    fullWidth
                                    placeholder="Compatibility version (e.g., >=1.0.0, >2.0, =1.2.3)"
                                />
                            </Box>
                            <Box className={classes.section}>
                                <InputItemLabel>Changes</InputItemLabel>
                                <TextareaField
                                    name="changeLogSection"
                                    style={{ flex: 1, fontSize: 16 }}
                                    formControlProps={{ style: { flex: 0.9 } }}
                                    minRows={4}
                                    placeholder={
                                        "- Fixed bug in network module\n- Improved battery performance\n- Added support for new hardware"
                                    }
                                />
                            </Box>
                            <Box className={classes.section}>
                                <InputItemLabel>Rollout</InputItemLabel>
                                <TextField
                                    name="rolloutSection"
                                    type="number"
                                    fullWidth
                                    required
                                    style={{ flex: 0.9 }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    }}
                                />
                            </Box>
                            <Box className={clsx(classes.section, classes.centeredLabel)}>
                                <BuildVariantLabel />
                                <SelectField
                                    fullWidth
                                    style={{ flex: 0.9 }}
                                    disabled={true}
                                    name="buildVariant"
                                    options={buildVariantOptions}
                                />
                            </Box>
                            <div className={classes.hints}>
                                <Typography className={classes.hintText}>
                                    Build variant cannot be changed once the build has been created.
                                </Typography>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.buttonBox}>
                            <SecondaryButton onClick={props.onClose} disabled={submitting}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                type="submit"
                                disabled={submitting || !valid || getFormDiff(form).pristine}
                                loading={submitting}
                                tooltipProps={{ title: !valid ? "You have validation issues" : "" }}
                            >
                                Save
                            </PrimaryButton>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        />
    );
};
