import { AxiosResponse } from "axios";
import httpService from "src/services/httpService";
import RouteService from "src/services/routeService";
import { ListResponse } from "src/types";

import { unpackError } from "@dashboard/devices/api/helpers";

interface FetchAllGroupsPayload {
    ids: number[];
    userEmail: string;
}
export const fetchAllGroups = async (payload?: Partial<FetchAllGroupsPayload>) => {
    try {
        const endpoint = await RouteService.getGroupsRoute();
        const { data } = await httpService.get<string, AxiosResponse<ListResponse<API.Group>>>(endpoint + "all", {
            data: "",
            headers: { "content-type": "application/json" },
            params: payload,
        });
        return data.list;
    } catch (error) {
        throw unpackError(error);
    }
};
interface FetchAllWorkspacesPayload {
    fromExpirationDate?: string;
    toExpirationDate?: string;
    includeTerminated?: boolean;
    billingType?: "manual" | "paypal" | "stripe";
}

export const fetchWorkspacesAll = async (payload?: FetchAllWorkspacesPayload) => {
    try {
        const endpoint = await RouteService.getWorkspacesRoute();
        const { data } = await httpService.get<ListResponse<API.Workspace>>(endpoint + "all", {
            headers: { "content-type": "application/json" },
            params: payload || {},
        });
        return data.list;
    } catch (error) {
        throw unpackError(error);
    }
};

export interface FetchAllFilesPayload {
    fileName: string;
    workspaceId: number;
    pageIndex: number;
    pageSize: number;
    sortCriteria: "filename" | "fileSize" | "modifiedDate" | "workspaceId" | "groupId" | "size" | "ownerId";
    sortDirection: "asc" | "desc";
}

export const fetchAllFiles = async (payload?: Partial<FetchAllFilesPayload>) => {
    try {
        const endpoint = await RouteService.getStorageRoute();
        const response: AxiosResponse<ListResponse<API.File>> = await httpService.get(endpoint + "files/all", {
            params: {
                Filename: payload?.fileName,
                WorkspaceId: payload?.workspaceId,
                PageIndex: payload?.pageIndex,
                PageSize: payload?.pageSize,
                SortCriteria: payload?.sortCriteria,
                SortDirection: payload?.sortDirection,
            },
        });
        return response.data;
    } catch (error) {
        throw unpackError(error);
    }
};
export const fetchAllBuilds = async () => {
    try {
        const endpoint = await RouteService.getProductRoute();
        const response: AxiosResponse<ListResponse<API.AdminBuild>> = await httpService.get(endpoint + "builds/queued");
        return response.data;
    } catch (error) {
        throw unpackError(error);
    }
};
