import { useDispatch } from "src/store";
import { showErrorToast, showSuccessToast } from "src/ui/shared/toasts/Toasts";
import * as yup from "yup";

import { updateProduct } from "@dashboard/products/store";

import { productSchema } from "./product.validation";
import ProductEditor from "./ProductEditor/ProductEditor";

import type { Product } from "@dashboard/products/types";
interface Props {
    product: Product;
    isEditorOpen: boolean;
    toggleDialog: VoidFunction;
}

export const EditDialog = ({ product, isEditorOpen, toggleDialog }: Props): JSX.Element => {
    const dispatch = useDispatch();

    const onSubmit = async (values: Product) => {
        try {
            await dispatch(updateProduct({ ...values, codename: product.codename })).unwrap();
            showSuccessToast("Product saved");
            toggleDialog();
        } catch (error) {
            const err = error as Error;
            showErrorToast(err.message || "Error while editing product");
            toggleDialog();
        }
    };

    const onValidate = async (values: Product) => {
        const errors: Partial<Record<keyof Product, string>> = {};
        try {
            await productSchema.validate(values);
        } catch (err) {
            if (err instanceof yup.ValidationError && err.path) {
                errors[err.path as keyof Product] = err.message;
            }
        }
        return errors;
    };

    return (
        <ProductEditor
            editing
            isEditorOpen={isEditorOpen}
            product={product}
            onConfirm={onSubmit}
            onClose={toggleDialog}
            onValidate={onValidate}
        />
    );
};
