import { useRef, useState } from "react";

import { Dropdown, DropdownList, DropdownTrigger } from "@devices/components/shared";

type Props = React.PropsWithChildren<{ disableClickAway?: boolean; displayLeft?: boolean }>;

export default function MenuContainer({ children, disableClickAway = false, displayLeft = false }: Props): JSX.Element {
    const [editMenuOpen, setEditMenuOpen] = useState(false);
    const anchorRef = useRef(null);

    return (
        <Dropdown>
            <DropdownTrigger
                onClick={() => setEditMenuOpen(!editMenuOpen)}
                anchorRef={anchorRef}
                data-testid="menu-trigger"
            >
                <i className="fas fa-ellipsis-v" />
            </DropdownTrigger>
            <DropdownList
                isOpen={editMenuOpen}
                onClickAway={disableClickAway ? () => null : () => setEditMenuOpen(false)}
                anchorRef={anchorRef}
                displayLeft={displayLeft}
            >
                {children}
            </DropdownList>
        </Dropdown>
    );
}
