import * as Yup from "yup";

export type FormValues = {
    ["android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME"]: string;
    ["android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_CHECKSUM"]: string;
    ["android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION"]: string;
    ["android.app.extra.PROVISIONING_SKIP_ENCRYPTION"]: boolean;
    ["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]: { ["UL"]: string } | undefined;
    ["android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED"]: boolean;
    ["android.app.extra.PROVISIONING_WIFI_SSID"]: string | undefined;
    ["android.app.extra.PROVISIONING_WIFI_PASSWORD"]: string | undefined;
    ["android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE"]: (typeof securityTypeOptions)[number]["value"] | undefined;
};

export const initialValues: FormValues = {
    ["android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME"]: "com.emteria.mdm/.MdmAdmin",
    ["android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_CHECKSUM"]: "jrvEXVtwzrwJcJBulYR7WYS1pjAzLnzBZsULiMb7X5w=",
    ["android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION"]:
        "https://apps.emteria.com/fdroid/emteria/repo/EmteriaMdm-v40300000.apk",
    ["android.app.extra.PROVISIONING_SKIP_ENCRYPTION"]: true,
    ["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]: undefined,
    ["android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED"]: false,
    ["android.app.extra.PROVISIONING_WIFI_SSID"]: undefined,
    ["android.app.extra.PROVISIONING_WIFI_PASSWORD"]: undefined,
    ["android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE"]: undefined,
};

export const schema = Yup.object({
    ["android.app.extra.PROVISIONING_SKIP_ENCRYPTION"]: Yup.boolean().label("Encryption"),
    ["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]: Yup.object().required().label("Universal license"),
    ["android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED"]: Yup.boolean().label("System applications"),
    ["android.app.extra.PROVISIONING_WIFI_SSID"]: Yup.string().required().label("WIFI SSID"),
    ["android.app.extra.PROVISIONING_WIFI_PASSWORD"]: Yup.string().required().label("WIFI password"),
    ["android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE"]: Yup.string().label("Security type"),
});

export const qrCodeSize = 300;
export const qrCodeLevel = "L";
export const qrCodeBorder = 0;

export const noUniversalLicenses = [{ label: "No universal licenses", value: "No universal licenses" }];
export const encryptionOptions = [
    { label: "Skip encryption", value: 1 },
    { label: "Allow encryption", value: 0 },
];
export const activationOptions = [
    { label: "Skip activation", value: 0 },
    { label: "Activate the device", value: 1 },
];
export const systemAppsOptions = [
    { label: "Disable system apps", value: 0 },
    { label: "Keep system apps", value: 1 },
];
export const wifiOptions = [
    { label: "Setup manually on the device", value: 0 },
    { label: "Setup now", value: 1 },
];
export const versionMDM = [
    {
        label: "v20006000",
        value: "v20006000",
        checksum: "SivAG2nahPq3xCh4m2rVNWevyxt1xlf_0mYO_T1QNI8",
        url: "https://apps.emteria.com/fdroid/emteria/repo/EmteriaMdm-v20006000.apk",
    },
    {
        label: "v40300000",
        value: "v40300000",
        checksum: "jrvEXVtwzrwJcJBulYR7WYS1pjAzLnzBZsULiMb7X5w=",
        url: "https://apps.emteria.com/fdroid/emteria/repo/EmteriaMdm-v40300000.apk",
    },
];
export const securityTypeOptions = [{ label: "WPA", value: "WPA" }];
