import { LocalStorage } from "src/services/localStorageService";

type ColumnName =
    | "id"
    | "status"
    | "onlineStatus"
    | "policyStatus"
    | "licenseStatus"
    | "name"
    | "comment"
    | "groupName"
    | "codename"
    | "serial"
    | "memoryTotal"
    | "memoryAvailable"
    | "memoryLow"
    | "batteryLevel"
    | "batteryVoltage"
    | "batteryPlugged"
    | "batteryStatus"
    | "batteryHealth"
    | "displaySize"
    | "displayRotation"
    | "displayDensity"
    | "systemVersion"
    | "systemUptime"
    | "systemTime"
    | "systemTimezone"
    | "wifiNetwork"
    | "onlineSince"
    | "ipv4"
    | "ipv6"
    | "code"
    | "moveLicenseCheckbox";

export const setColumnVisibility = (columnName: ColumnName) => {
    const currentVisibleColumns = LocalStorage.getItem<Record<string, boolean>>("devicesPageVisibleColumns") || {};

    const newVisibilityState = {
        ...currentVisibleColumns,
        [columnName]: true,
    };

    LocalStorage.setItem("devicesPageVisibleColumns", newVisibilityState);

    return newVisibilityState;
};
