import fileSize from "filesize";
import _ from "lodash";
import { useMemo, useState } from "react";
import { getEnv } from "src/services/configService";
import { useDispatch, useSelector } from "src/store";
import { LoadingIcon } from "src/ui/shared/Loading";

import { IconSize } from "@dashboard/devices/components/shared";
import useFetchInterval from "@dashboard/devices/hooks/useFetchInterval";
import { fetchFiles } from "@dashboard/files/store";
import { selectFiles, selectFilesListStatus } from "@dashboard/files/store/selectors";
import { hasUnsuccessfulAnalyzer } from "@dashboard/files/utils";
import { FileSettingsDialog } from "@dashboard/shared/components/FileSettingsDialog";
import { Paper, TableBody, TableContainer, TableRow } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { makeStyles } from "@mui/styles";
import {
    MinimalTable,
    MinimalTableCell,
    MinimalTableCellWithTooltip,
    MinimalTableHeader,
    MinimalTableHeaderCell,
} from "@shared/table/MinimalTableComponents";
import { Header } from "@shared/table/TableHeader";
import {
    createColumnHelper,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import FileIcon from "../FileIcon";
import { TableItem, TagsCell, VisibilityChangeCell } from "../TableItem/TableItem";
import { getLastTableRowStyle } from "../TableItem/TableItem.style";
import { TagFilterDialog } from "../TagFilterDialog/TagFilterDialog";
import {
    FileExtendedTableItem,
    getAnalyzedFiles,
    getFilteredFiles,
    useTransformFilesToTableData,
} from "./UploadTable.utils";

const columnHelper = createColumnHelper<FileExtendedTableItem>();
const tableColumns = [
    columnHelper.accessor("contentType", {
        header: "Type",
        cell: (info) => <FileIcon row={info.row} />,
        enableSorting: false,
    }),
    columnHelper.accessor("filename", {
        header: "Name",
        cell: (info) => (
            <MinimalTableCellWithTooltip
                maxLength={10}
                style={{ maxWidth: 200 }}
                title={info.getValue()}
                testid="file-name-cell"
            >
                {info.getValue()}
            </MinimalTableCellWithTooltip>
        ),
        enableSorting: true,
    }),
    columnHelper.accessor((row) => fileSize(row.size || 0) ?? "-", {
        id: "size",
        header: "Size",
        cell: (info) => (
            <MinimalTableCellWithTooltip
                maxLength={10}
                title={info.getValue()}
                testid="file-size-cell"
                style={{ minWidth: 100 }}
            >
                {info.getValue()}
            </MinimalTableCellWithTooltip>
        ),
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
            if (!rowA.original.size || !rowB.original.size) {
                return 0;
            }
            const sizeA = rowA.original.size;
            const sizeB = rowB.original.size;
            return sizeA - sizeB;
        },
    }),
    columnHelper.accessor(
        (row) => {
            if (row.formattedCreatedDate) {
                return row.formattedCreatedDate;
            }
            return { displayValue: "", tooltipTitle: "" };
        },
        {
            header: "Created",
            enableSorting: true,
            cell: (info) => (
                <MinimalTableCellWithTooltip
                    maxLength={20}
                    title={info.getValue().tooltipTitle}
                    testid="file-created-cell"
                >
                    {info.getValue().displayValue}
                </MinimalTableCellWithTooltip>
            ),
            sortingFn: (rowA, rowB) => {
                const dateA = rowA.original.formattedCreatedDate?.tooltipTitle || "";
                const dateB = rowB.original.formattedCreatedDate?.tooltipTitle || "";
                return dateA.localeCompare(dateB);
            },
        },
    ),
    columnHelper.accessor("visibility", {
        header: "Visibility",
        enableSorting: true,
        cell: (info) => <VisibilityChangeCell {...info} />,
    }),
    columnHelper.display({
        id: "tags",
        header: "Tags",
        cell: (info) => <TagsCell row={info.row} />,
        meta: { filterVariant: "filesTags" },
    }),
];

type Header = (typeof HEADERS)[number];

export const HEADERS = [
    { id: "contentType", label: "Type" },
    { id: "filename", label: "Name" },
    { id: "size", label: "Size" },
    { id: "createdDate", label: "Created" },
    { id: "visibility", label: "Visibility" },
    { id: "tags", label: "Tags" },
    { id: "actions", label: "Actions" },
] as const;

const useStyles = makeStyles({
    container: {
        marginBottom: 5,
    },
    loadingContainer: {
        height: "50px",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    noFilesBox: {
        fontSize: 22,
        marginTop: 40,
        textAlign: "center",
    },
});

const UploadTable = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);

    const status = useSelector(selectFilesListStatus);

    const files = useSelector(selectFiles);
    const data = useTransformFilesToTableData(files);

    const tags = _.compact(_.flatMap(files, (file) => file.metadata?.user?.tags));
    const filterableTags = _.mapValues(_.keyBy(tags, "key"), () => false);
    const [filters, setFilters] = useState(filterableTags);

    const filteredFiles = getFilteredFiles(data, filters);
    const analyzedFiles = useMemo(() => getAnalyzedFiles(filteredFiles), [filteredFiles]);

    const table = useReactTable<FileExtendedTableItem>({
        data: analyzedFiles,
        columns: tableColumns,
        initialState: {
            sorting: [{ id: "filename", desc: true }],
            pagination: {
                pageIndex: 0,
                pageSize: 50,
            },
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    const totalRows = table.getFilteredRowModel().rows.length;
    const currentPageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize;

    const reFetchFiles = async () => {
        try {
            await dispatch(fetchFiles());
        } catch (err) {
            if (getEnv() === "Development") {
                console.error("[Files] reFetchFiles failed: ", err);
            }
        }
    };

    useFetchInterval(reFetchFiles, { condition: hasUnsuccessfulAnalyzer(files) });

    return (
        <>
            <TableContainer className={classes.container} component={Paper}>
                <MinimalTable>
                    <MinimalTableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Header key={header.id} {...header}>
                                        {header.column.columnDef.meta?.filterVariant === "filesTags" ? (
                                            <TagFilterDialog
                                                filters={filters}
                                                setFilters={setFilters}
                                                setOpenFilterDialog={setOpenFilterDialog}
                                                open={openFilterDialog}
                                            />
                                        ) : null}
                                    </Header>
                                ))}
                                <MinimalTableHeaderCell align="right">Actions</MinimalTableHeaderCell>
                            </TableRow>
                        ))}
                    </MinimalTableHeader>
                    <TableBody>
                        {status === "pending" && !table.getRowModel().rows.length ? (
                            <TableRow>
                                <MinimalTableCell align="center" colSpan={tableColumns.length}>
                                    <div className={classes.loadingContainer}>
                                        <LoadingIcon size={IconSize.large} />
                                    </div>
                                </MinimalTableCell>
                            </TableRow>
                        ) : null}
                        {status !== "pending" && !table.getRowModel().rows.length ? (
                            <TableRow>
                                <MinimalTableCell align="center" colSpan={tableColumns.length}>
                                    There are no files yet.
                                </MinimalTableCell>
                            </TableRow>
                        ) : null}
                        {table.getRowModel().rows.map((row, index) => (
                            <TableItem
                                key={row.id}
                                row={row}
                                setSelectedFileId={setSelectedFileId}
                                style={getLastTableRowStyle(index, table.getRowModel().rows.length)}
                            />
                        ))}
                    </TableBody>
                </MinimalTable>
            </TableContainer>
            <TablePagination
                component="div"
                rowsPerPageOptions={[10, 20, 50]}
                count={totalRows}
                rowsPerPage={pageSize}
                page={currentPageIndex}
                onPageChange={(_event, page) => table.setPageIndex(page)}
                onRowsPerPageChange={(e) => {
                    const size = e.target.value ? Number(e.target.value) : 10;
                    table.setPagination({ pageIndex: 0, pageSize: size });
                }}
            />
            {selectedFileId ? (
                <FileSettingsDialog
                    open
                    onClose={() => setSelectedFileId(null)}
                    item={filteredFiles.filter((item) => item.id === selectedFileId)[0]}
                />
            ) : null}
        </>
    );
};

export default UploadTable;
