import { theme as MuiTheme } from "src/providers/ThemeProvider";

import { styled, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const deleteBorder = (index: number, isOpen: number) => (isOpen === index ? { borderBottom: "none" } : {});

export const alternatingBg = (index: number) =>
    index % 2 === 0
        ? { backgroundColor: MuiTheme.palette.white[50] }
        : { backgroundColor: MuiTheme.palette.shadow[100] };

export const useStyles = makeStyles((theme: Theme) => ({
    sectionHeader: {
        fontSize: 18,
        lineHeight: "23.94px",
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: "red",
        margin: 0,
        flexShrink: 0,
    },

    tableHeader: {
        "& .MuiTableCell-head": {
            fontWeight: 600,
        },
    },
    addButton: {
        paddingBottom: "7px",
    },

    gridContainer: {
        paddingLeft: "15px",
    },

    input: {
        height: 40,
        flex: 0.7,
    },
    hints: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        gap: theme.spacing(0.5),
    },

    hintsStart: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: theme.spacing(0.5),
    },

    switchButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    select: {
        "& .MuiInputBase-root": {
            height: "40px",
        },
    },
    categoryTitle: {
        fontSize: 22,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

export const SectionContainer = styled("div")(({ theme }) => ({
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: theme.spacing(4),
}));

export const SectionHeader = styled(Typography)({
    fontSize: 20,
    lineHeight: "23.94px",
    padding: 10,
    paddingLeft: 15,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    margin: 0,
    flexShrink: 0,
    width: "100%",
});

export const SectionDescription = styled(Typography)({
    fontSize: 16,
    paddingTop: 10,
    paddingLeft: 15,
    paddingBottom: 10,
    margin: 0,
    flexShrink: 0,
});

export const Row = styled("div")({
    display: "flex",
    gap: 8,
    paddingBottom: 8,
});

export const PageContainer = styled("div")(({ theme }) => ({
    maxWidth: 1440,
    margin: "0 auto",
    padding: "16px 32px",
    flexGrow: 1,
    backgroundColor: theme.palette.shadow[50],

    [theme.breakpoints.up(1920)]: {
        maxWidth: 1700,
    },
}));

export const ContentContainer = styled("div")(({ theme }) => ({
    marginTop: 24,
    padding: 24,
    borderRadius: 6,
    backgroundColor: theme.palette.white[50],
    border: "1px solid " + theme.palette.black[50],
}));

export const ContentContainerTitle = styled(Typography)({
    fontSize: 18,
    fontWeight: "bold",
    lineHeight: "100%",
});

export const ContentContainerSubTitle = styled(Typography)({
    marginTop: 8,
    marginBottom: 16,
});

export const DialogTitle = styled(Typography)({
    fontWeight: "bold",
    fontSize: 18,
});

export const DialogSubTitle = styled(Typography)({
    marginTop: 8,
    marginBottom: 16,
});
