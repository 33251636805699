import { ListStatus } from "@dashboard/devices/store";
import {
    cancelBuild as apiCancelBuild,
    createBuild as apiCreateBuild,
    createProduct as apiCreateProduct,
    deleteBuild as apiDeleteBuild,
    deleteProduct as apiDeleteProduct,
    deleteProductChangelog as apiDeleteProductChangelog,
    fetchFileDownloadLinks as apiFetchFileDownload,
    fetchProductBuilds as apiFetchProductBuilds,
    fetchProductChangeLogs as apiFetchProductChangeLogs,
    fetchProducts as apiFetchProducts,
    setBuildChanges as apiSetBuildChanges,
    updateBuildChannel as apiUpdateBuildChannel,
    updateBuildCompatibility as apiUpdateBuildCompatibility,
    updateBuildMetadata as apiUpdateBuildMetadata,
    updateBuildRollout as apiUpdateBuildRollout,
    updateProduct as apiUpdateProduct,
} from "@dashboard/products/api";
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";

import { Build, Product } from "../types";

export const productsAdapter = createEntityAdapter<Product>({
    selectId: (product) => product.id,
});
export const buildsAdapter = createEntityAdapter<Build>({
    selectId: (build) => build.buildId,
    sortComparer: (a, b) => (new Date(b.created) > new Date(a.created) ? 1 : -1),
});

export const fetchProducts = createAsyncThunk("products/fetchProducts", apiFetchProducts);
export const fetchProductBuilds = createAsyncThunk("products/fetchProductBuilds", apiFetchProductBuilds);
export const fetchProductChangelog = createAsyncThunk("products/fetchProductChangelog", apiFetchProductChangeLogs);
export const setBuildChangelog = createAsyncThunk("products/setBuildChangelog", apiSetBuildChanges);
export const deleteProductChangelog = createAsyncThunk("products/deleteProductChangeLog", apiDeleteProductChangelog);
const refetchProducts = createAsyncThunk("products/refetchProducts", apiFetchProducts);
export const createProduct = createAsyncThunk("products/createProduct", apiCreateProduct);
export const updateProduct = createAsyncThunk("products/updateProduct", apiUpdateProduct);
export const deleteProduct = createAsyncThunk("products/deleteProduct", apiDeleteProduct);

export const createBuild = createAsyncThunk("products/createBuild", apiCreateBuild);
export const cancelBuild = createAsyncThunk("products/cancelBuild", apiCancelBuild);
export const deleteBuild = createAsyncThunk("products/deleteBuild", apiDeleteBuild);
export const updateBuildMetadata = createAsyncThunk("products/updateBuildMetadata", apiUpdateBuildMetadata);
export const updateBuildChannel = createAsyncThunk("products/updateBuildChannel", apiUpdateBuildChannel);
export const updateBuildRollout = createAsyncThunk("products/updateBuildRollout", apiUpdateBuildRollout);
export const updateBuildCompatibility = createAsyncThunk(
    "products/updateBuildCompatibility",
    apiUpdateBuildCompatibility,
);

export const fetchFileDownloadLinks = createAsyncThunk("products/fetchFileDownload", apiFetchFileDownload);

type ProductState = {
    list: {
        status: ListStatus | null;
        products: {
            items: EntityState<Product>;
        };
        builds: {
            status: ListStatus | null;
            items: EntityState<Build>;
        };
    };
    error: null | unknown;
    useImport: boolean;
};

const initialState: ProductState = {
    list: {
        status: null,
        products: {
            items: productsAdapter.getInitialState(),
        },
        builds: {
            status: null,
            items: buildsAdapter.getInitialState(),
        },
    },
    error: null,
    useImport: false,
};

const slice = createSlice({
    name: "products",
    initialState,
    reducers: {
        setUseImport: (state, { payload = false }) => {
            state.useImport = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.list.status = "pending";
            })
            .addCase(fetchProducts.rejected, (state) => {
                state.list.status = "rejected";
            })
            .addCase(fetchProducts.fulfilled, (state, { payload }) => {
                state.list.status = "fulfilled";
                const { products, builds } = payload;
                productsAdapter.setAll(state.list.products.items, products);
                buildsAdapter.setAll(state.list.builds.items, builds);
            })
            .addCase(fetchProductBuilds.fulfilled, (state, { payload }) => {
                state.list.builds.status = "fulfilled";
                buildsAdapter.upsertMany(state.list.builds.items, payload);
            })
            .addCase(fetchProductChangelog.fulfilled, (state, { payload, meta }) => {
                state.list.status = "fulfilled";
                const productId = meta.arg.id;
                const product = state.list.products.items.entities[productId];
                if (product) {
                    product.changes = payload;
                }
            })
            .addCase(setBuildChangelog.fulfilled, (state, { payload, meta }) => {
                const productId = meta.arg.productId;
                const product = state.list.products.items.entities[productId];
                if (product) {
                    product.changes = payload;
                }
            })
            .addCase(refetchProducts.fulfilled, (state, { payload }) => {
                state.list.status = "fulfilled";

                const { products, builds } = payload;
                productsAdapter.updateMany(
                    state.list.products.items,
                    products.map((product) => ({
                        id: product.id,
                        changes: product,
                    })),
                );
                buildsAdapter.updateMany(
                    state.list.builds.items,
                    builds.map((build) => ({
                        id: build.buildId,
                        changes: build,
                    })),
                );
            })
            .addCase(fetchFileDownloadLinks.fulfilled, (state, { meta, payload }) => {
                state.list.status = "fulfilled";

                const buildId = meta.arg.buildId;

                buildsAdapter.updateOne(state.list.builds.items, {
                    id: buildId,
                    changes: {
                        files: payload.files,
                    },
                });
            })
            .addCase(createProduct.fulfilled, (state, { payload }) => {
                const product = payload;
                productsAdapter.addOne(state.list.products.items, product);
            })
            .addCase(updateProduct.fulfilled, (state, { payload }) => {
                const product = payload;
                productsAdapter.updateOne(state.list.products.items, {
                    id: product.id,
                    changes: {
                        ...product,
                    },
                });
            })
            .addCase(deleteProductChangelog.fulfilled, (state, { payload, meta }) => {
                const { productId } = meta.arg;
                const product = state.list.products.items.entities[productId];

                if (product) {
                    product.changes = payload;
                }
            })
            .addCase(deleteProduct.fulfilled, (state, { meta }) => {
                const id = meta.arg;
                const product = productsAdapter
                    .getSelectors()
                    .selectAll(state.list.products.items)
                    ?.find((_product) => _product.id === id);

                if (product) {
                    productsAdapter.removeOne(state.list.products.items, product.id);
                }
            })
            .addCase(createBuild.fulfilled, (state, { payload, meta }) => {
                const build = payload;

                if (!build?.productId) {
                    const { arg: item } = meta;
                    const product = productsAdapter
                        .getSelectors()
                        .selectAll(state.list.products.items)
                        ?.find((_product) => _product.id === item.productId);

                    if (product) {
                        build.productId = product?.id;
                    }
                }
                buildsAdapter.addOne(state.list.builds.items, {
                    ...build,
                    buildStatus: build.buildStatus ?? "pending",
                });
            })
            .addCase(cancelBuild.fulfilled, (state, { payload }) => {
                const buildId = payload;
                buildsAdapter.updateOne(state.list.builds.items, {
                    id: buildId,
                    changes: {
                        buildStatus: "canceled",
                    },
                });
            })
            .addCase(deleteBuild.fulfilled, (state, { payload }) => {
                buildsAdapter.removeOne(state.list.builds.items, payload);
            })
            .addCase(updateBuildRollout.rejected, (state) => {
                state.list.builds.status = "rejected";
            })
            .addCase(updateBuildRollout.pending, (state) => {
                state.list.builds.status = "pending";
            })
            .addCase(updateBuildRollout.fulfilled, (state, { payload }) => {
                state.list.builds.status = "fulfilled";

                buildsAdapter.updateOne(state.list.builds.items, {
                    id: payload.buildId,
                    changes: {
                        rolloutPercentage: payload.rolloutPercentage,
                    },
                });
            })
            .addCase(updateBuildMetadata.rejected, (state) => {
                state.list.builds.status = "rejected";
            })
            .addCase(updateBuildMetadata.pending, (state) => {
                state.list.builds.status = "pending";
            })
            .addCase(updateBuildMetadata.fulfilled, (state, { payload }) => {
                state.list.builds.status = "fulfilled";

                const build = buildsAdapter
                    .getSelectors()
                    .selectAll(state.list.builds.items)
                    .find((s) => s.buildId === payload.buildId);

                buildsAdapter.updateOne(state.list.builds.items, {
                    id: payload.buildId,
                    changes: {
                        metadata: {
                            ...build?.metadata,
                            user: payload.metadata,
                        },
                    },
                });
            })
            .addCase(updateBuildChannel.rejected, (state) => {
                state.list.builds.status = "rejected";
            })
            .addCase(updateBuildChannel.pending, (state) => {
                state.list.builds.status = "pending";
            })
            .addCase(updateBuildChannel.fulfilled, (state, { payload }) => {
                state.list.builds.status = "fulfilled";

                buildsAdapter.updateOne(state.list.builds.items, {
                    id: payload.buildId,
                    changes: {
                        channel: payload.channel,
                    },
                });
            })
            .addCase(updateBuildCompatibility.rejected, (state) => {
                state.list.builds.status = "rejected";
            })
            .addCase(updateBuildCompatibility.pending, (state) => {
                state.list.builds.status = "pending";
            })
            .addCase(updateBuildCompatibility.fulfilled, (state, { payload }) => {
                state.list.builds.status = "fulfilled";

                buildsAdapter.updateOne(state.list.builds.items, {
                    id: payload.buildId,
                    changes: {
                        compatibility: payload.compatibility,
                    },
                });
            });
    },
});

export default slice.reducer;
