import clsx from "clsx";
import { createUseStyles } from "react-jss";

import { Box, BoxProps } from "@mui/material";

const useStyles = createUseStyles({
    detailsCellProperty: {
        minWidth: "170px",
    },
    hasBorder: {
        borderBottom: "1px solid lightgray",
    },
    flex: { display: "flex" },
    detailsCellValue: {
        wordBreak: "break-word",
    },
});

export function PropertyKey({ children }: React.ComponentProps<"span">): JSX.Element {
    const classes = useStyles();

    return <span className={classes.detailsCellProperty}>{children}</span>;
}

interface PropertyRowProps extends BoxProps {
    hasBorder?: boolean;
}
export function PropertyRow({
    children,
    hasBorder = false,
    style,
}: React.ComponentProps<typeof Box> & PropertyRowProps): JSX.Element {
    const classes = useStyles();

    return (
        <Box style={style} className={clsx({ [classes.hasBorder]: hasBorder }, classes.flex)}>
            {children}
        </Box>
    );
}

export const PropertyValue = (props: React.ComponentProps<"span">) => {
    const classes = useStyles();

    return (
        <span {...props} className={clsx(classes.detailsCellValue, props.className)}>
            {props.children}
        </span>
    );
};
