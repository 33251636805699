import { FormApi, ValidationErrors } from "final-form";
import { useMemo, useRef, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "src/store";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";

import * as Fragments from "@dashboard/products/components/ProductDetails/fragments/index";
import { TopicHeader } from "@dashboard/shared/components/TopicHeader";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { Product } from "@dashboard/products/types";
const generateCodeName = (string: string) => {
    if (!string) {
        return "";
    }
    return string
        .replaceAll(" ", "_")
        .replaceAll(/[^0-9a-zA-Z_]+/g, "")
        .toLowerCase();
};

const useStyles = makeStyles({
    switchContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: 15,
    },
    modalHeaderContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
});

interface Props {
    product: Product;
    onClose: () => void;
    onConfirm: (values: Product) => Promise<void>;
    onValidate?: (values: Product) => ValidationErrors | Promise<ValidationErrors> | undefined;
    editing?: boolean;
    isEditorOpen?: boolean;
}

const ProductEditor = (props: Props) => {
    const classes = useStyles();
    const [advancedMode, setAdvancedMode] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);
    const workspace = useSelector(selectWorkspace);

    const initialValues = useMemo(
        () => ({
            ...props.product,
            workspaceId: props.product.workspaceId || workspace?.id,
            codename: props.product.codename || generateCodeName(props.product.customBuildConfig?.productName ?? ""),
            isPublic: false,
            customBuildConfig: props.product.customBuildConfig
                ? {
                      ...props.product.customBuildConfig,
                      compatibility:
                          props.product.customBuildConfig.compatibility || `>=${props.product.androidVersion}`,
                      applications: props.product.customBuildConfig?.applications || [],
                      removePackages: props.product.customBuildConfig.removePackages || [],
                  }
                : null,
        }),
        [props.product, workspace?.id],
    );

    const onClose = () => {
        setAdvancedMode((prev) => !prev);
        props.onClose();
    };

    const handleSwitchChange = () => {
        setAdvancedMode((prev) => !prev);
    };

    const handleOnSave = async (form: FormApi<Product>) => {
        if (!form.getState().valid && scrollRef.current) {
            scrollRef.current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
        await form.submit();
    };

    if (!workspace) {
        return null;
    }

    return (
        <Form<Product>
            onSubmit={props.onConfirm}
            validate={props.onValidate}
            initialValues={initialValues}
            render={({ handleSubmit, values, submitting, form }) => (
                <form onSubmit={handleSubmit} id="productDetails">
                    <Dialog open onClose={onClose} scroll="paper" maxWidth="md">
                        <Box className={classes.modalHeaderContainer}>
                            <DialogTitle>Product editor</DialogTitle>
                            <div className={classes.switchContainer}>
                                <TopicHeader>Advanced mode</TopicHeader>
                                <Switch
                                    checked={advancedMode}
                                    onClick={handleSwitchChange}
                                    color="primary"
                                    data-testid="advance-mode-switch"
                                />
                            </div>
                        </Box>
                        <DialogContent dividers ref={scrollRef}>
                            {advancedMode ? (
                                <Fragments.ConfigImport values={values} />
                            ) : (
                                <>
                                    <Fragments.Details
                                        editing={props.editing}
                                        workspace={workspace}
                                        isEditorOpen={props.isEditorOpen}
                                    />
                                    <Fragments.Recipes />
                                    <Fragments.Applications />
                                    <Fragments.ExecutableFiles />
                                    <Fragments.RemovePackages />
                                    {props.editing ? <Fragments.Variables productId={props.product.id} /> : null}
                                </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <SecondaryButton onClick={onClose} disabled={submitting}>
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                type="submit"
                                form="productDetails"
                                loading={submitting}
                                disabled={submitting}
                                onClick={() => handleOnSave(form)}
                            >
                                Save
                            </PrimaryButton>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        />
    );
};

export default ProductEditor;
