import _ from "lodash";
import { useMemo, useRef } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { selectGroups, selectGroupsMembersByWorkspace } from "src/ui/containers/dashboard/groups/store/selectors";

import { selectDevices } from "@dashboard/devices/store/selectors/devices";
import { Device, GroupMember } from "@dashboard/devices/types";
import { convertToLocalDate } from "@dashboard/devices/utils/dates";
import { SourceType } from "@dashboard/files/api";
import { FileInfo, FileStatus, FileTableItem } from "@dashboard/files/types";
import { createVersionCodeTag } from "@dashboard/files/utils";
import { createRolloutTag } from "@dashboard/shared/tags";
import { selectWorkspaces } from "@dashboard/workspaces/store/selectors";

const isObjEmpty = (obj: object) => Object.keys(obj).length === 0;
const hasAllFilters = (tags: API.Tag[], filters: Record<string, boolean>) => {
    if (!Array.isArray(tags)) {
        return false;
    }

    const keys = new Set();
    tags.forEach((item) => {
        if (item && item.key) {
            keys.add(item.key);
        }
    });
    return Object.keys(filters).some((key) => keys.has(key));
};
const getUploader = (sourceType: SourceType, member: GroupMember | undefined, device: Device | undefined) => {
    if (sourceType === SourceType.User && member?.email) {
        return member.email;
    } else if (sourceType === SourceType.Device && device?.name) {
        return device.name;
    } else if (sourceType === SourceType.Microservice) {
        return "OS CI";
    } else {
        return "-";
    }
};

export const getFilteredFiles = (files: FileTableItem[], filters: Record<string, boolean>): FileTableItem[] => {
    const activeFilters = _.pickBy(filters, (value) => value);

    if (isObjEmpty(activeFilters)) {
        return files;
    }

    return files.filter((item) => {
        const tags = item.metadata?.user?.tags;
        return tags ? hasAllFilters(tags, activeFilters) : false;
    });
};

const stablePaneFilesSort = (item: FileTableItem, orderBy: string) => {
    const value = item[orderBy as keyof FileTableItem];
    if (typeof value === "string") {
        return value.toLowerCase();
    }
    return value || 0;
};
export interface Sort<T> {
    orderBy: T;
    orderDirection: "asc" | "desc";
}
export const useGetSortedFiles = (files: FileInfo[], sort: Sort<string>) => {
    const groups = useSelector(selectGroups, shallowEqual);
    const devices = useSelector(selectDevices, shallowEqual);
    const workspaces = useSelector(selectWorkspaces, shallowEqual);
    const allWorkspaceGroupsMembers = useSelector(selectGroupsMembersByWorkspace, shallowEqual);
    const prevSort = useRef("");
    const prevFiles = useRef("");
    const sortedFilesFiles = useRef<FileTableItem[]>([]);

    if (JSON.stringify(files) === prevFiles.current && JSON.stringify(sort) === prevSort.current) {
        return sortedFilesFiles.current;
    }

    const transformedFiles: FileTableItem[] = files.map((file) => {
        const device = devices.find((item) => String(item.id) === file.ownerId);
        const group = groups.find((item) => item.id === file.groupId);
        const members = file.workspaceId ? allWorkspaceGroupsMembers : group?.members;
        const member = members?.find((item) => item.userId === file.ownerId);
        const workspaceName = workspaces.find((workspace) => workspace.id === file.workspaceId)?.name;

        return {
            ...file,
            groupName: group?.name || "-",
            uploader: getUploader(file.source, member, device),
            visibility: group?.name ?? workspaceName,
        };
    });

    prevSort.current = JSON.stringify(sort);
    prevFiles.current = JSON.stringify(files);
    sortedFilesFiles.current = _.orderBy(
        transformedFiles,
        [(item) => stablePaneFilesSort(item, sort.orderBy)],
        [sort.orderDirection],
    );

    return sortedFilesFiles.current;
};

export const useTransformFilesToTableData = (files: FileInfo[]): FileTableItem[] => {
    const groups = useSelector(selectGroups, shallowEqual);
    const devices = useSelector(selectDevices, shallowEqual);
    const workspaces = useSelector(selectWorkspaces, shallowEqual);
    const allWorkspaceGroupsMembers = useSelector(selectGroupsMembersByWorkspace, shallowEqual);

    return useMemo(() => {
        return files.map((file) => {
            const device = devices.find((item) => String(item.id) === file.ownerId);
            const group = groups.find((item) => item.id === file.groupId);
            const members = file.workspaceId ? allWorkspaceGroupsMembers : group?.members;
            const member = members?.find((item) => item.userId === file.ownerId);
            const workspaceName = workspaces.find((workspace) => workspace.id === file.workspaceId)?.name;

            const removableTags = file.metadata?.user?.tags;
            const rolloutTag = createRolloutTag(file.rolloutPercentage);
            const versionCodeTag = createVersionCodeTag(file);

            return {
                ...file,
                filename: file.filename,
                formattedCreatedDate: {
                    tooltipTitle: convertToLocalDate(file.createdDate, "ISO", "dateTime"),
                    displayValue: convertToLocalDate(file.createdDate, "ISO", "date"),
                },
                status: file.status,
                groupName: group?.name || "-",
                uploader: getUploader(file.source, member, device),
                visibility: group?.name ?? workspaceName,
                removableTags: removableTags,
                rolloutTag: rolloutTag,
                versionCodeTag: versionCodeTag,
                hasTags: removableTags?.length || versionCodeTag || rolloutTag,
                isUploading: file.status === FileStatus.Pending,
            };
        });
    }, [groups, devices, files, workspaces, allWorkspaceGroupsMembers]);
};

const getNameVersionPair = (item: FileTableItem, obj: Record<string, number>) => {
    if (!item.metadata?.file?.apk?.manifest?.versioncode) {
        return;
    }

    if (item.filename + item.metadata.file.apk.manifest.versioncode in obj) {
        obj[item.filename + item.metadata.file.apk.manifest.versioncode] = 1;
    } else {
        obj[item.filename + item.metadata.file.apk.manifest.versioncode] = 0;
    }
};

export type FileExtendedTableItem = FileTableItem & { warnings: string[] };
export const getAnalyzedFiles = (arr: FileTableItem[]): FileExtendedTableItem[] => {
    const nameVersionPair: Record<string, number> = {};

    arr.forEach((item) => {
        getNameVersionPair(item, nameVersionPair);
    });

    return arr.map((item) => {
        const warnings: string[] = [];
        if (
            item.metadata?.file?.apk?.manifest?.versioncode &&
            nameVersionPair[item.filename + item.metadata.file.apk.manifest.versioncode]
        ) {
            warnings.push("The file is duplicated");
        }

        return { ...item, warnings };
    });
};
