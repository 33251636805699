import { AxiosError, AxiosResponse } from "axios";
import RouteService from "src/services/routeService";
import { ApiResponse, BasicApiResponse, SuccessMessageResponse } from "src/types";
import { unpackError } from "src/ui/containers/dashboard/devices/api/helpers";

import httpService from "./httpService";

export type AccountProfile = API.Profile & {
    password?: string;
    password_repeat?: string;
};

type RegisterResponse = ApiResponse<{
    needEmailConfirmation: boolean;
    id: string;
    token: string;
}>;
export const register = async (payload: string) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const req = await httpService.post<{ Email: string }, AxiosResponse<RegisterResponse>>(endpoint, {
            Email: payload,
        });
        return req;
    } catch (error) {
        throw unpackError(error);
    }
};

interface ConfirmRegistrationPayload {
    id: string;
    token: string;
}
export const confirmRegistration = async (payload: ConfirmRegistrationPayload) => {
    const endpoint = await RouteService.getAccountsRoute();
    const { data } = await httpService.post<null, AxiosResponse<ApiResponse<string>>>(endpoint + "confirm", null, {
        params: payload,
        headers: { "content-type": "application/json" },
        withCredentials: true,
    });
    return data.model;
};

interface ChangePasswordResult {
    success: boolean;
    message: string;
    displayErrors: boolean;
}
interface ChangePasswordPayload {
    currentPassword: string;
    newPasswordValue: string;
    newPasswordRepeat: string;
}
export const changePassword = async (payload: ChangePasswordPayload) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const response = await httpService.patch<ChangePasswordPayload, AxiosResponse<ChangePasswordResult>>(
            endpoint + "password",
            { ...payload },
        );
        return response;
    } catch (error) {
        throw unpackError(error);
    }
};
interface RecoverPasswordPayload {
    email: string;
}
export const recoverPassword = async (payload: RecoverPasswordPayload) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.post<RecoverPasswordPayload, AxiosResponse<BasicApiResponse>>(
            endpoint + "password/recover",
            payload,
        );
        return data;
    } catch (error) {
        throw unpackError(error);
    }
};
interface ResetPasswordResponse {
    success: boolean;
    message: string;
    displayErrors: boolean;
}
interface ResetPasswordPayload {
    id: string;
    token: string;
    password: string;
    passwordRepeat: string;
}
export const resetPassword = async (payload: ResetPasswordPayload) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const response = await httpService.post<ResetPasswordPayload, AxiosResponse<ResetPasswordResponse>>(
            endpoint + "password/reset",
            payload,
        );
        return response;
    } catch (error) {
        throw unpackError(error);
    }
};

export const getAccount = async () => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data: profile } = await httpService.get<ApiResponse<API.Profile>>(endpoint);
        const { data: billingAddress } = await httpService.get<ApiResponse<API.BillingAddress>>(
            endpoint + "billing/address",
        );
        return { data: { profile: profile.model, billingAddress: billingAddress.model } };
    } catch (error) {
        throw unpackError(error);
    }
};
interface DeleteAccountPayload {
    email: string | undefined;
    password: string;
}
export const deleteAccount = async (payload: DeleteAccountPayload) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.delete<
            DeleteAccountPayload,
            AxiosResponse<SuccessMessageResponse<{ message: string }>>
        >(endpoint, {
            data: { ...payload },
        });
        return data;
    } catch (error) {
        throw unpackError(error);
    }
};

export const getProfile = async () => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.get<ApiResponse<API.Profile>>(endpoint);
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};

type UpdateProfilePayload = Omit<AccountProfile, "password">;

export const updateProfile = async (payload: UpdateProfilePayload) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data: response } = await httpService.patch<
            UpdateProfilePayload,
            AxiosResponse<ApiResponse<API.Profile>>
        >(endpoint, payload);
        return {
            success: true,
            message: response?.message ?? "Successfully updated profile information",
        };
    } catch (ex) {
        const error = ex as AxiosError<{ message: string; details: { message: string; type: string }[] }>;

        if (error.response) {
            const errorData = error.response.data;
            let errorMessage = errorData.message;

            if (errorData.details && errorData.details.length > 0) {
                errorMessage = errorData.details[0].message;
            }

            return {
                success: false,
                message: errorMessage,
            };
        } else {
            return {
                success: false,
                message: "An unexpected error occurred.",
            };
        }
    }
};

export const getBilling = async () => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.get<ApiResponse<API.BillingAddress>>(endpoint + "billing/address");
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
type UpdateBillingResponse = AxiosResponse<SuccessMessageResponse<{ message: string }>>;
export const updateBilling = async (payload: Partial<API.BillingAddress>) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.post<Partial<API.BillingAddress>, UpdateBillingResponse>(
            endpoint + "billing/address",
            payload,
        );
        return data;
    } catch (error) {
        throw unpackError(error);
    }
};

type GetBillingCardResponse = AxiosResponse<ApiResponse<API.BillingCard | null>>;
export const getBillingCard = async () => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.get<string, GetBillingCardResponse>(endpoint + "billing/card");
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
export const updateStripeCard = async (payload: string) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.post<{ PaymentMethodId: string }, AxiosResponse<BasicApiResponse>>(
            endpoint + "billing/card",
            { PaymentMethodId: payload },
        );
        return data;
    } catch (error) {
        throw unpackError(error);
    }
};

export const getApiKeys = async () => {
    try {
        const endpoint = await RouteService.getApiKeysRoute();
        const { data } = await httpService.get<ApiResponse<string>>(endpoint);
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
export const recreateApiKeys = async () => {
    try {
        const endpoint = await RouteService.getApiKeysRoute();
        const { data } = await httpService.patch<ApiResponse<string>>(endpoint);
        return data;
    } catch (error) {
        throw unpackError(error);
    }
};
export const deleteApiKeys = async () => {
    try {
        const endpoint = await RouteService.getApiKeysRoute();
        const { data } = await httpService.delete<BasicApiResponse>(endpoint);
        return data;
    } catch (error) {
        throw unpackError(error);
    }
};

interface TwoFactorSettings {
    url: string;
    secretKey: string;
    code: string;
}

export const getTwoFactorSettings = async () => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.get<ApiResponse<TwoFactorSettings>>(endpoint + "2fa");
        return data.model;
    } catch (error) {
        throw unpackError(error);
    }
};
export const enableTwoFactor = async (payload: Partial<TwoFactorSettings>) => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.post<Partial<TwoFactorSettings>, AxiosResponse<BasicApiResponse>>(
            endpoint + "2fa",
            payload,
        );
        return data;
    } catch (error) {
        throw unpackError(error);
    }
};
export const disableTwoFactor = async () => {
    try {
        const endpoint = await RouteService.getAccountsRoute();
        const { data } = await httpService.delete<Partial<TwoFactorSettings>, AxiosResponse<BasicApiResponse>>(
            endpoint + "2fa",
        );
        return data;
    } catch (error) {
        throw unpackError(error);
    }
};

interface InvitationRequest {
    email: string;
    token: string;
    type: string;
    id: string;
}

export const handleInvitation = async (
    invitationRequest: InvitationRequest,
): Promise<AxiosResponse<RegisterResponse>> => {
    const { email, token, type, id } = invitationRequest;
    const workspaceEndpoint = await RouteService.getWorkspacesRoute();
    const groupEndpoint = await RouteService.getGroupsRoute();

    const endpoint =
        type === "workspace" ? workspaceEndpoint + `${id}/invitations` : groupEndpoint + `${id}/invitations`;

    const requestData = {
        email,
        token,
        response: "accepted",
    };

    try {
        const response = await httpService.patch<
            { email: string; token: string; response: string },
            AxiosResponse<RegisterResponse>
        >(endpoint, requestData);
        return response;
    } catch (error) {
        throw unpackError(error);
    }
};
