import React from "react";
import { useSelector } from "src/store";
import { selectGroups } from "src/ui/containers/dashboard/groups/store/selectors";
import { ExpiredWrapper } from "src/ui/shared/ExpiredWrapper";

import { selectDevices, selectDevicesByGroupIds } from "@dashboard/devices/store/selectors/devices";
import { selectSubscriptionExpired } from "@dashboard/devices/store/selectors/subscriptions";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import makeStyles from "@mui/styles/makeStyles";

import PageTitleWithIcon from "../shared/components/PageTitleWithIcon";
import { selectWorkspace } from "../workspaces/store/selectors";
import { DeviceActivityCard } from "./components/DeviceActivity";
import { Filter } from "./components/Filter";
import { StatisticSection } from "./components/StatisticSection/StatisticSection";
import { TutorialSection } from "./components/TutorialSection";

const useStyles = makeStyles(() => ({
    filterContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        marginBottom: 16,
    },
    contentBox: { marginTop: 10 },
    appsLoadingBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "inherit",
    },
}));

const getOverviewPageGroupFilter = (): number[] => {
    const storedValue = localStorage.getItem("overviewPageGroupFilter");
    return storedValue ? JSON.parse(storedValue) : [];
};
const OverviewPage = () => {
    const [filter, setFilter] = React.useState<number[]>(getOverviewPageGroupFilter());

    const classes = useStyles();

    const workspace = useSelector(selectWorkspace);
    const groups = useSelector(selectGroups);
    const devices = useSelector(selectDevices);
    const isExpired = useSelector(selectSubscriptionExpired);

    const filteredGroups = filter.length > 0 ? groups.filter((group) => filter.includes(group.id)) : groups;
    const filteredGroupIds = filteredGroups.map((g) => g.id);
    const filteredDevices = useSelector((state) => selectDevicesByGroupIds(state, filteredGroupIds));

    return (
        <PageContainer>
            <div className={classes.filterContainer}>
                <div>
                    <PageTitleWithIcon title="Dashboard overview" iconName="fa-solid fa-chart-line" />
                    <SectionDescription>Monitor device status and events</SectionDescription>
                </div>
                {workspace && devices.length ? (
                    <Box className={classes.filterContainer}>
                        {!isExpired ? <Filter filter={filter} setFilter={setFilter} /> : null}
                    </Box>
                ) : null}
            </div>
            <TutorialSection />
            {workspace && devices.length ? (
                <ExpiredWrapper>
                    <Grid container spacing={1} className={classes.contentBox}>
                        <Grid size={{ xs: 12, md: 8 }}>
                            <StatisticSection {...{ filter }} />
                        </Grid>
                        <Grid size={{ xs: 12, md: 4 }}>
                            <DeviceActivityCard filteredDevices={filteredDevices} />
                        </Grid>
                    </Grid>
                </ExpiredWrapper>
            ) : null}
        </PageContainer>
    );
};

export default OverviewPage;
