import clsx from "clsx";
import React from "react";
import { shallowEqual } from "react-redux";
import euporiaService from "src/services/euporiaService";
import { LocalStorage } from "src/services/localStorageService";
import { useDispatch, useSelector } from "src/store";
import { selectLicensesWithFilter } from "src/ui/containers/dashboard/licenses/store/selectors";
import FreshAccountMessage from "src/ui/shared/FreshAccountMessage";
import { MinimalTable, MinimalTableCell, MinimalTableHeader } from "src/ui/shared/table/MinimalTableComponents";
import { Header } from "src/ui/shared/table/TableHeader";
import { fuzzyFilter } from "src/ui/shared/table/Tanstack.utils";

import {
    closeBatchMode,
    closeDetails,
    selectBatchActionIsTypeMove,
    selectBatchActionSelectionTransformed,
    selectBatchState,
    selectDetailsState,
    selectSearchQuery,
    showDetails,
    updateSearchQuery,
} from "@dashboard/devices/store";
import PageTitleWithIcon from "@dashboard/shared/components/PageTitleWithIcon";
import { PageContainer, SectionDescription } from "@dashboard/shared/styles";
import { selectWorkspace } from "@dashboard/workspaces/store/selectors";
import { Paper, TableBody, TableContainer, TablePagination, TableRow, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
    Cell,
    flexRender,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    OnChangeFn,
    useReactTable,
} from "@tanstack/react-table";

import { SidePanel } from "../shared/components/SidePanel/SidePanel";
import { ManagementSection } from "./components/ManagementSection/ManagementSection";
import { FilterWrapper } from "./components/Table/Filters";
import {
    DeviceTableData,
    tableColumns,
    useTransformLicenseToTableData,
    visibleColumnsConfig,
} from "./DevicesPage.utils";

const useStyles = makeStyles((theme: Theme) => ({
    tableContainer: {
        overflowX: "auto",
    },
    tableRow: {
        "&:hover": {
            backgroundColor: theme.palette.blue[50],
        },
    },
    tableRowSelected: {
        backgroundColor: theme.palette.blue[50],
    },
}));

const DevicesPage = () => {
    const [selectedRowId, setSelectedRowId] = React.useState<string | null>(null);
    const initialRowsPerPage = Number(LocalStorage.getItem("devicesPageRowsPerPage")) || 10;
    const [disablePageReset, setDisablePageReset] = React.useState(false);
    const [visibleColumns, setVisibleColumns] = React.useState(
        LocalStorage.getItem<Record<string, boolean>>("devicesPageVisibleColumns") ?? visibleColumnsConfig,
    );

    const dispatch = useDispatch();
    const classes = useStyles();

    const workspace = useSelector(selectWorkspace);
    const selectBatchActionSelected = useSelector(selectBatchActionSelectionTransformed);
    const batchState = useSelector(selectBatchState);
    const searchQuery = useSelector(selectSearchQuery);
    const detailsState = useSelector(selectDetailsState);
    const licenses = useSelector(selectLicensesWithFilter, shallowEqual);
    const selectIsBatchActionMove = useSelector(selectBatchActionIsTypeMove);
    const data = useTransformLicenseToTableData(licenses);

    const setSearchQuery: OnChangeFn<unknown> = (value) => {
        if (typeof value === "string") {
            dispatch(updateSearchQuery(value.toLowerCase()));
        }
    };
    const handleTableItemClick = (payload: { rowId: string; cell: Cell<DeviceTableData, unknown> }) => {
        if (batchState.entity !== "license" && payload.cell.column.columnDef.id === "groupName") {
            setSelectedRowId(payload.rowId);
            dispatch(
                showDetails({
                    type: "group",
                    selectedId: payload.cell.row.original.groupId,
                }),
            );
        }
        if (batchState.entity !== "license" && payload.cell.column.columnDef.id !== "groupName") {
            setSelectedRowId(payload.rowId);
            dispatch(
                showDetails({
                    type: "license",
                    selectedId: payload.cell.row.original.id,
                }),
            );
        }

        euporiaService.createTransaction("side-panel", {
            trigger_column: payload.cell.column.columnDef.id,
            panel_type: payload.cell.column.columnDef.id === "groupName" ? "group" : "license",
        });
    };

    const table = useReactTable({
        data: data,
        columns: tableColumns,
        filterFns: { fuzzy: fuzzyFilter },
        state: {
            globalFilter: searchQuery,
            columnVisibility: { ...visibleColumns, moveLicenseCheckbox: selectIsBatchActionMove },
            rowSelection: selectBatchActionSelected,
        },
        initialState: {
            pagination: {
                pageSize: initialRowsPerPage || 10,
                pageIndex: 0,
            },
        },
        defaultColumn: { size: 0 },
        autoResetPageIndex: !disablePageReset,
        onColumnVisibilityChange: setVisibleColumns,
        onGlobalFilterChange: setSearchQuery,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
    });

    const tableRows = table.getPaginationRowModel().rows;
    const totalRows = table.getFilteredRowModel().rows.length;
    const currentPageIndex = table.getState().pagination.pageIndex;
    const pageSize = table.getState().pagination.pageSize;

    // disables autoResetPageIndex to rerender the table when opening devices details pane
    React.useEffect(() => {
        if (!detailsState.isOpen) {
            setSelectedRowId("");
            setDisablePageReset(false);
        }
    }, [detailsState.isOpen]);

    React.useEffect(() => {
        return () => {
            dispatch(closeDetails());
            dispatch(closeBatchMode());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageContainer>
            <PageTitleWithIcon title="Devices" iconName="fa-solid fa-laptop" />
            <SectionDescription>
                Effortlessly monitor and manage your devices with real-time insights and status updates.
            </SectionDescription>
            {!workspace ? (
                <FreshAccountMessage />
            ) : (
                <React.Fragment>
                    <ManagementSection
                        setSearchQuery={setSearchQuery}
                        onClearSearchQuery={() => dispatch(updateSearchQuery(""))}
                        setVisibleColumns={setVisibleColumns}
                        searchQuery={searchQuery}
                        table={table}
                    />
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <MinimalTable>
                            <MinimalTableHeader>
                                {table
                                    .getHeaderGroups()
                                    .slice(1)
                                    .map((headerGroup) => (
                                        <TableRow key={headerGroup.id}>
                                            {headerGroup.headers.map((header) => (
                                                <Header key={header.id} {...header}>
                                                    <FilterWrapper column={header.column} />
                                                </Header>
                                            ))}
                                        </TableRow>
                                    ))}
                            </MinimalTableHeader>
                            <TableBody>
                                {tableRows.length === 0 ||
                                tableRows.every((row) => row.getVisibleCells().length === 0) ? (
                                    <TableRow>
                                        <MinimalTableCell colSpan={table.getAllLeafColumns().length} align="center">
                                            No devices to show
                                        </MinimalTableCell>
                                    </TableRow>
                                ) : (
                                    tableRows.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            className={clsx(
                                                classes.tableRow,
                                                selectedRowId === row.id ? classes.tableRowSelected : "",
                                            )}
                                        >
                                            {row.getVisibleCells().map((cell) => (
                                                <MinimalTableCell
                                                    align="left"
                                                    key={cell.id}
                                                    onClick={() => handleTableItemClick({ rowId: row.id, cell: cell })}
                                                >
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </MinimalTableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </MinimalTable>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        rowsPerPageOptions={[10, 25, 50]}
                        count={totalRows}
                        rowsPerPage={pageSize}
                        page={currentPageIndex}
                        onPageChange={(_e, newPage) => {
                            table.setPageIndex(newPage);
                        }}
                        onRowsPerPageChange={(e) => {
                            const newSize = parseInt(e.target.value, 10);
                            table.setPageSize(newSize);
                            LocalStorage.setItem("devicesPageRowsPerPage", newSize);
                        }}
                    />
                </React.Fragment>
            )}
            <SidePanel />
        </PageContainer>
    );
};

export default DevicesPage;
