import React from "react";
import { useField, UseFieldConfig, useForm } from "react-final-form";

import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const Field = styled(MuiTextField)(({ theme }) => ({
    "& .MuiInputBase-input": {
        backgroundColor: theme.palette.white[50],
        borderRadius: 4,
    },
    "& .MuiInputBase-input.Mui-disabled": {
        backgroundColor: theme.palette.shadow[100],
    },
    "& .Mui-focused .MuiInputBase-input": {
        borderColor: theme.palette.blue[150],
    },
    "& .Mui-error .MuiInputBase-input": {
        borderColor: theme.palette.red[50],
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: theme.palette.blue[150],
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.blue[150],
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.red[50],
    },
    "& .MuiFormHelperText-root": {
        color: theme.palette.blue[150],
        marginTop: "0px",
    },
    "& .MuiFormHelperText-root.Mui-error": {
        color: theme.palette.red[50],
    },
    "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.blue[150],
    },
    "& .MuiFormLabel-root.Mui-error": {
        color: theme.palette.red[50],
    },
}));

type Props = TextFieldProps & {
    name: string;
    config?: UseFieldConfig<string, string>;
};

export const TextField = ({ name, config, variant = "outlined", size = "small", helperText, ...rest }: Props) => {
    const { input, meta } = useField(name, config);
    const form = useForm();
    const error = form.getState().errors?.[name] || null;
    // cannot use meta.error from useField due to the limitations of react-final-form,
    // which does not recognize errors for fields with dot notation in meta.error

    return (
        <Field
            {...input}
            {...rest}
            {...{ size, variant }}
            error={error && meta.touched}
            helperText={error && meta.touched ? error : helperText}
            slotProps={{
                ...rest.slotProps,
            }}
            inputProps={{
                "data-testid": "text-field",
                ...rest.inputProps,
            }}
        />
    );
};

export const UncontrolledTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
    ({ variant = "outlined", size = "small", ...rest }, ref) => (
        <Field
            {...rest}
            {...{ size, variant }}
            slotProps={{
                ...rest.slotProps,
            }}
            inputProps={{
                "data-testid": "text-field",
                ...rest.inputProps,
            }}
            inputRef={ref}
        />
    ),
);
UncontrolledTextField.displayName = "UncontrolledTextField";
