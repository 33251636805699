import { styled, TableCell as MuiTableCell, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme: Theme) => ({
    licenseItemCheckbox: {
        width: "42px",
        height: "42px",
    },
    licenseSummaryLegacyIndicator: {
        width: "4px",
        borderRadius: "18px",
        height: 80,
    },
    licenseColumn: {
        display: "flex",
        flexDirection: "column",
        padding: "0.2rem 0.4rem",
    },
    tableCellContent: {
        display: "flex",
        flexDirection: "column",
    },
    licensePropertyContainer: {
        display: "flex",
        flexDirection: "row",
        whiteSpace: "nowrap",
        alignItems: "center",
    },
    licensePropertyIcon: {
        marginTop: "2px",
        marginRight: "8px",
        fontSize: "0.9rem",
        color: theme.palette.shadow[150],
    },
    licensePropertyValue: {
        fontSize: "0.9rem",
        display: "inline-block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 150,
    },
}));

export const TableCell = styled(MuiTableCell)({
    paddingBottom: 0,
    paddingTop: 0,
    border: "none",
});
export const NoPaddingTableCell = styled(MuiTableCell)({
    padding: 0,
    border: "none",
    maxWidth: 60,
});
export const IndicatorTableCell = styled(NoPaddingTableCell)({
    display: "flex",
    padding: "2px 0",
});
