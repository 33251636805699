import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import useQRCodeGenerator from "react-hook-qrcode-svg";
import { useNavigate } from "react-router";
import { getTwoFactorSettings } from "src/services/accountService";
import { useDispatch } from "src/store";
import { enableTwoFactor } from "src/store/account";
import { PrimaryButton, SecondaryButton } from "src/ui/shared/CustomButton";
import { TextField } from "src/ui/shared/form/TextField";
import { LoadingIcon } from "src/ui/shared/Loading";

import { IconSize } from "@dashboard/devices/components/shared";
import { PageContainer } from "@dashboard/shared/styles";
import { Card, CardContent, CardHeader, Divider, Theme, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { showErrorToast, showSuccessToast } from "@shared/toasts/Toasts";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        backgroundColor: theme.palette.shadow[100],
        color: theme.palette.blue[200],
    },
    codeBox: { minHeight: 300, display: "flex", justifyContent: "center", alignItems: "center" },
    inputBox: { display: "flex" },
    indentation: { marginLeft: 36, padding: "10px 0" },
    buttonBox: {
        marginTop: 12,
        display: "flex",
        gap: 10,
        justifyContent: "flex-end",
    },
}));

type FormValues = {
    secretKey: string;
    code: string;
};

const qrCodeSize = 300;
const qrCodeLevel = "L";
const qrCodeBorder = 0;

const TwoFactorEnablePage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [qrCode, setQrCode] = useState<string>("");
    const [secretKey, setSecretKey] = useState<string>();
    const { path, viewBox } = useQRCodeGenerator(qrCode, qrCodeLevel, qrCodeBorder);
    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);

        void (async () => {
            try {
                const data = await getTwoFactorSettings();
                setSecretKey(data.secretKey);
                setQrCode(data.url);
            } catch (err) {
                const error = err as Error;
                showErrorToast(error.message);
            } finally {
                setLoading(false);
            }
        })();
    }, [navigate]);

    const onSubmit = async (values: FormValues) => {
        setLoading(true);
        try {
            const { message } = await dispatch(enableTwoFactor({ code: values.code, secretKey })).unwrap();
            showSuccessToast(message);
            navigate("/account/edit");
        } catch (err) {
            const error = err as Error;
            showErrorToast(error.message);
        } finally {
            setLoading(false);
        }
    };

    const onValidate = (values: FormValues) => {
        const errors: Partial<FormValues> = {};

        if (!values.code) {
            errors.code = "The code cannot be empty";
        }

        if (/[a-zA-Z]/g.test(values.code)) {
            errors.code = "Bad format. Only digits are allowed";
        }

        return errors;
    };

    if (!viewBox) {
        return null;
    }

    return (
        <PageContainer>
            <Card>
                <CardHeader
                    className={classes.header}
                    title={<span style={{ fontSize: 18 }}>Two-Factor Authentication</span>}
                />
                <CardContent>
                    <p>Perform the following steps by using an existing authenticator application:</p>
                    <ol>
                        <li className={classes.indentation}>
                            <p>
                                Download a two-factor authenticator app like <i>Microsoft Authenticator</i> (for{" "}
                                <a
                                    href="https://go.microsoft.com/fwlink/?Linkid=825071"
                                    rel="nofollow noreferrer"
                                    target="_blank"
                                >
                                    Windows Phone
                                </a>
                                ,{" "}
                                <a
                                    href="https://go.microsoft.com/fwlink/?Linkid=825072"
                                    rel="nofollow noreferrer"
                                    target="_blank"
                                >
                                    Android
                                </a>{" "}
                                and{" "}
                                <a
                                    href="https://go.microsoft.com/fwlink/?Linkid=825073"
                                    rel="nofollow noreferrer"
                                    target="_blank"
                                >
                                    iOS
                                </a>
                                ) for <i>Google Authenticator</i> (for{" "}
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                                    rel="nofollow noreferrer"
                                    target="_blank"
                                >
                                    Android
                                </a>{" "}
                                and{" "}
                                <a
                                    href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
                                    rel="nofollow noreferrer"
                                    target="_blank"
                                >
                                    iOS
                                </a>
                                )
                            </p>
                        </li>
                        <li className={classes.indentation}>
                            <p>
                                In case your applicaton already has an existing entry for this website, remove it before
                                continuing. For security reasons, a new setup is generated each time this page is
                                reloaded, which requires you to start all over again and remove all entries for this
                                website in the authenticator application.
                            </p>
                        </li>
                        <li className={classes.indentation}>
                            <p>Scan the QR code:</p>
                            <div className={classes.codeBox}>
                                {loading ? (
                                    <LoadingIcon size={IconSize.large} />
                                ) : (
                                    <svg width={qrCodeSize} height={qrCodeSize} viewBox={viewBox} stroke="none">
                                        <rect width="100%" height="100%" fill={theme.palette.white[50]} />
                                        <path d={path} fill={theme.palette.black[100]} />
                                    </svg>
                                )}
                            </div>
                        </li>
                        <li className={classes.indentation}>
                            <p>
                                After completion, the two-factor authenticator app will provide you with a unique code,
                                please enter this code below.
                            </p>
                        </li>
                        <li className={classes.indentation}>
                            <p>
                                If something goes wrong, please remove the created entry in the two-factor authenticator
                                application and start over again.
                            </p>
                        </li>
                    </ol>
                </CardContent>
                <Divider />
                <CardContent>
                    <Form<FormValues>
                        onSubmit={onSubmit}
                        validate={onValidate}
                        render={({ handleSubmit, pristine }) => (
                            <form onSubmit={(e) => void handleSubmit(e)}>
                                <div className={classes.inputBox}>
                                    <TextField name="code" placeholder="012 345" fullWidth />
                                </div>
                                <div className={classes.buttonBox}>
                                    <SecondaryButton onClick={() => navigate("/account/edit")}>Cancel</SecondaryButton>
                                    <PrimaryButton type="submit" disabled={loading || pristine} loading={loading}>
                                        Enable
                                    </PrimaryButton>
                                </div>
                            </form>
                        )}
                    />
                </CardContent>
            </Card>
        </PageContainer>
    );
};

export default TwoFactorEnablePage;
