import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "src/store";

import { devicesPageFilterState } from "@dashboard/devices/store";

import { SidePanel } from "../../../shared/components/SidePanel/SidePanel";
import { Filters } from "../Filtering/Filters";
import { Header } from "../Header/Header";

const gap = 6;

const useDimensions = <T extends HTMLDivElement>() => {
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
    const ref = React.useRef<T | null>(null);

    React.useLayoutEffect(() => {
        if (ref.current) {
            const { offsetWidth, offsetHeight } = ref.current;
            setDimensions({ width: offsetWidth, height: offsetHeight });
        }
    }, []);

    return { ref, dimensions };
};

const useStyles = createUseStyles({
    container: { position: "sticky", top: 0, zIndex: 100, marginBottom: 20, paddingTop: 8 },
    headerBox: { width: "100%" },
    contentContainer: { position: "relative", display: "flex", justifyContent: "flex-end" },
    filterBox: { width: "100%", position: "absolute", top: gap },
    paneBox: { position: "absolute", width: "70%" },
});

export const ManagementPanel = () => {
    const { ref: barRef } = useDimensions();
    const { ref: filterRef, dimensions: filterDimensions } = useDimensions();

    const classes = useStyles();

    const filtersState = useSelector(devicesPageFilterState);

    return (
        <div className={classes.container} style={{ paddingBottom: filtersState.isOpen ? filterDimensions.height : 0 }}>
            <div ref={barRef} className={classes.headerBox}>
                <Header />
            </div>
            <div className={classes.contentContainer}>
                <div
                    ref={filterRef}
                    className={classes.filterBox}
                    style={{ visibility: filtersState.isOpen ? "visible" : "hidden" }}
                >
                    <Filters />
                </div>
                <SidePanel />
            </div>
        </div>
    );
};
