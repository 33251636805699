export * from "./filters";
export * from "./batchActions";
export * from "./details";

import { RootState } from "src/store";

import { localState } from "@dashboard/devices/store";
import { createSelector } from "@reduxjs/toolkit";

export type ViewState = {
    groups: {
        collapse: number[];
    };
    licenses: {
        pageSize: number;
    };
};

const viewState = (state: RootState): ViewState => localState(state)?.view;

const selectSearchQuery = (state: RootState): string => localState(state)?.search?.query ?? "";

const selectViewGroupCollapsedById = createSelector(
    viewState,
    (_: RootState, groupId: number) => groupId,
    (view, groupId) => {
        return view.groups.collapse.includes(groupId);
    },
);

const selectLicensePageSize = createSelector(viewState, (view) => view.licenses.pageSize);
const selectPaneTab = (state: RootState) => localState(state).pane.tab;
const selectPaneEditEnabled = (state: RootState) => localState(state).pane.editEnabled;
const selectPaneStatus = (state: RootState) => localState(state).pane.status;

export {
    selectLicensePageSize,
    selectPaneEditEnabled,
    selectPaneStatus,
    selectPaneTab,
    selectSearchQuery,
    selectViewGroupCollapsedById,
};
