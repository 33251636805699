import { styled } from "@mui/material";

const NoPaddingTableCell = styled("div")({
    padding: 0,
    border: "none",
    maxWidth: 60,
});
export const IndicatorTableCell = styled(NoPaddingTableCell)({
    display: "flex",
    padding: "2px 0",
});
