import { useState } from "react";
import { RootState, useDispatch, useSelector } from "src/store";

import { DeviceOrNumber } from "@dashboard/shared/components/SidePanel/shared/Table";
import { deleteBorder } from "@dashboard/shared/styles";
import { useCommands } from "@devices/components/Commands/hooks";
import { ConfirmDialog } from "@devices/components/shared";
import { setPaneTab } from "@devices/store";
import { selectSubscriptionExpired } from "@devices/store/selectors/subscriptions";
import { Device } from "@devices/types";
import { getPresignedDownloadUrl, invokeFileDownload } from "@files/api";
import { useDeleteFile } from "@files/hooks/entities";
import { FileInfo } from "@files/types";
import { hasUnsuccessfulAnalyzer } from "@files/utils";
import { selectGroupById } from "@groups/store/selectors";
import { Box, TableCell } from "@mui/material";
import { permissionService } from "@services/permissionService/permissionService";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";
import { selectWorkspace } from "@workspaces/store/selectors";

import { useStyles } from "./TableItem.style";
import { getApkFileStatus, getPrimaryButtonProps } from "./TableItem.utils";

type Props<T extends DeviceOrNumber> = {
    index: number;
    collapseOpen: number;
    setDetailsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    file: FileInfo;
    selectedItem: T;
};
export const ActionsCell = <T extends Device>(props: Props<T>) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { removeFile } = useDeleteFile();
    const unsuccessfulAnalyzerExist = hasUnsuccessfulAnalyzer(props.file);
    const device = props.selectedItem;
    const status = getApkFileStatus(props.file, device.lastState?.packages);
    const [loading, setLoading] = useState(false);

    const workspace = useSelector(selectWorkspace);
    const group = useSelector((state: RootState) =>
        props.file.groupId ? selectGroupById(state, props.file.groupId) : null,
    );
    const { groupAbility, deviceAbility } = permissionService();
    const mayManageFile = groupAbility(group || null, workspace).can("manage", "File");
    const mayInstallApp = deviceAbility(device).can("install", "App");

    const installedPackage = device.lastState?.packages?.find(
        (item) => item.package.toLowerCase() === props.file.metadata?.file?.apk?.manifest?.package.toLowerCase(),
    );
    const currentAppVersion = Number(installedPackage?.versionCode);
    const appVersionToInstall = Number(props.file.metadata?.file?.apk?.manifest?.versioncode);
    const appIsUpToDate = currentAppVersion >= appVersionToInstall;

    const { createMdmCommand } = useCommands();

    const isExpired = useSelector(selectSubscriptionExpired);

    const getDownloadBtnTitle = () => {
        const result = { title: "Download file" };
        if (isExpired) {
            result.title = "Subscription expired";
        }
        return result;
    };

    const toggleModal = () => setIsModalOpen((prev) => !prev);

    const handleDelete = async () => {
        await removeFile(props.file.id);
    };

    const installPackageForDevice = async () => {
        const url = await getPresignedDownloadUrl(props.file.id);
        const commandObject = {
            url: url,
            source: "url",
            package: props.file.metadata?.file?.apk?.manifest?.package,
            version: props.file.metadata?.file?.apk?.manifest?.versioncode,
        };

        await createMdmCommand({
            deviceId: device.id,
            json: {
                "command": "installPackage",
                "caption": "Install package",
                ...commandObject,
            },
        }).then(() => {
            dispatch(setPaneTab("COMMANDS"));
        });
    };

    const installButtonProps = getPrimaryButtonProps(status, unsuccessfulAnalyzerExist, mayInstallApp);

    const handleFileDownload = async () => {
        setLoading(true);
        await invokeFileDownload(props.file.id, props.file.filename);
        setLoading(false);
    };

    return (
        <>
            <TableCell align="right" style={deleteBorder(props.index, props.collapseOpen)}>
                <Box className={classes.buttonBox}>
                    {!appIsUpToDate ? (
                        <SecondaryButton
                            tooltipProps={{
                                title: isExpired ? "Subscription expired" : installButtonProps.tooltip,
                            }}
                            disabled={installButtonProps.disabled || isExpired}
                            onClick={installPackageForDevice}
                        >
                            <i className={installButtonProps.iconClass} />
                        </SecondaryButton>
                    ) : null}
                    <SecondaryButton
                        tooltipProps={getDownloadBtnTitle()}
                        disabled={loading || isExpired}
                        loading={loading}
                        onClick={() => handleFileDownload()}
                    >
                        <i className="fa-solid fa-download" />
                    </SecondaryButton>
                    <SecondaryButton
                        tooltipProps={{ title: "Show details" }}
                        onClick={() => props.setDetailsModalOpen(true)}
                    >
                        <i className="fas fa-info-circle" />
                    </SecondaryButton>
                    <DangerOutlinedButton
                        tooltipProps={{
                            title: mayManageFile ? "Delete file" : "You role does not allow to delete files",
                        }}
                        onClick={toggleModal}
                        disabled={!mayManageFile}
                    >
                        <i className="fa-solid fa-trash-alt" />
                    </DangerOutlinedButton>
                </Box>
            </TableCell>
            <ConfirmDialog
                title="Delete file"
                content={
                    <p>
                        Are you sure you want to delete{" "}
                        <span style={{ fontWeight: "bold" }}>{props.file.filename}</span>?
                    </p>
                }
                dangerButton
                primaryActionText="Delete"
                open={isModalOpen}
                onConfirm={handleDelete}
                onClose={toggleModal}
            />
        </>
    );
};
