export enum EmteriaRoles {
    EmteriaAdminRole = "Emteria Admin",
    EmteriaDeveloperRole = "Emteria Developer",
    EmteriaSalesRole = "Emteria Sales",
    EmteriaSupportRole = "Emteria Support",
    EmteriaUploaderRole = "Emteria Uploader",
    EmteriaAccountantRole = "Emteria Accountant",
}

export type AccessRole = Array<EmteriaRoles>;

type AccessRoles = Record<string, AccessRole>;

const accessRoles: AccessRoles = {
    AdminAllAccessRoles: [
        EmteriaRoles.EmteriaAdminRole,
        EmteriaRoles.EmteriaSalesRole,
        EmteriaRoles.EmteriaSupportRole,
        EmteriaRoles.EmteriaDeveloperRole,
    ],
    AdminDeveloperAccessRoles: [EmteriaRoles.EmteriaAdminRole, EmteriaRoles.EmteriaDeveloperRole],
    AdminSalesAccessRoles: [EmteriaRoles.EmteriaAdminRole, EmteriaRoles.EmteriaSalesRole],
    AdminSupportAccessRoles: [EmteriaRoles.EmteriaAdminRole, EmteriaRoles.EmteriaSupportRole],
    AdminAccountantAccessRoles: [EmteriaRoles.EmteriaAdminRole, EmteriaRoles.EmteriaAccountantRole],
};

const roles = {
    emteriaRoles: EmteriaRoles,
    accessRoles,
};

export default roles;
