import clsx from "clsx";
import { useReducer, useState } from "react";

import { deleteBorder } from "@dashboard/shared/styles";
import { useCommands } from "@devices/components/Commands/hooks";
import { ConfirmDialog, Icon, IconSize } from "@devices/components/shared";
import { MdmPolicyCommand } from "@devices/types";
import { Draggable, DraggableProvided, DraggableStateSnapshot } from "@hello-pangea/dnd";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Collapse, IconButton } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { DangerOutlinedButton, SecondaryButton } from "@shared/CustomButton";

import { EditPolicyCommandDialog } from "./EditPolicyCommandDialog";
import { useStyles } from "./PolicyCommandItem.style";

interface PolicyCommandItemProps {
    policyCommand: MdmPolicyCommand;
    policyCommands: MdmPolicyCommand[];
    groupId: number;
    index: number;
}

const PolicyCommandItem = ({ policyCommand, policyCommands, groupId, index }: PolicyCommandItemProps) => {
    const classes = useStyles();

    const [open, setOpen] = useState(-1);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const toggleModal = () => setIsDeleteModalOpen((prev) => !prev);

    const [editDialogOpen, setEditDialogOpen] = useReducer((state) => !state, false);

    const {
        clearPolicyCommands,
        loadingState: { updatePolicyLoading, clearPolicyLoading },
    } = useCommands();

    const handleDelete = async (policy: MdmPolicyCommand) => {
        const filteredPolicyCommands = policyCommands.filter((c) => c !== policy);
        await clearPolicyCommands({ groupId, policy: JSON.stringify(filteredPolicyCommands) });
    };

    return (
        <>
            <Draggable draggableId={index.toString()} index={index} isDragDisabled={updatePolicyLoading}>
                {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                    return (
                        <>
                            <TableRow
                                key={index}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={{
                                    ...draggableProvided.draggableProps.style,
                                    background: snapshot.isDragging ? "rgba(245,245,245, 0.75)" : "none",
                                }}
                            >
                                <TableCell align="left" style={deleteBorder(index, open)}>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <IconButton
                                            className={classes.collapseBtn}
                                            onClick={() => setOpen(open === index ? -1 : index)}
                                            size="large"
                                        >
                                            {open === index ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                                        </IconButton>
                                        <div {...draggableProvided.dragHandleProps}>
                                            <Icon name="fas fa-bars" color="action" size={IconSize.medium} />
                                        </div>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" width={20} style={deleteBorder(index, open)}>
                                    {index + 1}
                                </TableCell>
                                <TableCell
                                    align="left"
                                    style={deleteBorder(index, open)}
                                    className={clsx(classes.overflow)}
                                >
                                    {policyCommand.caption || policyCommand.command}
                                </TableCell>
                                <TableCell align="right" style={deleteBorder(index, open)}>
                                    <div className={classes.buttonBox}>
                                        <SecondaryButton
                                            tooltipProps={{ title: "Edit" }}
                                            onClick={setEditDialogOpen}
                                            className={updatePolicyLoading ? classes.iconDisabled : ""}
                                            loading={updatePolicyLoading}
                                            disabled={updatePolicyLoading}
                                        >
                                            <i className="fa-solid fa-pencil" />
                                        </SecondaryButton>
                                        <DangerOutlinedButton
                                            onClick={() => setIsDeleteModalOpen(true)}
                                            tooltipProps={{ title: "Delete" }}
                                            loading={clearPolicyLoading}
                                            className={clearPolicyLoading ? classes.iconDisabled : ""}
                                            disabled={clearPolicyLoading}
                                        >
                                            <i className="fa-solid fa-trash-alt" />
                                        </DangerOutlinedButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    colSpan={6}
                                    className={clsx(classes.overflow, classes.customCollapse)}
                                    style={{ paddingTop: 0, paddingBottom: 0, border: "0px" }}
                                >
                                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                                        <div className={classes.collapsingRowContent}>
                                            <br />
                                            <div>
                                                <pre>{JSON.stringify(policyCommand, null, 2)}</pre>
                                            </div>
                                        </div>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </>
                    );
                }}
            </Draggable>
            <EditPolicyCommandDialog
                groupId={groupId}
                policyCommands={policyCommands}
                policyCommand={policyCommand}
                isOpen={editDialogOpen}
                toggleDialog={setEditDialogOpen}
            />
            <ConfirmDialog
                title="Delete command"
                content="Are you sure you want to delete this command?"
                dangerButton
                primaryActionText="Delete"
                open={isDeleteModalOpen}
                onConfirm={() => handleDelete(policyCommand)}
                onClose={toggleModal}
            />
        </>
    );
};

export default PolicyCommandItem;
